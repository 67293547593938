import { Colors } from "client/themes";

const styles = {
  focusedTextArea: {
    backgroundColor: Colors.offWhite,
    padding: 10,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.auxxitSlateDarker,
    borderStyle: "solid",
    color: Colors.greyBlurred,
    cursor: "text",
    overflow: "auto"
  },
  changedTextArea: {
    backgroundColor: Colors.emeraldGreen,
    padding: 10,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.auxxitSlateDarker,
    borderStyle: "solid",
    color: Colors.greyBlurred,
    cursor: "text"
  }
};

export default styles;
