import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Flex from "client/components/flex";
import CurrentUserActions, { CurrentUserSelectors } from "client/state/redux/current_user_redux";
import styles from "./styles";

const CHANGE_PASSWORD_FIELD = "changePassword";
const CHANGE_PASSWORD_CONFIRM_FIELD = "changePasswordConfirm";

class SettingsPanel extends Component {
  static propTypes = {
    saveUserSettings: PropTypes.func.isRequired,
    setUserSettingsMessage: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      form: {
        changePassword: "",
        changePasswordConfirm: ""
      }
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.state;
    const { saveUserSettings } = this.props;
    saveUserSettings(form);
  }

  handleChange = (name, value) => {
    const { setUserSettingsMessage } = this.props;
    const { form } = this.state;
    this.setState({ form: { ...form, [name]: value } });
    setUserSettingsMessage("");
  }

  onChangePassword = (e) => {
    this.handleChange(CHANGE_PASSWORD_FIELD, e.target.value);
  }

  onChangePasswordConfirm = (e) => {
    this.handleChange(CHANGE_PASSWORD_CONFIRM_FIELD, e.target.value);
  }

  render() {
    const { form } = this.state;

    return (
      <Flex
        flexDirection="column"
        alignItems="center"
        width="500px"
        height="400px"
        style={styles.container}
      >
        <Flex style={styles.title} flex={2} alignItems="center">Settings</Flex>
        <form style={styles.formContainer}>
          Change Password
          <Flex style={styles.inputContainer}>
            <div style={styles.label}>Password</div>
            <input
              style={styles.input}
              value={form.changePassword}
              type="password"
              autoComplete="off"
              onChange={this.onChangePassword}
            />
          </Flex>
          <Flex style={styles.inputContainer}>
            <div style={styles.label}>Confirm Password</div>
            <input
              style={styles.input}
              value={form.changePasswordConfirm}
              type="password"
              autoComplete="off"
              onChange={this.onChangePasswordConfirm}
            />
          </Flex>
          <button
            style={styles.saveButton}
            type="submit"
            onClick={this.handleSubmit}
          >
            Save
          </button>
        </form>
      </Flex>
    );
  }
}

const mapStateToProps = state => ({
  settings: CurrentUserSelectors.getUserSettings(state)
});

const mapDispatchToProps = dispatch => ({
  saveUserSettings: settings => dispatch(CurrentUserActions.saveUserSettings(settings)),
  setUserSettingsMessage: text => dispatch(CurrentUserActions.setUserSettingsMessage(text))
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPanel);
