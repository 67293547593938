import api from "client/services/api";
import AuthTokenStorage from "client/services/auth_token_storage";

async function refreshAuthToken() {
  try {
    const authToken = await AuthTokenStorage.getAuthToken();
    if (!authToken) return false;

    const { refreshToken, userId } = authToken;
    const response = await api.refreshToken({ refreshToken, id: userId });

    await AuthTokenStorage.setAuthToken(response.data);
    await api.client.setAccessToken(response.data.accessToken);

    return true;
  } catch (e) {
    return false;
  }
}

export default refreshAuthToken;
