import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import Flex from "client/components/flex";
import { AppStateSelectors } from "client/state/redux/app_state_redux";
import ExposActions from "client/state/redux/expos_redux";
import { envStyles } from "client/themes";
import styles from "./styles";

const NAME_FIELD = "name";
const DESCRIPTION_FIELD = "description";
const START_DATE_FIELD = "startDate";
const END_DATE_FIELD = "endDate";

class CreateExpoForm extends Component {
  static propTypes = {
    createExpo: PropTypes.func.isRequired,
    environment: PropTypes.string.isRequired,
    setExposMessage: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      form: {
        name: "",
        description: "",
        startDate: new Date(),
        endDate: null,
        environment: props.environment
      },
      focusedDate: START_DATE_FIELD
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.state;
    const { createExpo } = this.props;
    createExpo(form);
  };

  handleChange = (name, value) => {
    const { setExposMessage } = this.props;
    const { form } = this.state;
    this.setState({ form: { ...form, [name]: value } });
    setExposMessage("");
  };

  onChangeName = (e) => {
    this.handleChange(NAME_FIELD, e.target.value);
  };

  onChangeDescription = (e) => {
    this.handleChange(DESCRIPTION_FIELD, e.target.value);
  };

  onChangeDates = ({ startDate, endDate }) => {
    const { form } = this.state;
    this.setState({ form: {
      ...form,
      [START_DATE_FIELD]: startDate,
      [END_DATE_FIELD]: endDate
    } });
  };

  render() {
    const { form, focusedDate } = this.state;

    return (
      <Flex
        flexDirection="column"
        alignItems="center"
        width="500px"
        height="400px"
        style={styles.container}
      >
        <Flex style={styles.title} flex={2} alignItems="center">Create an Expo</Flex>
        <form style={styles.formContainer}>
          <Flex style={styles.inputContainer}>
            <div style={styles.label}>Name</div>
            <input
              style={styles.input}
              value={form.user}
              placeholder="Totally Radical Expo 2020"
              autoComplete="off"
              onChange={this.onChangeName}
            />
          </Flex>
          <Flex style={styles.textAreaContainer}>
            <div style={styles.label}>Description</div>
            <textarea
              style={styles.textAreaInput}
              value={form.deviceId}
              placeholder="Most radical expo between the banks of the Mississippi!"
              autoComplete="off"
              onChange={this.onChangeDescription}
            />
          </Flex>
          <DateRangePicker
            startDate={form.startDate ? moment(form.startDate) : null}
            startDateId={`start-${Date.now().toString()}`}
            endDate={form.endDate ? moment(form.endDate) : null}
            endDateId={`end-${Date.now().toString()}`}
            onDatesChange={this.onChangeDates}
            focusedInput={focusedDate}
            onFocusChange={focused => this.setState({ focusedDate: focused })}
          />
          <Flex>
            <select
              style={{ ...styles.selectInput, ...envStyles[form.environment] }}
              value={form.environment}
              disabled
            >
              <option
                style={styles.selectOption}
                key={form.environment}
                value={form.environment}
              >
                {form.environment}
              </option>
            </select>
          </Flex>
          <button
            style={styles.saveButton}
            type="submit"
            onClick={this.handleSubmit}
          >
            Create
          </button>
        </form>
      </Flex>
    );
  }
}

const mapStateToProps = state => ({
  environment: AppStateSelectors.getEnvironment(state)
});

const mapDispatchToProps = dispatch => ({
  createExpo: data => dispatch(ExposActions.createExpo(data)),
  setExposMessage: text => dispatch(ExposActions.setExposMessage(text))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateExpoForm);
