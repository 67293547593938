import { Map } from "immutable";

const INIT_STATE = Map({
  complete: false
});

const types = {
  startup: "STARTUP",
  startupComplete: "STARTUP_COMPLETE"
};

/* ********* Actions ********* */

const actions = {
  startup() {
    return {
      type: types.startup
    };
  },
  startupComplete() {
    return {
      type: types.startupComplete
    };
  }
};

/* ********* Reducer ********* */

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case types.startupComplete:
    return state.merge({ complete: true });
  default:
    return state;
  }
};

/* ********* Selectors ********* */

const startup = state => state.get("startup");

const selectors = {
  getComplete: state => startup(state).get("complete")
};

export {
  types as StartupTypes,
  reducer as StartupReducer,
  selectors as StartupSelectors
};
export default actions;
