import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./styles";

const formatData = (data) => {
  if (typeof data === "object") {
    return JSON.stringify(data);
  }
  return data.toString();
};

class DataCell extends Component {
  static propTypes = {
    data: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.objectOf(PropTypes.any),
      PropTypes.arrayOf(PropTypes.any),
      PropTypes.number
    ]),
    editable: PropTypes.bool.isRequired,
    onEdit: PropTypes.func
  };

  static defaultProps = {
    data: "",
    onEdit: Function.prototype
  };

  /**
   * @type {string}
   * Initial value of the cell to keep track of changes.
   */
  initialValue = formatData(this.props.data);

  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      changed: false
    };
  }

  handleChange = (e) => {
    const changedValue = e.target.innerText;
    const { onEdit } = this.props;

    let noChange = false;
    if (!this.state.changed) {
      this.setState({ changed: true });
    } else if (this.initialValue === changedValue) {
      noChange = true;
      this.setState({ changed: false });
    }
    onEdit(changedValue, noChange);
  };

  handleFocus = () => {
    this.setState({ focused: true });
  };

  handleUnfocus = () => {
    this.setState({ focused: false });
  };

  renderEditableText = () => {
    const { focused, changed } = this.state;
    const { editable } = this.props;
    return (
      <div
        contentEditable={editable}
        suppressContentEditableWarning
        style={{
          // order matters here
          ...(changed && editable ? styles.changedTextArea : null),
          ...(focused && editable ? styles.focusedTextArea : null)
        }}
        onInput={this.handleChange}
        onClick={this.handleFocus}
        onFocus={this.handleFocus}
        onBlur={this.handleUnfocus}
        role="button"
        tabIndex={0}
      >
        {this.initialValue}
      </div>
    );
  };

  render() {
    return (
      <td>{this.renderEditableText()}</td>
    );
  }
}

export default DataCell;
