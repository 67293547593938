import React, { PureComponent } from "react";
import NavigationService from "client/services/navigation_service";
import { NavbarButton } from "client/components/navbar_item";
import styles from "./styles";

class SettingsButton extends PureComponent {
  goToSettingsPage = () => {
    NavigationService.toSettingsPage();
  }

  render() {
    return (
      <NavbarButton
        style={styles.settingsButton}
        onClick={this.goToSettingsPage}
      >
        Settings
      </NavbarButton>
    );
  }
}

export default SettingsButton;
