import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Flex from "client/components/flex";
import { AppStateSelectors } from "client/state/redux/app_state_redux";
import GroupsActions from "client/state/redux/groups_redux";
import { envStyles } from "client/themes";
import styles from "./styles";

const NAME_FIELD = "name";
const DESCRIPTION_FIELD = "description";
const USERNAME_FIELD = "username";

class CreateGroupForm extends Component {
  static propTypes = {
    createGroup: PropTypes.func.isRequired,
    environment: PropTypes.string.isRequired,
    setGroupsMessage: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      form: {
        name: "",
        description: "",
        username: "",
        environment: props.environment
      }
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.state;
    const { createGroup } = this.props;
    createGroup(form);
  };

  handleChange = (name, value) => {
    const { setGroupsMessage } = this.props;
    const { form } = this.state;
    this.setState({ form: { ...form, [name]: value } });
    setGroupsMessage("");
  };

  onChangeName = (e) => {
    this.handleChange(NAME_FIELD, e.target.value);
  };

  onChangeDescription = (e) => {
    this.handleChange(DESCRIPTION_FIELD, e.target.value);
  };

  onChangeUsername = (e) => {
    this.handleChange(USERNAME_FIELD, e.target.value);
  };

  render() {
    const { form } = this.state;

    return (
      <Flex
        flexDirection="column"
        alignItems="center"
        width="500px"
        height="400px"
        style={styles.container}
      >
        <Flex style={styles.title} flex={2} alignItems="center">Create an Group</Flex>
        <form style={styles.formContainer}>
          <Flex style={styles.inputContainer}>
            <div style={styles.label}>Name</div>
            <input
              style={styles.input}
              value={form.user}
              placeholder=""
              autoComplete="off"
              onChange={this.onChangeName}
            />
          </Flex>
          <Flex style={styles.inputContainer}>
            <div style={styles.label}>Username</div>
            <input
              style={styles.input}
              value={form.username}
              placeholder=""
              autoComplete="off"
              onChange={this.onChangeUsername}
            />
          </Flex>
          <Flex style={styles.textAreaContainer}>
            <div style={styles.label}>Description</div>
            <textarea
              style={styles.textAreaInput}
              value={form.description}
              placeholder=""
              autoComplete="off"
              onChange={this.onChangeDescription}
            />
          </Flex>
          <Flex>
            <select
              style={{ ...styles.selectInput, ...envStyles[form.environment] }}
              value={form.environment}
              disabled
            >
              <option
                style={styles.selectOption}
                key={form.environment}
                value={form.environment}
              >
                {form.environment}
              </option>
            </select>
          </Flex>
          <button
            style={styles.saveButton}
            type="submit"
            onClick={this.handleSubmit}
          >
            Create
          </button>
        </form>
      </Flex>
    );
  }
}

const mapStateToProps = state => ({
  environment: AppStateSelectors.getEnvironment(state)
});

const mapDispatchToProps = dispatch => ({
  createGroup: data => dispatch(GroupsActions.createGroup(data)),
  setGroupsMessage: text => dispatch(GroupsActions.setGroupsMessage(text))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroupForm);
