function sanitizeCurrentUser(data) {
  const currentUser = data.data;
  return {
    id: currentUser.id,
    ...currentUser.attributes
  };
}

function sanitizeUser(data) {
  return {
    id: data._id,
    ...data
  };
}

function sanitizeUsers(data) {
  return data.map(user => sanitizeUser(user));
}

function sanitizeSellers(data) {
  return data.map(seller => ({
    id: seller._id,
    ...seller,
  }));
}

function sanitizeDisbursements(data) {
  return data.map(d => ({
    ...d,
    id: d._id,
    purchases: d.purchases.map(p => (
      {
        id: p._id,
        ...p
      }
    )),
  }));
}

function sanitizeSellerOverallData(data) {
  return {
    id: data._id,
    ...data
  };
}

export default {
  sanitizeCurrentUser,
  sanitizeUser,
  sanitizeUsers,
  sanitizeSellers,
  sanitizeDisbursements,
  sanitizeSellerOverallData,
};
