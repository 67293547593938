import React, { PureComponent } from "react";
import Flex from "client/components/flex";
import BottomNavbar from "client/navigation/bottom_navbar";
import styles from "./styles";

class Footer extends PureComponent {
  render() {
    return (
      <Flex style={styles.footer}>
        <BottomNavbar />
      </Flex>
    );
  }
}

export default Footer;
