import { put, takeLatest, takeLeading } from "redux-saga/effects";
import api from "client/services/api";
import callAuthApi from "client/services/call_auth_api";
import ModelHelper from "./helpers/item_model";
import PrivateOffersActions, {
  PrivateOffersTypes as types
} from "../redux/private_offers_redux";
import AppStateActions from "../redux/app_state_redux";

function* fetchGroupsPrivateOffers({ month, year }) {
  try {
    const monthParam = month === 0 ? 0 : month || new Date().getMonth();
    const yearParam = year || new Date().getFullYear();
    const response = yield callAuthApi(api.fetchGroupsPrivateOffers, {
      month: monthParam,
      year: yearParam
    });
    const privateOffers = ModelHelper.sanitizeGroupsPrivateOffers(response.data);
    yield put(PrivateOffersActions.fetchGroupsPrivateOffersSuccess(privateOffers));
  } catch (e) {
    yield put(PrivateOffersActions.fetchGroupsPrivateOffersFailure());
  }
}

function* downloadPrivateOffersLast7Days() {
  try {
    const response = yield callAuthApi(api.downloadPrivateOffersLast7Days);
    const url = window.URL.createObjectURL(new window.Blob([response.data], { type: "text/csv" }));
    const link = document.createElement("a");
    link.href = url;
    const date = new Date();
    const formattedDate = `${date.getUTCMonth() + 1}-${date.getUTCDate()}-${date.getUTCFullYear()}`;
    link.setAttribute("download", `private-offers-${formattedDate}.csv`);
    // Append to html page
    document.body.appendChild(link);
    // Force download
    link.click();
    // Clean up and remove the link
    link.parentNode.removeChild(link);
    yield put(AppStateActions.setAppMessage({ message: "Successfully downloaded CSV of private offers" }));
  } catch (e) {
    yield put(AppStateActions.setAppMessage({ message: "Failed to download CSV of private offers" }));
  }
}

export default function privateOffersSagas() {
  return [
    takeLatest(types.fetchGroupsPrivateOffers, fetchGroupsPrivateOffers),
    takeLeading(types.downloadPrivateOffersLast7Days, downloadPrivateOffersLast7Days)
  ];
}
