import api from "./client";

function fetchItems() {
  return api.get("/items");
}

function modifyItems(params) {
  return api.put("/items", params);
}

function validateItemsCsv(params) {
  return api.post("/items/csv/validate", params, {
    headers: { "Content-Type": "multipart/form-data" }
  });
}

function fetchGroupsPrivateOffers(params) {
  return api.get("/items/groups/offers", { params });
}

function downloadPrivateOffersLast7Days() {
  return api.get("/items/offers/last7days");
}

export default {
  fetchItems,
  modifyItems,
  validateItemsCsv,
  fetchGroupsPrivateOffers,
  downloadPrivateOffersLast7Days
};
