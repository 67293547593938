import { Colors } from "client/themes";

const styles = {
  title: {
    fontSize: 18
  },
  container: {
    backgroundColor: Colors.white,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 8,
  },
  inputContainer: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.auxxitSlateDarker,
    borderStyle: "solid",
    padding: 10,
    marginTop: 5,
    marginBottom: 5
  },
  textAreaContainer: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.auxxitSlateDarker,
    borderStyle: "solid",
    padding: 10,
    marginTop: 5,
    marginBottom: 5,
    flex: 1,
    flexDirection: "column"
  },
  label: {
    color: Colors.greyBlurred
  },
  input: {
    color: Colors.lightblue,
    fontSize: 16
  },
  textAreaInput: {
    outline: "none",
    border: "none",
    flex: 1
  },
  selectInput: {
    fontSize: 14,
    color: Colors.black,
    padding: 9,
    borderRadius: 6,
    backgroundColor: Colors.white,
    width: 200,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: Colors.auxxitSlateDarker,
    marginTop: 5,
    marginBottom: 5
  },
  selectOption: {
    padding: 4,
    color: Colors.black,
    backgroundColor: Colors.white,
    paddingTop: 2
  },
  saveButton: {
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 30,
    paddingRight: 30,
    backgroundColor: Colors.auxxitBlue,
    borderRadius: 5,
    borderStyle: "solid",
    elevation: 2,
    shadowOffset: { width: 1, height: 1 },
    shadowColor: Colors.white,
    shadowOpacity: 0.5,
    shadowRadius: 10,
    color: Colors.white,
    fontSize: 16
  },
};

export default styles;
