import { call, put, takeLatest } from "redux-saga/effects";
import api from "client/services/api";
import ModelHelper from "./helpers/item_model";
import ItemsActions, { ItemsTypes as types } from "../redux/items_redux";

function* fetchItems() {
  try {
    const response = yield call(api.fetchItems);
    const items = ModelHelper.sanitizeItems(response.data);
    yield put(ItemsActions.fetchItemsSuccess(items));
  } catch (e) {
    yield put(ItemsActions.fetchItemsFailure());
  }
}

function* modifyItems(action) {
  try {
    yield call(api.modifyItems, action.data);
    yield put(ItemsActions.modifyItemsSuccess());
  } catch (e) {
    yield put(ItemsActions.modifyItemsFailure());
  }
}
function* validateItemsCsv(action) {
  try {
    const formData = new FormData();
    formData.append("data", action.file);
    yield call(api.validateItemsCsv, formData);
    yield put(ItemsActions.validateItemsCsvSuccess());
  } catch (e) {
    yield put(ItemsActions.validateItemsCsvFailure());
  }
}

export default function itemsSagas() {
  return [
    takeLatest(types.fetchItems, fetchItems),
    takeLatest(types.modifyItems, modifyItems),
    takeLatest(types.validateItemsCsv, validateItemsCsv)
  ];
}
