import { Colors } from "client/themes";

const styles = {
  page: {
    backgroundColor: Colors.william,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
    width: "100%",
    height: "100%"
  }
};

export default styles;
