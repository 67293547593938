function sanitizeFeedback(data) {
  return {
    ...data,
    id: data._id,
    comments: (data.comments || []).map(c => ({ ...c, id: c._id }))
  };
}

function sanitizeFeedbackList(data) {
  return data.map(feedback => sanitizeFeedback(feedback));
}

export default {
  sanitizeFeedback,
  sanitizeFeedbackList
};
