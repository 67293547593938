import { Colors } from "client/themes";

const styles = {
  container: {
    height: "100%"
  },
  interface: {
    overflow: "auto"
  },
  contentHeader: {
    justifyContent: "center"
  },
  selectInput: {
    fontSize: 14,
    padding: 9,
    borderRadius: 6,
    backgroundColor: Colors.white,
    width: 200,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: Colors.auxxitSlateGrey,
    boxShadow: `2px 3px 3px${Colors.grey}`,
  },
  selectOption: {
    padding: 4,
    color: Colors.black,
    backgroundColor: Colors.white,
    paddingTop: 2,
    width: 10,
    height: 10,
  },
  totalGmvWrapper: {
    backgroundColor: Colors.white,
    borderRadius: 5,
    padding: 10
  },
  downloadBtn: {
    backgroundColor: "transparent",
    border: 0,
    marginLeft: 10,
    color: Colors.linkBlue,
    textDecorationLine: "underline"
  }
};

export default styles;
