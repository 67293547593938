import api from "./client";

function fetchGroupsGrossMerchandiseValue(params) {
  return api.get("/ledgers/groups/gmv", { params });
}

function fetchGroupsPrivateOffersGmv(params) {
  return api.get("/ledgers/groups/offers", { params });
}

function downloadLedgerSummary(params) {
  return api.get("/ledgers/summary", { params });
}

export default {
  fetchGroupsGrossMerchandiseValue,
  downloadLedgerSummary,
  fetchGroupsPrivateOffersGmv
};
