import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as FlatColorIcons from "react-icons/fc";
import GroupsActions, { GroupsSelectors } from "client/state/redux/groups_redux";
import Page from "client/components/page";
import Flex from "client/components/flex";
import styles, { detailIconStyleProps } from "./styles";

class GroupFeesPage extends Component {
  static propTypes = {
    downloadFeeTotals: PropTypes.func.isRequired,
    fetchGroupFees: PropTypes.func.isRequired,
    globalFees: PropTypes.arrayOf(PropTypes.shape()),
    groupFees: PropTypes.arrayOf(PropTypes.shape()),
  };

  static defaultProps = {
    globalFees: [],
    groupFees: []
  };

  componentDidMount() {
    const { fetchGroupFees } = this.props;
    fetchGroupFees();
  }

  // eslint-disable-next-line class-methods-use-this
  renderGroupFees = (fee) => {
    const discount = fee.discount || 0;
    const percentage = fee.percentage * (1 - (discount / 100));
    return (
      <div key={fee.type} style={styles.groupFee}>
        <span>{`${fee.type}: `}</span>
        <span>{`${percentage}%`}</span>
      </div>
    );
  };

  renderGlobalFees = () => {
    const { globalFees } = this.props;
    return (
      <Flex alignItems="center" style={styles.globalWrapper}>
        <b style={styles.groupName}>Global</b>
        <Flex style={styles.groupsWrapper}>
          {globalFees.map((fee, idx) => {
            const discount = fee.discount || 0;
            const percentage = fee.percentage * (1 - (discount / 100));
            return (
              <Flex key={fee.type} style={styles.globalFee}>
                <div style={{ marginRight: 2 }}>{`${fee.type}:`}</div>
                <div>{`${percentage}%`}</div>
                <div>{idx !== globalFees.length - 1 ? "," : ""}</div>
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    );
  };

  downloadFeeTotals = groupId => () => {
    const { downloadFeeTotals } = this.props;
    downloadFeeTotals(groupId);
  };

  renderGroupFeeCard = groupData => (
    <div key={groupData.id} style={styles.dataContainer}>
      <div style={styles.groupDetailsWrap}>
        <div style={styles.groupName}>{groupData.name}</div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={styles.groupDetailsRow}>
            {groupData.fees.map((f, idx) => this.renderGroupFees(f, idx))}
          </div>
        </div>
      </div>
      <div style={styles.reportsWrap}>
        <div style={styles.reportsIconWrapper}>
          {React.createElement(FlatColorIcons.FcDataSheet, { ...detailIconStyleProps })}
          <div style={{ ...styles.iconText }}>Reports</div>
        </div>
        <div style={styles.detailsRows}>
          <button style={styles.downloadBtn} type="button" onClick={this.downloadFeeTotals(groupData.id)}>
            Download Fee Totals
          </button>
        </div>
      </div>
    </div>
  );

  render() {
    const { groupFees } = this.props;
    return (
      <Page
        title="Group Fees"
      >
        <Flex style={styles.container} flexDirection="column">
          <Flex style={styles.content} flexDirection="column">
            {this.renderGlobalFees()}
            <div style={styles.listWrapper}>
              {groupFees.length
                ? (
                  groupFees.map(group => (
                    <div key={group.id}>
                      {this.renderGroupFeeCard(group)}
                    </div>
                  ))
                ) : (
                  <div style={styles.emptyListText}>No group fees found</div>
                )}
            </div>
            <div style={{ fontSize: 14 }}>
              Groups use the global fees unless specified above.
            </div>
          </Flex>
        </Flex>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  groupFees: GroupsSelectors.getGroupFees(state),
  globalFees: GroupsSelectors.getGlobalFees(state)
});

const mapDispatchToProps = dispatch => ({
  fetchGroupFees: () => dispatch(GroupsActions.fetchGroupFees()),
  downloadFeeTotals: groupId => dispatch(GroupsActions.downloadFeeTotals(groupId))
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupFeesPage);
