import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReleasesActions, { ReleasesSelectors } from "client/state/redux/releases_redux";
import Page from "client/components/page";
import Flex from "client/components/flex";
import ReleaseForm from "./components/release_form";

class ReleasePage extends PureComponent {
  static propTypes = {
    fetchReleases: PropTypes.func.isRequired,
    releaseMessage: PropTypes.string.isRequired
  };

  componentDidMount() {
    const { fetchReleases } = this.props;
    fetchReleases();
  }

  render() {
    const { releaseMessage } = this.props;
    return (
      <Page
        title="Admin - Release"
      >
        <Flex
          flex={8}
          alignItems="center"
          justifyContent="center"
        >
          <ReleaseForm />
        </Flex>
        <Flex
          flex={1}
          alignItems="center"
          justifyContent="center"
        >
          {releaseMessage}
        </Flex>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  releaseMessage: ReleasesSelectors.getReleaseMessage(state)
});

const mapDispatchToProps = dispatch => ({
  fetchReleases: () => dispatch(ReleasesActions.fetchReleases())
});

export default connect(mapStateToProps, mapDispatchToProps)(ReleasePage);
