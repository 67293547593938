import { Colors } from "client/themes";

const styles = {
  header: {
    fontSize: 20,
    color: Colors.white,
    top: 0
  }
};

export default styles;
