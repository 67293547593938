import api from "./client";

function fetchSellersForDisbursement(params) {
  return api.get("/disbursements/sellers", { params });
}

function fetchPendingDisbursementsData() {
  return api.get("/disbursements/pending");
}

function searchSellersForDisbursement(params) {
  return api.get("/disbursements/sellers/search", { params });
}

function fetchSellerDisbursementData(userId, params) {
  return api.get(`/disbursements/sellers/${userId}/disbursements`, { params });
}

function fetchSellerInfo(userId) {
  return api.get(`/disbursements/sellers/${userId}`);
}

function markItemsAsDisbursed(params) {
  return api.put("/disbursements/complete", params);
}

function refundPurchases(params) {
  return api.put("/disbursements/refund", params);
}

function cancelPurchases(params) {
  return api.put("/disbursements/cancel", params);
}

function fetchSellerExceptions(userId) {
  return api.get(`/disbursements/sellers/${userId}/exceptions`);
}

function fetchUserTransactionHistory(userId) {
  return api.get(`/disbursements/users/${userId}/transactions`);
}

export default {
  fetchSellersForDisbursement,
  fetchPendingDisbursementsData,
  searchSellersForDisbursement,
  fetchSellerDisbursementData,
  fetchSellerInfo,
  markItemsAsDisbursed,
  refundPurchases,
  cancelPurchases,
  fetchSellerExceptions,
  fetchUserTransactionHistory
};
