import { Map } from "immutable";

import constants from "client/constants";

const { ENV } = constants;

const INIT_STATE = Map({
  isFetching: false,
  environment: ENV.DEVELOPMENT,
  selectedTab: "users",
  message: "",
  expiresIn: 0,
  showMessage: false // 0 means hide message
});

const types = {
  setSelectedTab: "SET_SELECTED_TAB",
  setEnvironment: "SET_ENVIRONMENT",
  setEnvironmentSuccess: "SET_ENVIRONMENT_SUCCESS",
  setEnvironmentFailure: "SET_ENVIRONMENT_FAILURE",
  setAppMessage: "SET_APP_MESSAGE",
  hideAppMessage: "HIDE_APP_MESSAGE"
};

/* ********* Actions ********* */

const actions = {
  setSelectedTab(data) {
    return {
      type: types.setSelectedTab,
      data
    };
  },
  setEnvironment(data) {
    return {
      type: types.setEnvironment,
      data
    };
  },
  setEnvironmentSuccess(data) {
    return {
      type: types.setEnvironmentSuccess,
      data
    };
  },
  setEnvironmentFailure(data) {
    return {
      type: types.setEnvironmentFailure,
      data
    };
  },
  setAppMessage(data) {
    return {
      type: types.setAppMessage,
      data
    };
  },
  hideAppMessage() {
    return {
      type: types.hideAppMessage
    };
  }
};

/* ********* Reducer ********* */

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case types.setSelectedTab:
    return state.merge({ selectedTab: action.data });
  case types.setEnvironment:
    return state.merge({ isFetching: true });
  case types.setEnvironmentSuccess:
    return state.merge({
      isFetching: false,
      environment: action.data
    });
  case types.setEnvironmentFailure:
    return state.merge({ isFetching: false });
  case types.setAppMessage:
    return state.merge({
      message: action.data.message,
      expiresIn: action.data.expiresIn,
      showMessage: true
    });
  case types.hideAppMessage:
    return state.merge({
      showMessage: false
    });
  default:
    return state;
  }
};

/* ********* Selectors ********* */

const appState = state => state.get("appState");

const selectors = {
  getSelectedTab: state => appState(state).get("selectedTab"),
  getEnvironment: state => appState(state).get("environment"),
  getAppMessage: state => appState(state).get("message"),
  getAppMessageExpiration: state => appState(state).get("expiresIn"),
  getShowAppMessage: state => appState(state).get("showMessage")
};

export {
  types as AppStateTypes,
  reducer as AppStateReducer,
  selectors as AppStateSelectors
};
export default actions;
