import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import GroupsActions, { GroupsSelectors } from "client/state/redux/groups_redux";
import Page from "client/components/page";
import Flex from "client/components/flex";
import styles from "./styles";

class GroupSetsPage extends Component {
  static propTypes = {
    fetchGroupSets: PropTypes.func.isRequired,
    groupSets: PropTypes.arrayOf(PropTypes.shape()),
  };

  static defaultProps = {
    groupSets: []
  };

  componentDidMount() {
    const { fetchGroupSets } = this.props;
    fetchGroupSets();
  }

  // eslint-disable-next-line class-methods-use-this
  renderGroup = (group, idx) => (
    <div key={group.id} style={styles.groupEntry}>
      <span>{`${idx + 1}) `}</span>
      <span>{group.name}</span>
      <span>{!group.settings.whiteLabeled ? "*" : ""}</span>
    </div>
  );

  renderGroupSet = groupSet => (
    <Flex key={groupSet.id} style={styles.groupSet}>
      <b style={styles.groupSetName}>{groupSet.name || "Unnamed"}</b>
      {groupSet.groups.map((g, idx) => this.renderGroup(g, idx))}
    </Flex>
  );

  render() {
    const { groupSets } = this.props;
    return (
      <Page
        title="Group Sets"
      >
        <Flex style={styles.container} flexDirection="column">
          <Flex style={styles.interface}>
            <Flex style={styles.groupSetsWrapper}>
              {groupSets.map(set => this.renderGroupSet(set))}
            </Flex>
          </Flex>
          <div style={{ fontSize: 14 }}>
            *The group has been added to a set but does not
            have &quot;whiteLabeled&quot; set to true.
          </div>
        </Flex>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  groupSets: GroupsSelectors.getGroupSets(state)
});

const mapDispatchToProps = dispatch => ({
  fetchGroupSets: () => dispatch(GroupsActions.fetchGroupSets())
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupSetsPage);
