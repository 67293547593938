import React, { Component } from "react";
import PropTypes from "prop-types";
import FeedbackActions, { FeedbackSelectors } from "client/state/redux/feedback_redux";
import { connect } from "react-redux";
import Page from "client/components/page";
import Flex from "client/components/flex";
import styles from "./styles";

class FeedbackPage extends Component {
  static propTypes = {
    feedback: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    fetchFeedback: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null
    };
  }

  componentDidMount() {
    const { fetchFeedback } = this.props;
    fetchFeedback();
  }

  handleSelectedItem = item => () => {
    this.setState({
      selectedItem: item
    });
  };

  render() {
    const { feedback } = this.props;
    const { selectedItem } = this.state;
    const baseImageUrl = "https://i.auxxit.com/";
    return (
      <Page title="Tickets">
        <Flex style={styles.container}>
          <Flex style={styles.interface}>
            <Flex flexDirection="column" style={styles.feedbackList}>
              {feedback.map(item => (
                <div
                  key={item.id}
                  role="button"
                  tabIndex={0}
                  style={styles.feedbackItem}
                  onClick={this.handleSelectedItem(item)}
                >
                  <div style={styles.feedbackLabelContainer}>
                    <h2 style={styles.feedbackLabel}>{item.format}</h2>
                    <p style={styles.feedbackUser}>{item.user_details[0].username}</p>
                  </div>
                  <p style={styles.feedDescription}>{item.text}</p>
                  <p style={styles.feedbackDate}>{item.createDate}</p>
                  <p style={styles.feedbackAttachment}>{item.images.length ? "Attachment" : ""}</p>
                </div>
              ))}
            </Flex>
            <Flex style={styles.selectedItemContainer}>
              {selectedItem && (
                <div style={styles.selectedItem}>
                  <h1 style={styles.selectedLabel}>{selectedItem.format}</h1>
                  <p style={styles.feedbackDateSelected}>{selectedItem.createDate}</p>
                  <h4>Feedback ID</h4>
                  <p>{selectedItem.id}</p>
                  <h4>Reported by</h4>
                  <p>{selectedItem.createdBy}</p>
                  <p>{selectedItem.user_details[0].username}</p>
                  <p>{selectedItem.user_details[0].email}</p>
                  <p>{`Joined ${selectedItem.user_details[0].createDate}`}</p>
                  <h4>Report</h4>
                  <p>{selectedItem.text}</p>
                  <h4>Attachment(s)</h4>
                  {selectedItem.images.map(image => (
                    <img key={image.url} style={styles.image} src={baseImageUrl + image.url} alt="screenshot" />
                  ))}
                </div>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Page>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  fetchFeedback: () => dispatch(FeedbackActions.fetchFeedback()),
  modifyFeedback: data => dispatch(FeedbackActions.modifyFeedback(data))
});

const mapStateToProps = state => ({
  feedback: FeedbackSelectors.getFeedback(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackPage);
