import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as FlatColorIcons from "react-icons/fc";
import UsersActions, { UsersSelectors } from "client/state/redux/users_redux";
import DisbursementsActions from "client/state/redux/disbursements_redux";
import Page from "client/components/page";
import Flex from "client/components/flex";
import { dateFormat } from "client/util";
import styles, { detailIconStyleProps } from "./styles";

class UsersPage extends Component {
  static propTypes = {
    clearUserSearchResults: PropTypes.func.isRequired,
    downloadTransactionHistory: PropTypes.func.isRequired,
    downloadUsersLast7Days: PropTypes.func.isRequired,
    searchUsers: PropTypes.func.isRequired,
    users: PropTypes.arrayOf(PropTypes.shape())
  };

  static defaultProps = {
    users: []
  };

  static lineItem = (label, text) => (
    <div style={styles.lineItemRow}>
      <div style={styles.lineItemRowLabel}>
        {label}
      </div>
      <div style={styles.lineItemRowText}>
        {text}
      </div>
    </div>
  );

  static stackedLineItem = (label, text, color) => (
    <div style={styles.lineItemColumn}>
      <div style={{ ...styles.lineItemColumnText, ...{ color } }}>
        {text}
      </div>
      <div style={styles.lineItemColumnLabel}>
        {label}
      </div>
    </div>
  );

  state = {
    searchQuery: ""
  };

  onChangeSearchQuery = (e) => {
    const { searchUsers } = this.props;
    const newSearchQuery = e.target.value;
    this.setState({ searchQuery: newSearchQuery });
    if (newSearchQuery.length >= 4) {
      searchUsers(newSearchQuery);
    }
  };

  clearUserSearch = () => {
    const { clearUserSearchResults } = this.props;
    this.setState({ searchQuery: "" });
    clearUserSearchResults();
    this.searchInput.focus();
  };

  downloadTransactionHistory = userId => () => {
    const { downloadTransactionHistory } = this.props;
    downloadTransactionHistory(userId);
  };

  renderUserCard = (userData) => {
    const baseImageUrl = "https://i.auxxit.com/";
    const { createDate, username, email, avatar, id: userId, profile } = userData;
    let avatarUrl = baseImageUrl + avatar;
    if (avatar.includes("www.gravatar.com")) {
      avatarUrl = avatar;
    }
    const name = profile.firstName ? `${profile.firstName} ${profile.lastName}` : null;
    const address = profile.addressPrimary?.addressLine1;
    const city = profile.addressPrimary?.city || "N/A";
    const state = profile.addressPrimary?.state || "N/A";
    const zipCode = profile.addressPrimary?.zip || "N/A";
    return (
      <div key={userData.id} style={styles.dataContainer}>
        <div style={styles.userDetailsWrap}>
          <div style={styles.userIcon}>
            <img key={avatar} style={styles.avatar} src={avatarUrl} alt="avatar" />
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={styles.userDetailsRow}>
              {UsersPage.lineItem("Username", username)}
              {UsersPage.lineItem("Email", email)}
              {UsersPage.lineItem("Joined", dateFormat(createDate))}
              {name ? UsersPage.lineItem("Name", name) : null}
              {profile.phone ? UsersPage.lineItem("Phone", profile.phone) : null}
              {address ? UsersPage.lineItem("Address", address) : null}
              {address ? UsersPage.lineItem("", `${city}, ${state} ${zipCode}`) : null}
            </div>
          </div>
        </div>
        <div style={styles.reportsWrap}>
          <div style={styles.reportsIconWrapper}>
            {React.createElement(FlatColorIcons.FcDataSheet, { ...detailIconStyleProps })}
            <div style={{ ...styles.iconText }}>Reports</div>
          </div>
          <div style={styles.detailsRows}>
            <button style={styles.downloadBtn} type="button" onClick={this.downloadTransactionHistory(userId)}>
              Download Transaction History
            </button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { users, downloadUsersLast7Days } = this.props;
    const { searchQuery } = this.state;
    return (
      <Page
        title="Users"
      >
        <Flex style={styles.container} flexDirection="column">
          <Flex style={styles.content} flexDirection="column">
            <Flex flex={1} style={styles.searchContainer}>
              <Flex flex={1} style={styles.inputContainer}>
                <input
                  style={styles.input}
                  value={searchQuery}
                  onChange={this.onChangeSearchQuery}
                  placeholder="Username or email"
                  ref={(ref) => { this.searchInput = ref; }}
                />
                <button type="button" onClick={this.clearUserSearch}>Clear</button>
              </Flex>
              <button style={styles.downloadBtn} type="button" onClick={downloadUsersLast7Days}>
                Download Last 7 Days
              </button>
            </Flex>
            <div style={styles.listWrapper}>
              {users.length
                ? (
                  users.map(user => (
                    <div key={user.id}>
                      {this.renderUserCard(user)}
                    </div>
                  ))
                ) : (
                  <div style={styles.emptyListText}>No users found</div>
                )}
            </div>
          </Flex>
        </Flex>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  users: UsersSelectors.getUserSearchResults(state)
});

const mapDispatchToProps = dispatch => ({
  clearUserSearchResults: () => dispatch(UsersActions.clearUserSearchResults()),
  searchUsers: query => dispatch(UsersActions.searchUsers(query)),
  downloadUsersLast7Days: () => dispatch(UsersActions.downloadUsersLast7Days()),
  downloadTransactionHistory: userId =>
    dispatch(DisbursementsActions.downloadUserTransactionHistory(userId))
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
