import client from "./client";

import authApi from "./auth_api";
import currentUserApi from "./current_user_api";
import usersApi from "./users_api";
import groupsApi from "./groups_api";
import auctionsApi from "./auctions_api";
import itemsApi from "./items_api";
import feedbackApi from "./feedback_api";
import releasesApi from "./releases_api";
import exposApi from "./expos_api";
import disbursementsApi from "./disbursements_api";
import ledgersApi from "./ledgers_api";

export default {
  client,
  ...authApi,
  ...currentUserApi,
  ...usersApi,
  ...groupsApi,
  ...auctionsApi,
  ...itemsApi,
  ...feedbackApi,
  ...releasesApi,
  ...exposApi,
  ...disbursementsApi,
  ...ledgersApi
};
