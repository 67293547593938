import { Map, List, fromJS } from "immutable";
import memoize from "fast-memoize";

const INIT_STATE = Map({
  isSubmitting: false,
  releaseMessage: "",
  releases: List([])
});

const types = {
  fetchReleases: "FETCH_RELEASES",
  fetchReleasesSuccess: "FETCH_RELEASES_SUCCESS",
  fetchReleasesFailure: "FETCH_RELEASES_FAILURE",
  draftRelease: "DRAFT_RELEASE",
  draftReleaseSuccess: "DRAFT_RELEASE_SUCCESS",
  draftReleaseFailure: "DRAFT_RELEASE_FAILURE",
  setReleaseMessage: "SET_RELEASE_MESSAGE"
};

/* ********* Actions ********* */

const actions = {
  fetchReleases() {
    return {
      type: types.fetchReleases
    };
  },
  fetchReleasesSuccess(data) {
    return {
      type: types.fetchReleasesSuccess,
      data
    };
  },
  fetchReleasesFailure() {
    return {
      type: types.fetchReleasesFailure
    };
  },
  draftRelease(data) {
    return {
      type: types.draftRelease,
      data
    };
  },
  draftReleaseSuccess(data) {
    return {
      type: types.draftReleaseSuccess,
      data
    };
  },
  draftReleaseFailure(data) {
    return {
      type: types.draftReleaseFailure,
      data
    };
  },
  setReleaseMessage(data) {
    return {
      type: types.setReleaseMessage,
      data
    };
  }
};

/* ********* Reducer ********* */

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case types.fetchReleases:
    return state.merge({ isFetching: true });
  case types.fetchReleasesSuccess:
    return state.merge({ isFetching: false, releases: fromJS(action.data) });
  case types.fetchReleasesFailure:
    return state.merge({ isFetching: false });
  case types.draftRelease:
    return state.merge({ isSubmitting: true });
  case types.draftReleaseSuccess:
    return state.merge({ isSubmitting: false, releaseMessage: JSON.stringify(action.data) });
  case types.draftReleaseFailure:
    return state.merge({ isSubmitting: false, releaseMessage: JSON.stringify(action.data) });
  case types.setReleaseMessage:
    return state.merge({ releaseMessage: action.data });
  default:
    return state;
  }
};

/* ********* Selectors ********* */

const releases = state => state.get("releases");

const selectors = {
  getReleaseMessage: state => releases(state).get("releaseMessage"),
  getIsSubmitting: state => releases(state).get("isSubmitting"),
  getIsFetching: state => releases(state).get("isFetching"),
  getReleases: memoize(state => releases(state).get("releases").toJS())
};

export {
  types as ReleasesTypes,
  reducer as ReleasesReducer,
  selectors as ReleasesSelectors
};
export default actions;
