import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Flex from "client/components/flex";
import styles from "./styles";

class NavbarRight extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.string,
      PropTypes.array
    ]).isRequired
  };

  render() {
    const { children } = this.props;

    return (
      <Flex
        flex={1}
        justifyContent="flex-end"
        alignItems="center"
        style={styles.navbarRight}
      >
        {children}
      </Flex>
    );
  }
}

export default NavbarRight;
