import { Colors } from "client/themes";

const styles = {
  container: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center"
  },
  interface: {
    height: "90%",
    width: "90%",
    backgroundColor: Colors.greyBlurred,
    borderRadius: 10,
    boxShadow: `3px 4px 4px${Colors.grey}`,
    marginRight: 10,
    paddingTop: 10,
    marginBottom: 10
  },
  groupSetsWrapper: {
    flexWrap: "wrap"
  },
  groupSet: {
    backgroundColor: Colors.white,
    margin: 5,
    flexDirection: "column",
    height: 250,
    minWidth: 200,
    borderRadius: 10,
    boxShadow: `3px 4px 4px${Colors.grey}`,
    padding: 10,
    overflowY: "scroll"
  },
  groupSetName: {
    textAlign: "center",
    marginBottom: 5
  },
  groupEntry: {
    marginBottom: 5
  }
};

export default styles;
