import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AppStateActions, { AppStateSelectors } from "client/state/redux/app_state_redux";
import Page from "client/components/page";
import Flex from "client/components/flex";
import TabInterface from "client/components/tab_interface";
import UsersInterface from "./components/users_interface";
import GroupsInterface from "./components/groups_interface";
import AuctionsInterface from "./components/auctions_interface";
import ItemsInterface from "./components/items_interface";
import styles from "./styles";

class Main extends PureComponent {
  static propTypes = {
    selectedTab: PropTypes.string.isRequired,
    setSelectedTab: PropTypes.func.isRequired
  };

  mainTabs = [
    { title: "Users", type: "users", component: UsersInterface },
    { title: "Groups", type: "groups", component: GroupsInterface },
    { title: "Auctions", type: "auctions", component: AuctionsInterface },
    { title: "Items", type: "items", component: ItemsInterface }
  ];

  render() {
    const { selectedTab, setSelectedTab } = this.props;
    return (
      <Page
        title="Admin Portal"
      >
        <Flex style={styles.container}>
          <Flex
            style={styles.frame}
            justifyContent="flex-start"
            alignItems="flex-start"
            flex={1}
          >
            <TabInterface
              tabs={this.mainTabs}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          </Flex>
        </Flex>
      </Page>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setSelectedTab: type => dispatch(AppStateActions.setSelectedTab(type))
});

const mapStateToProps = state => ({
  selectedTab: AppStateSelectors.getSelectedTab(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
