import { call, put, takeLatest, select } from "redux-saga/effects";
import api from "client/services/api";
import NavigationService from "client/services/navigation_service";
import AuthTokenStorage from "client/services/auth_token_storage";
import refreshAuthToken from "client/services/refresh_auth_token";
import AuthActions, { AuthTypes as types } from "../redux/auth_redux";
import CurrentUserActions, { CurrentUserSelectors } from "../redux/current_user_redux";

function* attemptLogIn(action) {
  try {
    const response = yield call(api.logIn, action.data);

    yield call(AuthTokenStorage.setAuthToken, response.data);
    yield call(api.client.setAccessToken, response.data.accessToken);

    yield put(AuthActions.logInSuccess(response.data));
    yield put(CurrentUserActions.fetchCurrentUser());

    yield call(NavigationService.toMainPage);
  } catch (e) {
    const err = (e.response && e.response.data) || e.message;
    yield put(AuthActions.logInFailure(err));
  }
}

function* attemptLogOut() {
  try {
    const { id: userId } = yield select(CurrentUserSelectors.getCurrentUser);
    const authToken = yield call(AuthTokenStorage.getAuthToken);
    if (authToken) {
      yield call(api.logOut, { id: userId, refreshToken: authToken.refreshToken });

      yield call(AuthTokenStorage.deleteAuthToken);
      yield put(AuthActions.logOutSuccess());
      yield put(CurrentUserActions.reset());

      yield call(NavigationService.toLoginPage);
    }
  } catch (e) {
    yield put(AuthActions.logOutFailure());
  }
}

function* attemptSignUp(action) {
  try {
    yield call(api.signUp, action.data);
    yield put(AuthActions.signUpSuccess());

    yield call(NavigationService.toLoginPage);
  } catch (e) {
    const err = (e.response && e.response.data) || e.message;
    yield put(AuthActions.signUpFailure(err));
  }
}

function* attemptRefreshToken() {
  const isRefreshed = yield call(refreshAuthToken);
  if (!isRefreshed) {
    yield put(AuthActions.refreshTokenFailure());
    return;
  }
  yield put(AuthActions.refreshTokenSuccess());
}

export default function authSagas() {
  return [
    takeLatest(types.attemptLogIn, attemptLogIn),
    takeLatest(types.attemptLogOut, attemptLogOut),
    takeLatest(types.attemptSignUp, attemptSignUp),
    takeLatest(types.attemptRefreshToken, attemptRefreshToken)
  ];
}
