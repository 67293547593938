import { List, Map, fromJS } from "immutable";
import memoize from "fast-memoize";

const DEFAULT_PRIVATE_OFFERS = List([
  {
    id: "Loading...",
    name: "Loading...",
    count: 0,
    gmv: 0,
    gmvUsd: "$0.00"
  }
]);

const INIT_STATE = Map({
  groupsPrivateOffers: DEFAULT_PRIVATE_OFFERS,
  isFetching: false
});

const types = {
  fetchGroupsPrivateOffers: "FETCH_GROUPS_PRIVATE_OFFERS",
  fetchGroupsPrivateOffersSuccess: "FETCH_GROUPS_PRIVATE_OFFERS_SUCCESS",
  fetchGroupsPrivateOffersFailure: "FETCH_GROUPS_PRIVATE_OFFERS_FAILURE",
  downloadPrivateOffersLast7Days: "DOWNLOAD_PRIVATE_OFFERS_LAST_7_DAYS"
};

/* ********* Actions ********* */

const actions = {
  fetchGroupsPrivateOffers(month, year) {
    return {
      type: types.fetchGroupsPrivateOffers,
      month,
      year
    };
  },
  fetchGroupsPrivateOffersSuccess(data) {
    return {
      type: types.fetchGroupsPrivateOffersSuccess,
      data
    };
  },
  fetchGroupsPrivateOffersFailure() {
    return {
      type: types.fetchGroupsPrivateOffersFailure
    };
  },
  downloadPrivateOffersLast7Days() {
    return {
      type: types.downloadPrivateOffersLast7Days,
    };
  }
};

/* ********* Reducer ********* */

// eslint-disable-next-line default-param-last
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case types.fetchGroupsPrivateOffers:
    return state.merge({ isFetching: true, groupsPrivateOffers: DEFAULT_PRIVATE_OFFERS });
  case types.fetchGroupsPrivateOffersSuccess:
    return state.merge({ isFetching: false, groupsPrivateOffers: fromJS(action.data) });
  case types.fetchGroupsPrivateOffersFailure:
    return state.merge({ isFetching: false });
  default:
    return state;
  }
};

/* ********* Selectors ********* */

const privateOffers = state => state.get("privateOffers");

const selectors = {
  getGroupsPrivateOffers: memoize(state => privateOffers(state).get("groupsPrivateOffers").toJS())
};

export {
  types as PrivateOffersTypes,
  reducer as PrivateOffersReducer,
  selectors as PrivateOffersSelectors
};
export default actions;
