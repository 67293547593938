import React, { Component } from "react";
import NavigationService from "client/services/navigation_service";
import styles from "./styles";

class SignUpLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false
    };
  }

  goToSignUpPage = () => {
    NavigationService.toSignUpPage();
  }

  handleMouseEnter = () => {
    this.setState({ hover: true });
  }

  handleMouseLeave = () => {
    this.setState({ hover: false });
  }

  render() {
    const { hover } = this.state;
    const style = {
      ...styles.signUpLinkText,
      textDecoration: hover ? "underline" : "initial"
    };
    return (
      <div
        style={style}
        onClick={this.goToSignUpPage}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        role="link"
        tabIndex="0"
      >
        Create Admin Account
      </div>
    );
  }
}

export default SignUpLink;
