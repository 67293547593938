import React, { PureComponent } from "react";
import Flex from "client/components/flex";
import MainNavbar from "client/navigation/main_navbar";

import styles from "./styles";

class Header extends PureComponent {
  render() {
    return (
      <Flex style={styles.header}>
        <MainNavbar />
      </Flex>
    );
  }
}

export default Header;
