import React, { PureComponent } from "react";
import { FcAddImage } from "react-icons/fc";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Page from "client/components/page";
import Flex from "client/components/flex";
import ItemsActions from "client/state/redux/items_redux";
import styles, { iconStyleProps } from "./styles";

function validateFile(file) {
  if (file.type !== "text/csv") {
    throw new Error("File must be a CSV!");
  }
}

function preventEventDefault(e) {
  e.stopPropagation();
  e.preventDefault();
}

class ItemImportPage extends PureComponent {
  static propTypes = {
    validateItemsCsv: PropTypes.func.isRequired
  };

  onFileDrop = (e) => {
    const { validateItemsCsv } = this.props;

    e.stopPropagation();
    e.preventDefault();

    const files = [];
    try {
      if (e.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        [...e.dataTransfer.items].forEach((item) => {
          // If dropped items aren't files, reject them
          if (item.kind === "file") {
            const file = item.getAsFile();
            validateFile(file);
            files.push(file);
          }
        });
      } else {
        // Use DataTransfer interface to access the file(s)
        [...e.dataTransfer.files].forEach((file) => {
          validateFile(file);
          files.push(file);
        });
      }
      if (files.length) {
        validateItemsCsv(files[0]);
      } else {
        throw new Error("Please upload a valid CSV file.");
      }
    } catch (err) {
      // eslint-disable-next-line no-alert
      window.alert(err.message);
    }
  };

  render() {
    return (
      <Page
        title="Bulk Import"
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          style={styles.container}
        >
          <div style={styles.interface}>
            <div
              onDragEnter={preventEventDefault}
              onDragOver={preventEventDefault}
              onDrop={this.onFileDrop}
              style={styles.importWrapper}
            >
              {React.createElement(FcAddImage, { ...iconStyleProps })}
              <div>Drag and drop the file here</div>
            </div>
          </div>
        </Flex>
      </Page>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  validateItemsCsv: file => dispatch(ItemsActions.validateItemsCsv(file))
});

export default connect(null, mapDispatchToProps)(ItemImportPage);
