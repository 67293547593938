import React, { PureComponent } from "react";
import { GROUP_FEATURE } from "@auxxit/auxxit-db/constants";
import Page from "client/components/page";
import Flex from "client/components/flex";
import styles from "./styles";

const features = Object.keys(GROUP_FEATURE);

class FeaturesIntegerPage extends PureComponent {
  state = {
    featuresInt: 0
  };

  handleClick = (evt) => {
    const { featuresInt } = this.state;
    const toggled = evt.target.name;
    if (evt.target.checked) {
      this.setState({ featuresInt: featuresInt + GROUP_FEATURE[toggled] });
    } else {
      this.setState({ featuresInt: featuresInt - GROUP_FEATURE[toggled] });
    }
  };

  render() {
    const { featuresInt } = this.state;
    return (
      <Page
        title="Features Integer"
      >
        <Flex style={styles.container} flexDirection="column">
          <Flex style={styles.interface}>
            <Flex flexDirection="column" style={styles.featuresColumn}>
              {features.map(feature => (
                <label htmlFor={feature} key={feature} style={styles.inputWrapper}>
                  {`${feature}:`}
                  <div>
                    <input type="checkbox" name={feature} onClick={this.handleClick} />
                  </div>
                </label>
              ))}
            </Flex>
            <div>
              <div style={styles.integerText}>{featuresInt}</div>
            </div>
          </Flex>
        </Flex>
      </Page>
    );
  }
}

export default FeaturesIntegerPage;
