import { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CurrentUserSelectors } from "client/state/redux/current_user_redux";

class AuthOnly extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.element
    ]).isRequired,
    isLoggedIn: PropTypes.bool.isRequired
  }

  render() {
    const { isLoggedIn, children } = this.props;
    return isLoggedIn ? children : null;
  }
}

const mapStateToProps = state => ({
  isLoggedIn: CurrentUserSelectors.getIsLoggedIn(state)
});

export default connect(mapStateToProps, null)(AuthOnly);
