import { call, put, takeLatest } from "redux-saga/effects";
import api from "client/services/api";
import ModelHelper from "./helpers/auction_model";
import AuctionsActions, { AuctionsTypes as types } from "../redux/auctions_redux";

function* fetchAuctions() {
  try {
    const response = yield call(api.fetchAuctions);
    const auctions = ModelHelper.sanitizeAuctions(response.data);
    yield put(AuctionsActions.fetchAuctionsSuccess(auctions));
  } catch (e) {
    yield put(AuctionsActions.fetchAuctionsFailure());
  }
}

function* modifyAuctions(action) {
  try {
    yield call(api.modifyAuctions, action.data);
    yield put(AuctionsActions.modifyAuctionsSuccess());
  } catch (e) {
    yield put(AuctionsActions.modifyAuctionsFailure());
  }
}

export default function auctionsSagas() {
  return [
    takeLatest(types.fetchAuctions, fetchAuctions),
    takeLatest(types.modifyAuctions, modifyAuctions)
  ];
}
