import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { NavbarButton } from "client/components/navbar_item";
import AuthActions from "client/state/redux/auth_redux";

class LogoutButton extends PureComponent {
  static propTypes = {
    attemptLogOut: PropTypes.func.isRequired,
  }

  handleClick = () => {
    this.props.attemptLogOut();
  }

  render() {
    return <NavbarButton onClick={this.handleClick}>Log Out</NavbarButton>;
  }
}

const mapDispatchToProps = dispatch => ({
  attemptLogOut: () => dispatch(AuthActions.attemptLogOut())
});

export default connect(null, mapDispatchToProps)(LogoutButton);
