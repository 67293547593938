import { Colors } from "client/themes";

const styles = {
  selectInput: {
    fontSize: 14,
    color: Colors.white,
    padding: 9,
    borderRadius: 6,
    backgroundColor: Colors.white,
    width: 200,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: Colors.auxxitSlateDarker
  },
  selectOption: {
    padding: 4,
    color: Colors.black,
    backgroundColor: Colors.white,
    paddingTop: 2
  }
};

export default styles;
