import history from "./history";

export default {
  toMainPage() {
    history.push("/");
  },
  toLoginPage() {
    history.push("/login");
  },
  toSignUpPage() {
    history.push("/signup");
  },
  toSettingsPage() {
    history.push("/settings");
  },
  toReleasePage() {
    history.push("/release");
  },
  toDisbursementsPage() {
    history.push("/disbursements");
  },
  toSellerDisbursementPage(userId) {
    history.push(`/seller-disbursements/${userId}`);
  },
  reloadMainPage() {
    window.location = "/";
  }
};
