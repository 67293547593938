function sanitizeAuction(data) {
  return {
    ...data,
    id: data._id
  };
}

function sanitizeAuctions(data) {
  return data.map(auction => sanitizeAuction(auction));
}

export default {
  sanitizeAuction,
  sanitizeAuctions
};
