import { Colors } from "client/themes";

const styles = {
  container: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center"
  },
  content: {
    height: "100%",
    width: "80%"
  },
  groupsWrapper: {
    flexWrap: "wrap"
  },
  globalWrapper: {
    backgroundColor: Colors.white,
    margin: 5,
    flexDirection: "column",
    borderRadius: 10,
    boxShadow: `3px 4px 4px${Colors.grey}`,
    padding: 10,
  },
  globalFee: {
    flexDirection: "row",
    margin: 2
  },
  groupFee: {
    marginBottom: 5
  },
  listWrapper: {
    backgroundColor: Colors.greyBlurred,
    borderRadius: 10,
    boxShadow: `3px 4px 4px${Colors.grey}`,
    marginRight: 10,
    paddingTop: 10,
    marginBottom: 10,
    height: "100%",
    overflowY: "scroll"
  },
  dataContainer: {
    display: "flex",
    flexDirection: "row",
    width: "98%",
    background: Colors.white,
    margin: "0 auto",
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 10,
    marginBottom: 10,
  },
  groupName: {
    fontWeight: "bold",
    marginLeft: 30,
    marginRight: 10
  },
  groupText: {
    marginLeft: 30,
    marginRight: 10,
    fontSize: 14
  },
  groupDetailsWrap: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "70%",
    marginRight: 20,
    flex: 1
  },
  groupDetailsRow: {
    display: "flex",
    flexDirection: "column",
  },
  reportsWrap: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    marginRight: 20
  },
  detailsRows: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  iconText: {
    fontSize: 12,
    color: Colors.greyBlurred,
  },
  downloadBtn: {
    backgroundColor: "transparent",
    border: 0,
    marginLeft: 10,
    color: Colors.linkBlue,
    textDecorationLine: "underline"
  },
  emptyListText: {
    marginTop: 20,
    textAlign: "center"
  },
  copyBtn: {
    cursor: "pointer"
  }
};

export const detailIconStyleProps = {
  color: Colors.white,
  size: 35,
  style: {
    alignSelf: "center",
    display: "inline-block",
  }
};

export const copyIconStyleProps = {
  color: Colors.grey,
  size: 22
};

export default styles;
