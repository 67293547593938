import React, { PureComponent } from "react";
import Flex from "client/components/flex";
import SideNavbar from "client/navigation/side_navbar";
import styles from "./styles";

class SideMenu extends PureComponent {
  render() {
    return (
      <Flex style={styles.sideMenu}>
        <SideNavbar />
      </Flex>
    );
  }
}

export default SideMenu;
