import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import Flex from "client/components/flex";
import { NavbarLink } from "client/components/navbar_item";
import NavbarLeft from "client/components/navbar_left";
import AuthOnly from "client/containers/auth_only";
import styles from "./styles";

class SideNavbar extends PureComponent {
  render() {
    return (
      <Flex
        flex={1}
        style={styles.sideNavbarContainer}
      >
        <div style={styles.linkContainer}>
          <NavbarLeft>
            <NavbarLink href="/">
              <div style={styles.titleContainer}>
                <img alt="img" width={200} src="/assets/auxxit_official_text.png" style={styles.img} />
                <div style={styles.iconText}>Administration Portal</div>
              </div>
            </NavbarLink>
          </NavbarLeft>
          <AuthOnly>
            <NavbarLink href="/">
              <div style={styles.link}>Data Grid</div>
            </NavbarLink>
            <NavbarLink href="/release">
              <div style={styles.link}>Draft Release</div>
            </NavbarLink>
            <NavbarLink href="/block">
              <div style={styles.link}>Block Device</div>
            </NavbarLink>
            <NavbarLink href="/users">
              <div style={styles.link}>Users</div>
            </NavbarLink>
            <NavbarLink href="/disbursements">
              <div style={styles.link}>Disbursements</div>
            </NavbarLink>
            <NavbarLink href="/groups">
              <div style={styles.link}>Groups</div>
            </NavbarLink>
            <NavbarLink href="/groups-gmv">
              <div style={styles.link}>Groups GMV</div>
            </NavbarLink>
            <NavbarLink href="/private-offers">
              <div style={styles.link}>Private Offers</div>
            </NavbarLink>
            <NavbarLink href="/group-sets">
              <div style={styles.link}>Group Sets</div>
            </NavbarLink>
            <NavbarLink href="/group-fees">
              <div style={styles.link}>Group Fees</div>
            </NavbarLink>
            <NavbarLink href="/item-import">
              <div style={styles.link}>Bulk Import</div>
            </NavbarLink>
            <NavbarLink href="/features-int">
              <div style={styles.link}>Features Integer</div>
            </NavbarLink>
          </AuthOnly>
        </div>
      </Flex>
    );
  }
}

export default withRouter(SideNavbar);
