import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import GroupsActions, { GroupsSelectors } from "client/state/redux/groups_redux";
import Flex from "client/components/flex";
import DataGrid from "client/components/data_grid";
import styles from "./styles";

const GroupModel = {
  id: {
    type: String,
    editable: false
  },
  createdBy: {
    type: String,
    editable: true
  },
  name: {
    type: String,
    editable: true
  },
  description: {
    type: String,
    editable: true
  },
  icon: {
    type: String,
    editable: true
  },
  categories: {
    type: Array,
    editable: true
  },
  createDate: {
    type: Date,
    editable: false
  },
  lastModified: {
    type: Date,
    editable: false
  }
};

class GroupsInterface extends Component {
  static propTypes = {
    fetchGroups: PropTypes.func.isRequired,
    groups: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    modifyGroups: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      changes: {}
    };
  }

  componentDidMount() {
    const { fetchGroups } = this.props;
    fetchGroups();
  }

  handleChange = (id, field) => (value, noChange) => {
    this.setState((prevState) => {
      const newChanges = { ...prevState.changes };
      // if no change, delete that field
      if (noChange) {
        delete newChanges[id][field];
        // if no change and object now empty, delete object
        if (!Object.keys(newChanges[id]).length) {
          delete newChanges[id];
        }
      // else, make change to requested field
      } else {
        newChanges[id] = { ...newChanges[id], [field]: value };
      }
      return { changes: newChanges };
    });
  };

  handleSave = () => {
    const { changes } = this.state;
    this.props.modifyGroups(changes);
  };

  render() {
    const { groups } = this.props;
    const { changes } = this.state;
    const showSave = Object.keys(changes).length > 0;
    return (
      <Flex flex={1} style={styles.interface}>
        <DataGrid
          data={groups}
          model={GroupModel}
          showSave={showSave}
          handleEdit={this.handleChange}
          handleSave={this.handleSave}
        />
      </Flex>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  fetchGroups: () => dispatch(GroupsActions.fetchGroups()),
  modifyGroups: data => dispatch(GroupsActions.modifyGroups(data))
});

const mapStateToProps = state => ({
  groups: GroupsSelectors.getGroups(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupsInterface);
