const TOKEN_KEY = "AuxxitStore:authToken";
const myStorage = window.localStorage;
const { atob } = window;

function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(c => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)) // eslint-disable-line
      .join("")
  );
  return JSON.parse(jsonPayload);
}

async function getAuthToken() {
  try {
    const authTokenJson = await myStorage.getItem(TOKEN_KEY);
    if (authTokenJson) {
      return JSON.parse(authTokenJson);
    }
    return null;
  } catch (error) {
    return null;
  }
}

async function setAuthToken({ accessToken, expiredIn, refreshToken }) {
  const { id: userId } = parseJwt(accessToken.replace("Bearer ", ""));
  await myStorage.setItem(
    TOKEN_KEY,
    JSON.stringify({ accessToken, expiredIn, refreshToken, userId })
  );
}

async function deleteAuthToken() {
  myStorage.removeItem(TOKEN_KEY);
}

export default {
  deleteAuthToken,
  getAuthToken,
  setAuthToken,
};
