import { Colors } from "client/themes";

const styles = {
  container: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center"
  },
  interface: {
    height: "90%",
    width: "90%",
    backgroundColor: Colors.greyBlurred,
    borderRadius: 10,
    boxShadow: `3px 4px 4px${Colors.grey}`,
    marginRight: 10,
    paddingTop: 10,
    marginBottom: 10
  },
  featuresColumn: {
    margin: 20,
    position: "relative"
  },
  inputWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: 300,
    marginBottom: 20,
    borderBottomColor: Colors.black,
    borderBottomWidth: 1,
    borderBottomStyle: "solid"
  },
  integerText: {
    fontSize: 24,
    fontWeight: "bold",
    margin: 20,
    padding: 10,
    borderWidth: 2,
    borderStyle: "solid"
  }
};

export default styles;
