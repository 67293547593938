import { Map, List, fromJS } from "immutable";
import memoize from "fast-memoize";

const INIT_STATE = Map({
  isFetching: false,
  isSubmitting: false,
  feedback: List([])
});

const types = {
  fetchFeedback: "FETCH_FEEDBACK",
  fetchFeedbackSuccess: "FETCH_FEEDBACK_SUCCESS",
  fetchFeedbackFailure: "FETCH_FEEDBACK_FAILURE",
  modifyFeedback: "MODIFY_FEEDBACK",
  modifyFeedbackSuccess: "MODIFY_FEEDBACK_SUCCESS",
  modifyFeedbackFailure: "MODIFY_FEEDBACK_FAILURE"
};

/* ********* Actions ********* */

const actions = {
  fetchFeedback() {
    return {
      type: types.fetchFeedback
    };
  },
  fetchFeedbackSuccess(data) {
    return {
      type: types.fetchFeedbackSuccess,
      data
    };
  },
  fetchFeedbackFailure(data) {
    return {
      type: types.fetchFeedbackFailure,
      data
    };
  },
  modifyFeedback(data) {
    return {
      type: types.modifyFeedback,
      data
    };
  },
  modifyFeedbackSuccess() {
    return {
      type: types.modifyFeedbackSuccess
    };
  },
  modifyFeedbackFailure() {
    return {
      type: types.modifyFeedbackFailure
    };
  }
};

/* ********* Reducer ********* */

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case types.fetchFeedback:
    return state.merge({ isFetching: true });
  case types.fetchFeedbackSuccess:
    return state.merge({ isFetching: false, feedback: fromJS(action.data) });
  case types.fetchFeedbackFailure:
    return state.merge({ isFetching: false });
  case types.modifyFeedback:
    return state.merge({ isSubmitting: true });
  case types.modifyFeedbackSuccess:
    return state.merge({ isSubmitting: false });
  case types.modifyFeedbackFailure:
    return state.merge({ isSubmitting: false });
  default:
    return state;
  }
};

/* ********* Selectors ********* */

const feedback = state => state.get("feedback");

const selectors = {
  getFeedback: memoize(state => feedback(state).get("feedback").toJS())
};

export {
  types as FeedbackTypes,
  reducer as FeedbackReducer,
  selectors as FeedbackSelectors
};
export default actions;
