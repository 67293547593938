import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AuthActions, { AuthSelectors } from "client/state/redux/auth_redux";
import Page from "client/components/page";
import Flex from "client/components/flex";
import SignUpForm from "./components/sign_up_form";

class SignUpPage extends Component {
  static propTypes = {
    attemptSignUp: PropTypes.func.isRequired,
    setSignUpMessage: PropTypes.func.isRequired,
    signUpMessage: PropTypes.string,
  };

  static defaultProps = {
    signUpMessage: null
  };

  constructor(props) {
    super(props);
    this.state = {
      form: {
        username: "",
        email: "",
        password: "",
        password2: "",

      },
    };
  }

  handleChange = (name, value) => {
    const { setSignUpMessage } = this.props;
    const { form } = this.state;
    this.setState({ form: { ...form, [name]: value } });
    setSignUpMessage(null);
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { attemptSignUp, setSignUpMessage } = this.props;
    const { form } = this.state;

    if (form.username.length
      && form.email.length
      && form.password.length
      && form.password2.length
    ) {
      attemptSignUp(form);
      return;
    }
    setSignUpMessage("All fields are required");
  }

  render() {
    const { signUpMessage } = this.props;
    const { form } = this.state;
    return (
      <Page
        title="Admin - Sign Up"
      >
        <Flex
          flex={8}
          alignItems="center"
          justifyContent="center"
        >
          <SignUpForm
            form={form}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
          />
        </Flex>
        <Flex
          flex={1}
          alignItems="center"
          justifyContent="center"
        >
          {signUpMessage}
        </Flex>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  signUpMessage: AuthSelectors.getSignUpMessage(state)
});

const mapDispatchToProps = dispatch => ({
  attemptSignUp: details => dispatch(AuthActions.attemptSignUp(details)),
  setSignUpMessage: text => dispatch(AuthActions.setSignUpMessage(text))
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPage);
