import api from "./client";

function fetchReleases(params) {
  return api.get("/releases", params);
}

function draftRelease(params) {
  return api.post("/releases", params);
}

export default {
  fetchReleases,
  draftRelease
};
