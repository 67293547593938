function sanitizeRelease(release) {
  return {
    id: release._id,
    ...release
  };
}

function sanitizeReleases(data) {
  return data.map(release => sanitizeRelease(release));
}

export default {
  sanitizeRelease,
  sanitizeReleases
};
