import Colors from "./colors";

const envStyles = {
  development: {
    color: Colors.auxxitBlue
  },
  staging: {
    color: Colors.darkGreen
  },
  production: {
    color: Colors.auxxitOrange
  },
  replay: {
    color: Colors.replayYellow
  }
};

export { Colors, envStyles }; // eslint-disable-line
