import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Flex from "client/components/flex";
import DataCell from "./components/data_cell";
import styles from "./styles";

class DataGrid extends PureComponent {
  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape()
    ).isRequired,
    dataKey: PropTypes.string,
    handleEdit: PropTypes.func,
    handleSave: PropTypes.func,
    model: PropTypes.objectOf(
      PropTypes.shape({
        editable: PropTypes.bool,
        type: PropTypes.func
      })
    ).isRequired,
    showSave: PropTypes.bool.isRequired
  };

  static defaultProps = {
    dataKey: "id",
    handleEdit: Function.prototype,
    handleSave: Function.prototype
  };

  renderFieldsRow = () => (
    <tr>
      {Object.keys(this.props.model).map(field => (
        <th key={field}>{field}</th>
      ))}
    </tr>
  );

  renderDataRow = (dataRow) => {
    const { model, dataKey, handleEdit } = this.props;
    return (
      <tr key={dataRow[dataKey]}>
        {Object.keys(model).map((field) => {
          const onEdit = handleEdit(dataRow[dataKey], field);
          return (
            <DataCell
              data={dataRow[field]}
              editable={model[field].editable}
              onEdit={onEdit}
              key={`${dataRow[dataKey]}-${field}`}
            />
          );
        })}
      </tr>
    );
  };

  render() {
    const { data, handleSave, showSave } = this.props;
    return (
      <Flex flex={1} style={styles.container}>
        <div className="table_wrap">
          <table className="data_table">
            <thead>
              {this.renderFieldsRow()}
            </thead>
            <tbody>
              {data.map((dataRow, i) => this.renderDataRow(dataRow, i))}
            </tbody>
          </table>
        </div>
        {showSave && (
          <Flex justifyContent="flex-end" style={styles.saveOverlay}>
            <button
              style={styles.saveBtn}
              onClick={handleSave}
              type="button"
            >
              Save Changes
            </button>
          </Flex>
        )}
      </Flex>
    );
  }
}

export default DataGrid;
