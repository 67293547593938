import { call, put, takeLatest, take } from "redux-saga/effects";
import api from "client/services/api";
import AuthTokenStorage from "client/services/auth_token_storage";
import NavigationService from "client/services/navigation_service";
import AuthActions, { AuthTypes } from "../redux/auth_redux";
import CurrentUserActions, { CurrentUserTypes } from "../redux/current_user_redux";
import StartupActions, { StartupTypes as types } from "../redux/startup_redux";

function* authenticate() {
  const authToken = yield call(AuthTokenStorage.getAuthToken);
  if (!authToken) return false;

  const isValid = authToken.expiredIn > Date.now();
  if (isValid) {
    yield call(api.client.setAccessToken, authToken.accessToken);
    return true;
  }

  yield put(AuthActions.attemptRefreshToken());
  const result = yield take([
    AuthTypes.refreshTokenSuccess,
    AuthTypes.refreshTokenFailure
  ]);
  const isRefreshed = result.type === AuthTypes.refreshTokenSuccess;
  if (isRefreshed) return true;

  yield call(AuthTokenStorage.deleteAuthToken);
  return false;
}

function* startup() {
  const isAuthenticated = yield* authenticate();
  if (!isAuthenticated) {
    yield put(StartupActions.startupComplete());
    yield call(NavigationService.toLoginPage);
    return;
  }
  yield put(AuthActions.logInSuccess());

  yield put(CurrentUserActions.fetchCurrentUser());
  yield take([
    CurrentUserTypes.fetchCurrentUserSuccess,
    CurrentUserTypes.fetchCurrentUserFailure
  ]);

  yield put(StartupActions.startupComplete());
}

export default function startupSagas() {
  return [takeLatest(types.startup, startup)];
}
