function sanitizeGroup(data) {
  return {
    ...data,
    id: data._id
  };
}

function sanitizeGroups(data) {
  return data.map(group => sanitizeGroup(group));
}

function sanitizeGroupSets(data) {
  return data.map(s => ({ ...s, id: s._id, groups: s.groups.map(g => ({ ...g, id: g._id })) }));
}

function sanitizeGroupFees(data) {
  return data.map(g => ({ ...g, id: g._id }));
}

function sanitizeGroupsGmv(data) {
  return data.map(g => ({ ...g, id: g._id, gmvUsd: `$${g.gmv.toFixed(2)}` }));
}

export default {
  sanitizeGroup,
  sanitizeGroups,
  sanitizeGroupSets,
  sanitizeGroupsGmv,
  sanitizeGroupFees
};
