import { List, Map, fromJS } from "immutable";
import memoize from "fast-memoize";

const DEFAULT_GROUPS_GMV = List([
  {
    id: "Loading...",
    name: "Loading...",
    count: 0,
    gmv: 0,
    gmvUsd: "$0.00"
  }
]);

const INIT_STATE = Map({
  groupsGmv: DEFAULT_GROUPS_GMV,
  isFetching: false
});

const types = {
  fetchGroupsGrossMerchandiseValue: "FETCH_GROUPS_GROSS_MERCHANDISE_VALUE",
  fetchGroupsGrossMerchandiseValueSuccess: "FETCH_GROUPS_GROSS_MERCHANDISE_VALUE_SUCCESS",
  fetchGroupsGrossMerchandiseValueFailure: "FETCH_GROUPS_GROSS_MERCHANDISE_VALUE_FAILURE",
  downloadLedgerSummary: "DOWNLOAD_LEDGER_SUMMARY"
};

/* ********* Actions ********* */

const actions = {
  fetchGroupsGrossMerchandiseValue(month, year) {
    return {
      type: types.fetchGroupsGrossMerchandiseValue,
      month,
      year
    };
  },
  fetchGroupsGrossMerchandiseValueSuccess(data) {
    return {
      type: types.fetchGroupsGrossMerchandiseValueSuccess,
      data
    };
  },
  fetchGroupsGrossMerchandiseValueFailure() {
    return {
      type: types.fetchGroupsGrossMerchandiseValueFailure
    };
  },
  downloadLedgerSummary(month, year) {
    return {
      type: types.downloadLedgerSummary,
      month,
      year
    };
  }
};

/* ********* Reducer ********* */

// eslint-disable-next-line default-param-last
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case types.fetchGroupsGrossMerchandiseValue:
    return state.merge({ isFetching: true, groupsGmv: DEFAULT_GROUPS_GMV });
  case types.fetchGroupsGrossMerchandiseValueSuccess:
    return state.merge({ isFetching: false, groupsGmv: fromJS(action.data) });
  case types.fetchGroupsGrossMerchandiseValueFailure:
    return state.merge({ isFetching: false });
  default:
    return state;
  }
};

/* ********* Selectors ********* */

const ledgers = state => state.get("ledgers");

const selectors = {
  getGroupsGmv: memoize(state => ledgers(state).get("groupsGmv").toJS())
};

export {
  types as LedgersTypes,
  reducer as LedgersReducer,
  selectors as LedgersSelectors
};
export default actions;
