import { call, put, takeLatest } from "redux-saga/effects";
import api from "client/services/api";
import ExposActions, { ExposTypes as types } from "../redux/expos_redux";

function* createExpo(action) {
  try {
    const response = yield call(api.createExpo, action.data);
    yield put(ExposActions.createExpoSuccess(response.data));
  } catch (e) {
    yield put(ExposActions.createExpoFailure(e.response.data));
  }
}

export default function usersSagas() {
  return [
    takeLatest(types.createExpo, createExpo)
  ];
}
