import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PrivateOffersActions, { PrivateOffersSelectors } from "client/state/redux/private_offers_redux";
import Page from "client/components/page";
import Flex from "client/components/flex";
import DataGrid from "client/components/data_grid";
import styles from "./styles";

const PrivateOffersModel = {
  id: {
    type: String,
    editable: false
  },
  name: {
    type: String,
    editable: false
  },
  count: {
    type: Number,
    editable: false
  },
  offersAcceptedCount: {
    type: Number,
    editable: false
  },
  gmvUsd: {
    type: Number,
    editable: false
  }
};

const MONTH_OPTIONS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const START_YEAR = 2021;
const YEAR_COUNT = new Date().getFullYear() - START_YEAR + 1;
const YEAR_OPTIONS = new Array(YEAR_COUNT).fill().map((y, idx) => new Date().getFullYear() - idx);

class PrivateOffersPage extends Component {
  static propTypes = {
    downloadPrivateOffersData: PropTypes.func.isRequired,
    fetchGroupsPrivateOffers: PropTypes.func.isRequired,
    privateOffers: PropTypes.arrayOf(PropTypes.shape())
  };

  static defaultProps = {
    privateOffers: []
  };

  static renderOptions = options => (
    Object.values(options).map(option => (
      <option
        key={option}
        style={styles.selectOption}
        value={option}
      >
        {option}
      </option>
    ))
  );

  state = {
    selectedMonth: MONTH_OPTIONS[new Date().getMonth()],
    selectedYear: new Date().getFullYear()
  };

  componentDidMount() {
    const { fetchGroupsPrivateOffers } = this.props;
    fetchGroupsPrivateOffers();
  }

  handleMonthDropdownChange = (e) => {
    const { fetchGroupsPrivateOffers } = this.props;
    const { selectedYear } = this.state;
    const { value: newSelectedMonth } = e.target;
    this.setState({ selectedMonth: newSelectedMonth });
    const month = MONTH_OPTIONS.indexOf(newSelectedMonth);
    fetchGroupsPrivateOffers(month, selectedYear);
  };

  handleYearDropdownChange = (e) => {
    const { fetchGroupsPrivateOffers } = this.props;
    const { selectedMonth } = this.state;
    const { value: newSelectedYear } = e.target;
    this.setState({ selectedYear: newSelectedYear });
    const month = MONTH_OPTIONS.indexOf(selectedMonth);
    fetchGroupsPrivateOffers(month, newSelectedYear);
  };

  render() {
    const { privateOffers, downloadPrivateOffersData } = this.props;
    const { selectedMonth, selectedYear } = this.state;
    const gmvTotal = privateOffers.reduce((total, g) => total + g.offersAcceptedGmv, 0);
    return (
      <Page
        title="Private Offers"
      >
        <Flex style={styles.container} flexDirection="column">
          <Flex style={styles.contentHeader}>
            <Flex style={{ marginRight: 5 }}>
              <select
                style={styles.selectInput}
                value={selectedMonth}
                onChange={this.handleMonthDropdownChange}
              >
                {PrivateOffersPage.renderOptions(MONTH_OPTIONS)}
              </select>
            </Flex>
            <Flex style={{ marginLeft: 5, marginRight: 10 }}>
              <select
                style={styles.selectInput}
                value={selectedYear}
                onChange={this.handleYearDropdownChange}
              >
                {PrivateOffersPage.renderOptions(YEAR_OPTIONS)}
              </select>
            </Flex>
            <div style={styles.totalGmvWrapper}>
              <b>Total: </b>
              <span>{`$${gmvTotal.toFixed(2)}`}</span>
            </div>
            <button style={styles.downloadBtn} type="button" onClick={downloadPrivateOffersData}>Download Last 7 Days</button>
          </Flex>
          <Flex flex={1} style={styles.interface}>
            <DataGrid
              data={privateOffers}
              model={PrivateOffersModel}
              showSave={false}
            />
          </Flex>
        </Flex>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  privateOffers: PrivateOffersSelectors.getGroupsPrivateOffers(state),
});

const mapDispatchToProps = dispatch => ({
  fetchGroupsPrivateOffers: (month, year) =>
    dispatch(PrivateOffersActions.fetchGroupsPrivateOffers(month, year)),
  downloadPrivateOffersData: () =>
    dispatch(PrivateOffersActions.downloadPrivateOffersLast7Days())
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateOffersPage);
