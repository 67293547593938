import { Map, List, fromJS } from "immutable";
import memoize from "fast-memoize";
import { CURSOR_STAGE } from "client/constants";

const INIT_STATE = Map({
  isFetching: false,
  sellersCursor: Map({
    stage: CURSOR_STAGE.DISBURSEMENT_SELLERS.UNDISBURSED,
    last: null,
    sortField: "seller.createDate",
    sortDirection: -1
  }),
  disbursementsCursor: null,
  pendingInfo: Map(),
  sellers: List([]),
  sellerScrollTop: 0,
  sellerSearchResults: List([]),
  sellerDisbursements: List([]),
  sellerOverallData: Map(),
  sellerDetails: Map(),
});

const types = {
  fetchSellersForDisbursement: "FETCH_SELLERS_FOR_DISBURSEMENT",
  fetchMoreSellersForDisbursement: "FETCH_MORE_SELLERS_FOR_DISBURSEMENT",
  fetchSellersForDisbursementFailure: "FETCH_SELLERS_FOR_DISBURSEMENT_FAILURE",
  fetchSellersForDisbursementSuccess: "FETCH_SELLERS_FOR_DISBURSEMENT_SUCCESS",
  fetchMoreSellersForDisbursementSuccess: "FETCH_MORE_SELLERS_FOR_DISBURSEMENT_SUCCESS",
  setSellersScrollTop: "SET_SELLERS_SCROLL_TOP",
  fetchPendingDisbursementsData: "FETCH_PENDING_DISBURSEMENTS_DATA",
  fetchPendingDisbursementsDataSuccess: "FETCH_PENDING_DISBURSEMENTS_DATA_SUCCESS",
  fetchPendingDisbursementsDataFailure: "FETCH_PENDING_DISBURSEMENTS_DATA_FAILURE",
  searchSellersForDisbursement: "SEARCH_SELLERS_FOR_DISBURSEMENT",
  searchSellersForDisbursementSuccess: "SEARCH_SELLERS_FOR_DISBURSEMENT_SUCCESS",
  searchSellersForDisbursementFailure: "SEARCH_SELLERS_FOR_DISBURSEMENT_FAILURE",
  clearSellerSearchResults: "CLEAR_SELLER_SEARCH_RESULTS",
  fetchSellerDisbursementData: "FETCH_SELLER_DISBURSEMENT_DATA",
  fetchSellerDisbursementDataSuccess: "FETCH_SELLER_DISBURSEMENT_DATA_SUCCESS",
  fetchSellerDisbursementDataFailure: "FETCH_SELLER_DISBURSEMENT_DATA_FAILURE",
  fetchMoreSellerDisbursements: "FETCH_MORE_SELLER_DISBURSEMENTS",
  fetchMoreSellerDisbursementsSuccess: "FETCH_MORE_SELLER_DISBURSEMENTS_SUCCESS",
  fetchMoreSellerDisbursementsFailure: "FETCH_MORE_SELLER_DISBURSEMENTS_FAILURE",
  fetchSellerExceptions: "FETCH_SELLER_EXCEPTIONS",
  fetchSellerInfoSuccess: "FETCH_SELLER_INFO_SUCCESS",
  markItemsAsDisbursed: "MARK_ITEMS_AS_DISBURSED",
  markItemsAsDisbursedSuccess: "MARK_ITEMS_AS_DISBURSED_SUCCESS",
  markItemsAsDisbursedFailure: "MARK_ITEMS_AS_DISBURSED_FAILURE",
  makeException: "MAKE_EXCEPTION",
  makeExceptionSuccess: "MAKE_EXCEPTION_SUCCESS",
  makeExceptionFailure: "MAKE_EXCEPTION_FAILURE",
  downloadUserTransactionHistory: "DOWNLOAD_USER_TRANSACTION_HISTORY",
};

const actions = {
  fetchSellersForDisbursement(data) {
    return {
      type: types.fetchSellersForDisbursement,
      data
    };
  },
  fetchMoreSellersForDisbursement(data) {
    return {
      type: types.fetchMoreSellersForDisbursement,
      data
    };
  },
  fetchSellersForDisbursementFailure() {
    return {
      type: types.fetchSellersForDisbursementFailure,
    };
  },
  fetchSellersForDisbursementSuccess(data, cursor) {
    return {
      type: types.fetchSellersForDisbursementSuccess,
      data,
      cursor
    };
  },
  fetchMoreSellersForDisbursementSuccess(data, cursor) {
    return {
      type: types.fetchMoreSellersForDisbursementSuccess,
      data,
      cursor
    };
  },
  setSellersScrollTop(data) {
    return {
      type: types.setSellersScrollTop,
      data
    };
  },
  fetchPendingDisbursementsData() {
    return {
      type: types.fetchPendingDisbursementsData
    };
  },
  fetchPendingDisbursementsDataSuccess(data) {
    return {
      type: types.fetchPendingDisbursementsDataSuccess,
      data
    };
  },
  fetchPendingDisbursementsDataFailure() {
    return {
      type: types.fetchPendingDisbursementsDataFailure
    };
  },
  searchSellersForDisbursement(query) {
    return {
      type: types.searchSellersForDisbursement,
      query
    };
  },
  searchSellersForDisbursementSuccess(data) {
    return {
      type: types.searchSellersForDisbursementSuccess,
      data
    };
  },
  searchSellersForDisbursementFailure(data) {
    return {
      type: types.searchSellersForDisbursementFailure,
      data
    };
  },
  clearSellerSearchResults() {
    return {
      type: types.clearSellerSearchResults
    };
  },
  fetchSellerDisbursementData(userId, isDisbursed) {
    return {
      type: types.fetchSellerDisbursementData,
      userId,
      isDisbursed,
    };
  },
  fetchSellerDisbursementDataFailure() {
    return {
      type: types.fetchSellerDisbursementDataFailure,
    };
  },
  fetchSellerDisbursementDataSuccess(data) {
    return {
      type: types.fetchSellerDisbursementDataSuccess,
      data
    };
  },
  fetchMoreSellerDisbursements(userId, isDisbursed) {
    return {
      type: types.fetchMoreSellerDisbursements,
      userId,
      isDisbursed,
    };
  },
  fetchMoreSellerDisbursementsSuccess(data) {
    return {
      type: types.fetchMoreSellerDisbursementsSuccess,
      data
    };
  },
  fetchMoreSellerDisbursementsFailure() {
    return {
      type: types.fetchMoreSellerDisbursementsFailure,
    };
  },
  fetchSellerExceptions(userId) {
    return {
      type: types.fetchSellerExceptions,
      userId
    };
  },
  fetchSellerInfoSuccess(data) {
    return {
      type: types.fetchSellerInfoSuccess,
      data
    };
  },
  markItemsAsDisbursed(ledgerIds, userId) {
    return {
      type: types.markItemsAsDisbursed,
      ledgerIds,
      userId
    };
  },
  markItemsAsDisbursedSuccess() {
    return {
      type: types.markItemsAsDisbursedSuccess,
    };
  },
  markItemsAsDisbursedFailure() {
    return {
      type: types.markItemsAsDisbursedFailure,
    };
  },
  makeException(ledgerIds, userId, exception, isDisbursed = false) {
    return {
      type: types.makeException,
      ledgerIds,
      userId,
      exception,
      isDisbursed
    };
  },
  makeExceptionSuccess() {
    return {
      type: types.makeExceptionSuccess
    };
  },
  makeExceptionFailure() {
    return {
      type: types.makeExceptionFailure
    };
  },
  downloadUserTransactionHistory(userId) {
    return {
      type: types.downloadUserTransactionHistory,
      userId
    };
  }
};

// eslint-disable-next-line default-param-last
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case types.fetchSellersForDisbursement:
    return state.merge({ isFetching: true });
  case types.fetchMoreSellersForDisbursement:
    return state.merge({ isFetching: true });
  case types.fetchSellersForDisbursementFailure:
    return state.merge({ isFetching: false });
  case types.fetchSellersForDisbursementSuccess:
    return state.merge({
      isFetching: false,
      sellers: fromJS(action.data),
      sellersCursor: fromJS(action.cursor)
    });
  case types.fetchMoreSellersForDisbursementSuccess: {
    return state.merge({
      isFetching: false,
      sellers: state.get("sellers").concat(action.data),
      sellersCursor: fromJS(action.cursor)
    });
  }
  case types.setSellersScrollTop:
    return state.merge({ sellersScrollTop: action.data });
  case types.fetchPendingDisbursementsData:
    return state.merge({ isFetching: true });
  case types.fetchPendingDisbursementsDataSuccess:
    return state.merge({ isFetching: false, pendingInfo: Map(action.data) });
  case types.fetchPendingDisbursementsDataFailure:
    return state.merge({ isFetching: false });
  case types.searchSellersForDisbursement:
    return state.merge({ isFetching: true });
  case types.searchSellersForDisbursementSuccess:
    return state.merge({ isFetching: false, sellerSearchResults: fromJS(action.data) });
  case types.searchSellersForDisbursementFailure:
    return state.merge({ isFetching: false });
  case types.clearSellerSearchResults:
    return state.merge({ sellerSearchResults: List([]) });
  case types.fetchSellerDisbursementData:
    return state.merge({ isFetching: true });
  case types.fetchSellerDisbursementDataFailure:
    return state.merge({ isFetching: false });
  case types.fetchSellerDisbursementDataSuccess:
    return state.merge({
      isFetching: false,
      sellerDisbursements: fromJS(action.data.disbursements),
      disbursementsCursor: action.data.cursor
    });
  case types.fetchMoreSellerDisbursements:
    return state.merge({ isFetching: true });
  case types.fetchMoreSellerDisbursementsSuccess: {
    const sellerDisbursements = [...state.get("sellerDisbursements")];
    const newSellerDisbursements = List(sellerDisbursements.concat(action.data.disbursements));
    return state.merge({
      isFetching: false,
      sellerDisbursements: newSellerDisbursements,
      disbursementsCursor: action.data.cursor
    });
  }
  case types.fetchMoreSellersForDisbursementsFailure:
    return state.merge({ isFetching: false });
  case types.fetchSellerExceptions:
    return state.merge({ isFetching: true });
  case types.fetchSellerInfoSuccess:
    return state.merge({
      isFetching: false,
      sellerOverallData: Map(action.data.overallData),
      sellerDetails: Map(action.data.sellerDetails),
    });
  case types.markItemsAsDisbursed:
    return state.merge({ isSubmitting: true });
  case types.markItemsAsDisbursedSuccess:
    return state.merge({ isSubmitting: false });
  case types.markItemsAsDisbursedFailure:
    return state.merge({ isSubmitting: false });
  case types.makeException:
    return state.merge({ isSubmitting: true });
  case types.makeExceptionSuccess:
    return state.merge({ isSubmitting: false });
  case types.makeExceptionFailure:
    return state.merge({ isSubmitting: false });
  default:
    return state;
  }
};

/* ********* Selectors ********* */

const disbursements = state => state.get("disbursements");

const selectors = {
  getDisbursements: memoize(state => disbursements(state).get("sellerDisbursements").toJS()),
  getSellers: memoize(state => disbursements(state).get("sellers").toJS()),
  getSellerDetails: memoize(state => disbursements(state).get("sellerDetails").toJS()),
  getSellerOverallData: memoize(state => disbursements(state).get("sellerOverallData").toJS()),
  getUsernameFromId: memoize((state, userId) => {
    const sellers = disbursements(state).get("sellers").toJS();
    let username = "";
    for (let i = 0; i < sellers.length; i++) {
      if (sellers[i].id === userId) {
        username = sellers[i].seller.username;
      }
    }
    return username;
  }),
  getSellersCursor: memoize(state => disbursements(state).get("sellersCursor").toJS()),
  getSellerSearchResults: memoize(state => disbursements(state).get("sellerSearchResults").toJS()),
  getSellersScrollTop: memoize(state => disbursements(state).get("sellersScrollTop")),
  getPendingInfo: memoize(state => disbursements(state).get("pendingInfo").toJS()),
  getDisbursementsCursor: state => disbursements(state).get("disbursementsCursor"),
};

export {
  types as DisbursementsTypes,
  reducer as DisbursementsReducer,
  selectors as DisbursementsSelectors,
};
export default actions;
