import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AuthActions, { AuthSelectors } from "client/state/redux/auth_redux";
import Page from "client/components/page";
import Flex from "client/components/flex";
import LogInForm from "./components/login_form";

class LoginPage extends Component {
  static propTypes = {
    attemptLogIn: PropTypes.func.isRequired,
    loginMessage: PropTypes.string,
    setLoginMessage: PropTypes.func.isRequired
  };

  static defaultProps = {
    loginMessage: null
  };

  constructor(props) {
    super(props);
    this.state = {
      form: {
        username: "",
        password: ""
      },
    };
  }

  handleChange = (name, value) => {
    const { setLoginMessage } = this.props;
    const { form } = this.state;
    this.setState({ form: { ...form, [name]: value } });
    setLoginMessage(null);
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { attemptLogIn, setLoginMessage } = this.props;
    const { form } = this.state;

    if (form.username.length && form.password.length) {
      attemptLogIn(form);
      return;
    }
    setLoginMessage("Username and password are required");
  }

  render() {
    const { loginMessage } = this.props;
    const { form } = this.state;
    return (
      <Page
        title="Admin - Log In"
      >
        <Flex
          flex={8}
          alignItems="center"
          justifyContent="center"
        >
          <LogInForm
            form={form}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
          />
        </Flex>
        <Flex
          flex={1}
          alignItems="center"
          justifyContent="center"
        >
          {loginMessage}
        </Flex>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  loginMessage: AuthSelectors.getLoginMessage(state)
});

const mapDispatchToProps = dispatch => ({
  attemptLogIn: credentials => dispatch(AuthActions.attemptLogIn(credentials)),
  setLoginMessage: text => dispatch(AuthActions.setLoginMessage(text))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
