import { Colors } from "client/themes";

const styles = {
  container: {
    overflow: "hidden",
    position: "relative",
    justifyContent: "center"
  },
  saveOverlay: {
    position: "absolute",
    bottom: 10, // padding from scroll bar
    width: "100%",
    backgroundColor: "rgba(255,255,255,0.75)",
    padding: 10
  },
  saveBtn: {
    backgroundColor: Colors.auxxitBlue,
    color: Colors.white,
    border: "none",
    fontSize: 20,
    padding: 10,
    borderRadius: 10
  }
};

export default styles;
