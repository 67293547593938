import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Flex from "client/components/flex";
import { CurrentUserSelectors } from "client/state/redux/current_user_redux";
import styles from "./styles";

class UserText extends PureComponent {
  static propTypes = {
    currentUser: PropTypes.shape().isRequired,
  };

  render() {
    const { currentUser } = this.props;
    return (
      <Flex style={{ padding: 8 }}>
        <div style={styles.userText}>{`Welcome, ${currentUser.username}`}</div>
      </Flex>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: CurrentUserSelectors.getCurrentUser(state),
});

export default connect(mapStateToProps, null)(UserText);
