import { Colors } from "client/themes";

const styles = {
  iconText: {
    letterSpacing: 5,
    fontSize: 14,
    color: Colors.white
  },
  link: {
    flex: "1",
    color: Colors.white,
    marginLeft: 15,
    fontSize: "1em"
  },
  linkContainer: {
    background: Colors.grey,
    flex: "1"
  },
  sideNavbarContainer: {
    background: Colors.grey
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  img: {
    margin: "auto"
  },
};

export default styles;
