import { Map, List, fromJS } from "immutable";
import memoize from "fast-memoize";

const INIT_STATE = Map({
  isFetching: false,
  isSubmitting: false,
  items: List([])
});

const types = {
  fetchItems: "FETCH_ITEMS",
  fetchItemsSuccess: "FETCH_ITEMS_SUCCESS",
  fetchItemsFailure: "FETCH_ITEMS_FAILURE",
  modifyItems: "MODIFY_ITEMS",
  modifyItemsSuccess: "MODIFY_ITEMS_SUCCESS",
  modifyItemsFailure: "MODIFY_ITEMS_FAILURE",
  validateItemsCsv: "VALIDATE_ITEMS_CSV",
  validateItemsCsvSuccess: "VALIDATE_ITEMS_CSV_SUCCESS",
  validateItemsCsvFailure: "VALIDATE_ITEMS_CSV_FAILURE"
};

/* ********* Actions ********* */

const actions = {
  fetchItems() {
    return {
      type: types.fetchItems
    };
  },
  fetchItemsSuccess(data) {
    return {
      type: types.fetchItemsSuccess,
      data
    };
  },
  fetchItemsFailure(data) {
    return {
      type: types.fetchItemsFailure,
      data
    };
  },
  modifyItems(data) {
    return {
      type: types.modifyItems,
      data
    };
  },
  modifyItemsSuccess() {
    return {
      type: types.modifyItemsSuccess
    };
  },
  modifyItemsFailure() {
    return {
      type: types.modifyItemsFailure
    };
  },
  validateItemsCsv(file) {
    return {
      type: types.validateItemsCsv,
      file
    };
  },
  validateItemsCsvSuccess() {
    return {
      type: types.validateItemsCsvSuccess
    };
  },
  validateItemsCsvFailure() {
    return {
      type: types.validateItemsCsvFailure
    };
  }
};

/* ********* Reducer ********* */

// eslint-disable-next-line default-param-last
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case types.fetchItems:
    return state.merge({ isFetching: true });
  case types.fetchItemsSuccess:
    return state.merge({ isFetching: false, items: fromJS(action.data) });
  case types.fetchItemsFailure:
    return state.merge({ isFetching: false });
  case types.modifyItems:
    return state.merge({ isSubmitting: true });
  case types.modifyItemsSuccess:
    return state.merge({ isSubmitting: false });
  case types.modifyItemsFailure:
    return state.merge({ isSubmitting: false });
  case types.validateItemsCsv:
    return state.merge({ isSubmitting: true });
  case types.validateItemsCsvSuccess:
    return state.merge({ isSubmitting: false });
  case types.validateItemsCsvFailure:
    return state.merge({ isSubmitting: false });
  default:
    return state;
  }
};

/* ********* Selectors ********* */

const items = state => state.get("items");

const selectors = {
  getItems: memoize(state => items(state).get("items").toJS())
};

export {
  types as ItemsTypes,
  reducer as ItemsReducer,
  selectors as ItemsSelectors
};
export default actions;
