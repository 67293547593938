/* eslint-disable class-methods-use-this */
import React, { Component } from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import {
  Switch,
  Route,
  Redirect,
  Router
} from "react-router-dom";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import StartupActions from "client/state/redux/startup_redux";
import WaitForStartup from "client/containers/wait_for_startup";
import AppMessage from "client/containers/app_message";
import AuthOnly from "client/containers/auth_only";
import history from "client/services/history";
import store from "./state/create_store";
import "./styles.css";
import pages from "./pages";

const {
  MainPage,
  LoginPage,
  SignUpPage,
  SettingsPage,
  ReleasePage,
  FeedbackPage,
  BlockPage,
  CreateExpoPage,
  CreateGroupPage,
  DisbursementsPage,
  SellerDisbursementPage,
  GroupsGmvPage,
  GroupSetsPage,
  GroupFeesPage,
  UsersPage,
  ItemImportPage,
  GroupsPage,
  FeaturesIntegerPage,
  PrivateOffersPage
} = pages;

class App extends Component {
  async componentDidMount() {
    store.dispatch(StartupActions.startup());
  }

  renderMainPage = () => (
    <AuthOnly>
      <MainPage />
    </AuthOnly>
  );

  renderSignUpPage = () => (
    <AuthOnly>
      <SignUpPage />
    </AuthOnly>
  );

  renderSettingsPage = () => (
    <AuthOnly>
      <SettingsPage />
    </AuthOnly>
  );

  renderReleasePage = () => (
    <AuthOnly>
      <ReleasePage />
    </AuthOnly>
  );

  renderFeedbackPage = () => (
    <AuthOnly>
      <FeedbackPage />
    </AuthOnly>
  );

  renderBlockPage = () => (
    <AuthOnly>
      <BlockPage />
    </AuthOnly>
  );

  renderCreateExpoPage = () => (
    <AuthOnly>
      <CreateExpoPage />
    </AuthOnly>
  );

  renderCreateGroupPage = () => (
    <AuthOnly>
      <CreateGroupPage />
    </AuthOnly>
  );

  renderDisbursementsPage = () => (
    <AuthOnly>
      <DisbursementsPage />
    </AuthOnly>
  );

  renderSellerDisbursementPage = () => (
    <AuthOnly>
      <SellerDisbursementPage />
    </AuthOnly>
  );

  renderGroupsGmvPage = () => (
    <AuthOnly>
      <GroupsGmvPage />
    </AuthOnly>
  );

  renderGroupSetsPage = () => (
    <AuthOnly>
      <GroupSetsPage />
    </AuthOnly>
  );

  renderGroupFeesPage = () => (
    <AuthOnly>
      <GroupFeesPage />
    </AuthOnly>
  );

  renderUsersPage = () => (
    <AuthOnly>
      <UsersPage />
    </AuthOnly>
  );

  renderItemImportPage = () => (
    <AuthOnly>
      <ItemImportPage />
    </AuthOnly>
  );

  renderGroupsPage = () => (
    <AuthOnly>
      <GroupsPage />
    </AuthOnly>
  );

  renderFeaturesIntegerPage = () => (
    <AuthOnly>
      <FeaturesIntegerPage />
    </AuthOnly>
  );

  renderPrivateOffersPage = () => (
    <AuthOnly>
      <PrivateOffersPage />
    </AuthOnly>
  );

  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <AppMessage />
          <WaitForStartup>
            <Switch>
              <Route exact path="/" component={this.renderMainPage} />
              <Route path="/login" component={LoginPage} />
              <Route path="/signup" component={this.renderSignUpPage} />
              <Route path="/settings" component={this.renderSettingsPage} />
              <Route path="/release" component={this.renderReleasePage} />
              <Route path="/feedback" component={this.renderFeedbackPage} />
              <Route path="/block" component={this.renderBlockPage} />
              <Route path="/create-group" component={this.renderCreateGroupPage} />
              <Route path="/create-expo" component={this.renderCreateExpoPage} />
              <Route path="/users" component={this.renderUsersPage} />
              <Route path="/disbursements" component={this.renderDisbursementsPage} />
              <Route path="/seller-disbursements/:user_id" component={this.renderSellerDisbursementPage} />
              <Route path="/groups" component={this.renderGroupsPage} />
              <Route path="/groups-gmv" component={this.renderGroupsGmvPage} />
              <Route path="/group-sets" component={this.renderGroupSetsPage} />
              <Route path="/group-fees" component={this.renderGroupFeesPage} />
              <Route path="/item-import" component={this.renderItemImportPage} />
              <Route path="/features-int" component={this.renderFeaturesIntegerPage} />
              <Route path="/private-offers" component={this.renderPrivateOffersPage} />
              <Redirect to="/" />
            </Switch>
          </WaitForStartup>
        </Router>
      </Provider>
    );
  }
}

render(<App />, document.getElementById("root"));
