import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import { isEmpty } from "@auxxit/auxxit-common/util";
import { EXCEPTION_TYPE } from "@auxxit/auxxit-db/constants";
import Page from "client/components/page";
import Flex from "client/components/flex";
import AppStateActions from "client/state/redux/app_state_redux";
import DisbursementsActions, { DisbursementsSelectors } from "client/state/redux/disbursements_redux";
import * as FlatColorIcons from "react-icons/fc";
import { Colors } from "client/themes";
import { DISBURSEMENT_ACTION } from "client/constants";
import { moneyFormat, dateFormat } from "client/util";

import styles, { iconStyleProps } from "./styles";

const VIEWING_OPTIONS = {
  UNDISBURSED: "Undisbursed",
  DISBURSED: "Disbursed",
  EXCEPTIONS: "Exceptions"
};
const ACTION_OPTIONS = DISBURSEMENT_ACTION;

const DISBURSED_ACTION_OPTIONS = { ...ACTION_OPTIONS };
delete DISBURSED_ACTION_OPTIONS.DISBURSE;

class SellerDisbursementPage extends PureComponent {
  static propTypes = {
    disbursements: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    fetchMoreSellerDisbursements: PropTypes.func.isRequired,
    fetchSellerDisbursementData: PropTypes.func.isRequired,
    fetchSellerExceptions: PropTypes.func.isRequired,
    makeException: PropTypes.func.isRequired,
    markItemsAsDisbursed: PropTypes.func.isRequired,
    match: PropTypes.shape().isRequired,
    sellerDetails: PropTypes.shape().isRequired,
    sellerOverallData: PropTypes.shape().isRequired,
    setAppMessage: PropTypes.func.isRequired,
    username: PropTypes.string,
  };

  static defaultProps = {
    username: "",
  };

  static renderOptions = options => (
    Object.values(options).map(option => (
      <option
        key={option}
        style={styles.selectOption}
        value={option}
      >
        {option}
      </option>
    ))
  );

  state = {
    checkedItems: {},
    selectedDropdownOption: VIEWING_OPTIONS.UNDISBURSED,
    selectedActionOption: ACTION_OPTIONS.DISBURSE,
    searchQuery: ""
  };

  componentDidMount() {
    const { fetchSellerDisbursementData } = this.props;
    const { match } = this.props;
    const { params } = match;
    const { user_id: userId } = params;
    const isDisbursed = 0;
    fetchSellerDisbursementData(userId, isDisbursed);
  }

  handleDropdownChange = (e) => {
    const { fetchSellerDisbursementData, fetchSellerExceptions, match } = this.props;
    const { params } = match;
    const { user_id: userId } = params;
    const { value } = e.target;
    this.setState({ selectedDropdownOption: value });
    if (value === VIEWING_OPTIONS.EXCEPTIONS) {
      fetchSellerExceptions(userId);
    } else {
      const isDisbursed = value === VIEWING_OPTIONS.DISBURSED ? 1 : 0;
      const defaultAction = isDisbursed ? ACTION_OPTIONS.REFUND : ACTION_OPTIONS.DISBURSE;
      this.setState({ selectedActionOption: defaultAction });
      fetchSellerDisbursementData(userId, isDisbursed);
    }
  };

  handleActionChange = (e) => {
    const { value } = e.target;
    this.setState({ selectedActionOption: value });
  };

  handleCheckBox = (e) => {
    const { value } = e.target;
    const object = JSON.parse(value);
    this.setState((prevState) => {
      const checkedItems = { ...prevState.checkedItems };
      if (checkedItems[object.id]) {
        delete checkedItems[object.id];
      } else {
        checkedItems[object.id] = object.amount;
      }
      return { checkedItems };
    });
  };

  handleCheckAllBoxes = () => {
    const { disbursements } = this.props;
    const { selectedDropdownOption } = this.state;
    const newCheckedItems = {};
    disbursements.forEach((d) => {
      d.purchases.forEach((p) => {
        if (
          selectedDropdownOption === VIEWING_OPTIONS.UNDISBURSED
          && !p.disbursementInfo.isDisbursed && isEmpty(p.exceptionInfo)
        ) {
          newCheckedItems[p.ledgerId] = p.netPayment;
        } else if (
          selectedDropdownOption === VIEWING_OPTIONS.DISBURSED
          && isEmpty(p.exceptionInfo)
        ) {
          newCheckedItems[p.ledgerId] = p.netPayment;
        }
      });
    });
    if (!this.areAllItemsChecked()) {
      this.setState({ checkedItems: newCheckedItems });
    } else {
      this.setState({ checkedItems: {} });
    }
  };

  areAllItemsChecked = () => {
    const { disbursements } = this.props;
    const { checkedItems, selectedDropdownOption } = this.state;
    let totalItemCount = 0;
    disbursements.forEach((d) => {
      d.purchases.forEach((p) => {
        if (
          selectedDropdownOption === VIEWING_OPTIONS.UNDISBURSED
          && !p.disbursementInfo.isDisbursed && isEmpty(p.exceptionInfo)
        ) {
          totalItemCount += 1;
        } else if (
          selectedDropdownOption === VIEWING_OPTIONS.DISBURSED
          && isEmpty(p.exceptionInfo)
        ) {
          totalItemCount += 1;
        }
      });
    });
    return (totalItemCount === Object.keys(checkedItems).length && totalItemCount > 0);
  };

  isViewingExceptions = () => this.state.selectedDropdownOption === VIEWING_OPTIONS.EXCEPTIONS;

  submitAction = () => {
    const { selectedActionOption, selectedDropdownOption } = this.state;
    const { markItemsAsDisbursed, makeException, match, setAppMessage } = this.props;
    const { params: { user_id: userId } } = match;
    const { checkedItems } = this.state;
    const ledgerIds = Object.keys(checkedItems);
    if (!ledgerIds.length) {
      setAppMessage({ message: "No items were marked" });
      return;
    }
    if (
      selectedDropdownOption === VIEWING_OPTIONS.UNDISBURSED
      && selectedActionOption === ACTION_OPTIONS.DISBURSE
    ) {
      markItemsAsDisbursed(ledgerIds, userId);
    } else if (
      selectedActionOption === ACTION_OPTIONS.REFUND
      || selectedActionOption === ACTION_OPTIONS.CANCEL
    ) {
      const isDisbursed = selectedDropdownOption === VIEWING_OPTIONS.DISBURSED;
      makeException(ledgerIds, userId, selectedActionOption, isDisbursed);
    } else {
      // eslint-disable-next-line no-alert
      window.alert("Not a valid option, please retry!");
    }
    this.setState({ checkedItems: {} });
  };

  // eslint-disable-next-line class-methods-use-this
  renderLabelValue = (label, value, color, fontSize) => (
    <div style={styles.labelValueRow}>
      <div style={{ ...styles.labelText, color, fontSize }}>{`${label}:`}</div>
      &nbsp;
      <div style={{ ...styles.mainText, color, fontSize }}>
        {value}
      </div>
    </div>
  );

  // eslint-disable-next-line class-methods-use-this
  renderShippingInfo = (shipping) => {
    if (shipping.carrier) {
      let estimatedDaysText = shipping.estimatedDays
        ? ` (est. ${shipping.estimatedDays} days)`
        : "";
      if (shipping.trackingNumber) {
        estimatedDaysText += ":";
      }
      return (
        <div style={{ ...styles.itemRowText, marginRight: 20 }}>
          <div>
            {`${shipping.carrier}${estimatedDaysText}`}
          </div>
          {shipping.trackingNumber
            ? (
              <a
                href={`https://www.google.com/search?q=${shipping.trackingNumber}`}
                target="_blank"
                rel="noreferrer"
              >
                {shipping.trackingNumber}
              </a>
            ) : null}
        </div>

      );
    }
    return (
      <div style={styles.itemRowText}>
        no shipping
      </div>
    );
  };

  renderPurchaseCheckbox = (p) => {
    const { selectedDropdownOption, checkedItems } = this.state;
    if (p.exceptionInfo && typeof p.exceptionInfo.type === "string") {
      return (
        <div data-tip={p.exceptionInfo.type} data-for={`${p.ledgerId}-exception-tooltip`}>
          {React.createElement(FlatColorIcons.FcAnswers, { ...iconStyleProps })}
          <ReactTooltip id={`${p.ledgerId}-exception-tooltip`} className="medium-tooltip" place="right" />
        </div>
      );
    }
    if (
      selectedDropdownOption === VIEWING_OPTIONS.DISBURSED
      || !p.disbursementInfo.isDisbursed
    ) {
      return (
        <div>
          <input
            type="checkbox"
            style={styles.checkbox}
            checked={!!checkedItems[p.ledgerId]}
            value={JSON.stringify(
              { id: p.ledgerId, amount: p.netPayment }
            )}
            onChange={this.handleCheckBox}
          />
        </div>
      );
    }
    return React.createElement(FlatColorIcons.FcApproval, { ...iconStyleProps });
  };

  getDropdownColor = () => {
    const { selectedDropdownOption } = this.state;
    if (selectedDropdownOption === VIEWING_OPTIONS.UNDISBURSED) {
      return Colors.yellow;
    }
    if (selectedDropdownOption === VIEWING_OPTIONS.DISBURSED) {
      return Colors.emeraldGreen;
    }
    if (selectedDropdownOption === VIEWING_OPTIONS.EXCEPTIONS) {
      return Colors.red;
    }
  };

  renderSellerInfo = () => {
    const { sellerDetails } = this.props;
    const { createDate, username, name = "n/a", email, avatar } = sellerDetails;

    const baseImageUrl = "https://i.auxxit.com/";
    return (
      <Flex style={styles.userInfo} flexDirection="column">
        <Flex alignItems="center" style={styles.avatarWrapper}>
          <img key={avatar} style={styles.avatar} src={baseImageUrl + avatar} alt="screenshot" />
          <Flex flexDirection="column" alignItems="center" flex={1} style={{ margin: 5 }}>
            <div style={styles.usernameText}>{username}</div>
            <hr />
            <a href={`mailto:${email}`} style={styles.linkText}>{email}</a>
          </Flex>
        </Flex>
        <div style={{ ...styles.mainText, fontSize: 18 }}>{name}</div>
        <div style={{ ...styles.mainText, fontSize: 14 }}>{`Joined on ${dateFormat(createDate)}`}</div>
      </Flex>
    );
  };

  renderContactInfo = () => {
    const { sellerDetails } = this.props;
    const { address = {}, phone = "n/a" } = sellerDetails;
    const { addressLine1 = "n/a", addressLine2 = "", city = "n/a", state = "", zip = "", country = "" } = address;
    return (
      <div style={styles.contactInfo}>
        <div style={styles.addressRow}>
          <div style={styles.addressColumn1}>
            <div style={styles.labelText}>
              Address:
            </div>
          </div>
          <div style={styles.addressColumn2}>
            <div style={styles.mainText}>
              {`${addressLine1}`}
            </div>
            <div style={styles.mainText}>
              {addressLine2}
            </div>
            <div style={styles.mainText}>
              {`${city} ${state} ${zip} ${country}`}
            </div>
          </div>
        </div>
        {this.renderLabelValue("Phone", phone)}
      </div>
    );
  };

  renderPaymentInfo = () => {
    const { sellerDetails: { disbursement = {} } } = this.props;
    const { account, entity, bankAccountNumber, routingNumber, fullName } = disbursement;
    let eWalletInfo;
    if (entity === "Direct Deposit") {
      eWalletInfo = (
        <div>
          <div style={styles.mainText}>{`${entity} - ${fullName}`}</div>
          <div style={styles.mainText}>{`Routing #${routingNumber}`}</div>
          <div style={styles.mainText}>{`Bank account #${bankAccountNumber}`}</div>
        </div>
      );
    } else if (entity && account) {
      eWalletInfo = `${entity} - ${account}`;
    } else {
      eWalletInfo = "none";
    }
    return (
      <div style={styles.paymentInfo}>
        <div style={styles.addressColumn1}>
          <div style={styles.labelText}>
            E-Wallet:
          </div>
        </div>
        <div style={styles.addressColumn2}>
          <div style={styles.mainText}>
            {eWalletInfo}
          </div>
        </div>
      </div>
    );
  };

  renderTotals = () => {
    const { sellerOverallData } = this.props;
    const {
      totalPendingAmount,
      totalDisbursedPurchasesCount,
      totalPendingPurchasesCount
    } = sellerOverallData;
    return (
      <Flex style={styles.figureWrapper} flex={1}>
        <ul className="disbursement-totals" style={{ width: "100%" }}>
          <li>
            <span>Total Due</span>
            <span>{moneyFormat(totalPendingAmount)}</span>
          </li>
          <li>
            <span>Total Pending Purchases</span>
            <span>{totalPendingPurchasesCount}</span>
          </li>
          <li>
            <span>Total Disbursed Purchases</span>
            <span>{totalDisbursedPurchasesCount}</span>
          </li>
        </ul>
      </Flex>
    );
  };

  renderHistoric = () => {
    const { sellerOverallData } = this.props;
    const {
      totalDisbursedAmount,
      totalFeesGenerated,
      transactionFeesGenerated,
      groupModeratorFeesGenerated,
      processingFeesGenerated
    } = sellerOverallData;
    return (
      <Flex style={styles.figureWrapper} flex={1}>
        <ul className="disbursement-totals" style={{ width: "100%" }}>
          <li>
            <span>Historic Net Sales</span>
            <span>{moneyFormat(totalDisbursedAmount)}</span>
          </li>
          <li>
            <span>Historic Fees</span>
            <span>{moneyFormat(totalFeesGenerated)}</span>
          </li>
          <li>
            <span>- Transaction</span>
            <span>{moneyFormat(transactionFeesGenerated)}</span>
          </li>
          <li>
            <span>- Group Moderator</span>
            <span>{moneyFormat(groupModeratorFeesGenerated)}</span>
          </li>
          <li>
            <span>- Processing</span>
            <span>{moneyFormat(processingFeesGenerated)}</span>
          </li>
          <li>
            <span>Historic GMV</span>
            <span>{moneyFormat(totalDisbursedAmount + totalFeesGenerated)}</span>
          </li>
        </ul>
      </Flex>
    );
  };

  // eslint-disable-next-line class-methods-use-this
  renderStatusInfo = (purchase) => {
    let text = "";
    if (!isEmpty(purchase.exceptionInfo)) {
      const { exceptionInfo } = purchase;
      if (exceptionInfo.type === EXCEPTION_TYPE.REFUNDED) {
        text = `Refunded on ${dateFormat(exceptionInfo.date)}`;
      } else if (exceptionInfo.type === EXCEPTION_TYPE.CANCELLED_BY_ADMIN) {
        text = `Canceled on ${dateFormat(exceptionInfo.date)}`;
      }
    } else if (!isEmpty(purchase.disbursementInfo) && purchase.disbursementInfo.isDisbursed) {
      text = `Disbursed on ${dateFormat(purchase.disbursementInfo.date)}`;
    }
    return <div style={{ ...styles.mainText, fontSize: 14 }}>{text}</div>;
  };

  // eslint-disable-next-line class-methods-use-this
  getFeedbackText = (feedback) => {
    if (!feedback) {
      return "None";
    }
    return feedback.rating ? "Positive" : "Negative";
  };

  clearSearchQuery = () => this.setState({ searchQuery: "" });

  onChangeSearchQuery = (e) => {
    const newSearchQuery = e.target.value;
    this.setState({ searchQuery: newSearchQuery });
  };

  handleEndReached = () => {
    const { fetchMoreSellerDisbursements, disbursements, match } = this.props;
    const { selectedDropdownOption } = this.state;
    const { params } = match;
    const { user_id: userId } = params;
    const isDisbursed = selectedDropdownOption === VIEWING_OPTIONS.DISBURSED ? 1 : 0;
    if (disbursements.length) {
      fetchMoreSellerDisbursements(userId, isDisbursed);
    }
  };

  handleScroll = (e) => {
    const isEndReached = (
      (e.target.scrollHeight - Math.round(e.target.scrollTop) === e.target.clientHeight)
      || (e.target.scrollHeight - Math.floor(e.target.scrollTop) === e.target.clientHeight));
    if (isEndReached && !this.isViewingExceptions()) {
      this.handleEndReached();
    }
  };

  getDisbursements = () => {
    const { searchQuery } = this.state;
    const { disbursements } = this.props;
    if (searchQuery) {
      const query = searchQuery.replace(/[^A-Z0-9]/ig, "_").toLowerCase();
      return disbursements.filter((d) => {
        const matchingPurchases = d.purchases.filter((p) => {
          const nameStr = p.item.name.replace(/[^A-Z0-9]/ig, "_").toLowerCase();
          return nameStr.includes(query);
        });
        return matchingPurchases.length > 0;
      });
    }
    return disbursements;
  };

  render() {
    const { sellerDetails } = this.props;
    const { checkedItems, selectedDropdownOption, selectedActionOption, searchQuery } = this.state;

    const inputColor = this.getDropdownColor();
    const areAllItemsChecked = this.areAllItemsChecked();

    let totalAmountToDisburse = 0;
    Object.keys(checkedItems).forEach((key) => {
      totalAmountToDisburse += checkedItems[key];
    });

    const disbursements = this.getDisbursements();

    if (!sellerDetails) return null;
    return (
      <Page
        title="Seller Disbursement"
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          style={styles.container}
        >
          <div style={styles.interface}>
            <div style={styles.content} onScroll={this.handleScroll}>
              <div style={{ ...styles.row, ...{ justifyContent: "flex-start" } }}>
                <div style={styles.row}>
                  <Flex style={{ marginBottom: 10 }}>
                    <select
                      style={{ ...styles.selectInput, ...{ color: inputColor } }}
                      value={selectedDropdownOption}
                      onChange={this.handleDropdownChange}
                    >
                      {SellerDisbursementPage.renderOptions(VIEWING_OPTIONS)}
                    </select>
                  </Flex>
                </div>
              </div>
              <div style={styles.infoRow}>
                <Flex>
                  {this.renderSellerInfo()}
                  <Flex flexDirection="column" style={{ marginLeft: 2 }}>
                    {this.renderContactInfo()}
                    {this.renderPaymentInfo()}
                  </Flex>
                </Flex>
                {this.renderTotals()}
                {this.renderHistoric()}
              </div>
              <hr />
              <div style={styles.column}>
                {!this.isViewingExceptions()
                  ? (
                    <div style={styles.toolboxRow}>
                      <div style={{ ...styles.row, alignItems: "center" }}>
                        <input
                          type="checkbox"
                          style={styles.checkbox}
                          checked={areAllItemsChecked}
                          onChange={this.handleCheckAllBoxes}
                        />
                        <div style={styles.mainText}>
                          Check All
                        </div>
                      </div>
                      <div style={{ ...styles.row, alignItems: "center" }}>
                        <Flex flex={1} style={styles.inputContainer}>
                          <input
                            type="text"
                            style={styles.input}
                            value={searchQuery}
                            onChange={this.onChangeSearchQuery}
                            placeholder="Search item name..."
                          />
                          <button type="button" onClick={this.clearSearchQuery}>Clear</button>
                        </Flex>
                      </div>
                      <div style={{ ...styles.row, alignItems: "center" }}>
                        <div style={styles.markDisbursedText}>
                          <div style={styles.redText}>
                            {`${Object.keys(checkedItems).length} items`}
                          </div>
                          <div style={styles.redText}>
                            {moneyFormat(totalAmountToDisburse)}
                          </div>
                        </div>
                        <div style={styles.actionDropdown}>
                          <select
                            style={styles.selectInput}
                            value={selectedActionOption}
                            onChange={this.handleActionChange}
                          >
                            {SellerDisbursementPage.renderOptions(
                              selectedDropdownOption === VIEWING_OPTIONS.UNDISBURSED
                                ? ACTION_OPTIONS
                                : DISBURSED_ACTION_OPTIONS
                            )}
                          </select>
                        </div>
                        <button style={styles.disbursedButton} type="submit" onClick={this.submitAction}>
                          <div style={styles.buttonText}>
                            Submit
                          </div>
                        </button>
                      </div>
                    </div>
                  ) : null}
                {disbursements.map((d) => {
                  let netSales = 0;
                  let fees = 0;
                  d.purchases.forEach((purchase) => {
                    netSales += purchase.netPayment;
                  });
                  d.purchases.forEach((purchase) => {
                    fees += purchase.transactionFee.total
                      + purchase.groupModeratorFee.total + purchase.processingFee.total;
                  });
                  let buyerName = "";
                  if (d.buyer.firstName && d.buyer.lastName) {
                    buyerName = `${d.buyer.firstName} ${d.buyer.lastName}, `;
                  }
                  return (
                    <div style={styles.paymentContainer} key={d.id}>
                      <div style={styles.column}>
                        <div style={styles.paymentHeader}>
                          <div style={styles.column}>
                            {this.renderLabelValue("Payment ID", d.paymentId)}
                            {this.renderLabelValue("BlueSnap ID", d.entityTransactionId || "Unknown")}
                            <div style={styles.column}>
                              <div style={styles.itemRowText}>
                                {this.renderLabelValue(
                                  "Buyer",
                                  `${d.buyer.username}, ${buyerName}${d.buyer.city} ${d.buyer.state} ${d.buyer.deliveryZip}`
                                )}
                              </div>
                            </div>
                          </div>
                          <div style={styles.row}>
                            <div style={styles.salesValue}>
                              {this.renderLabelValue("Net Sales", moneyFormat(netSales), Colors.white)}
                            </div>
                            <div style={styles.feesValue}>
                              {this.renderLabelValue("Fees", moneyFormat(fees))}
                            </div>
                            <div style={styles.gmvValue}>
                              {this.renderLabelValue("GMV", moneyFormat(netSales + fees), Colors.white)}
                            </div>
                          </div>
                        </div>
                        <div style={styles.purchasesContainer}>
                          {d.purchases.map((p) => {
                            const itemName = p.item.name;
                            const { shipping } = p;
                            const pFees = p.transactionFee.total + p.groupModeratorFee.total
                              + p.processingFee.total;
                            let eWalletText = "";
                            if (p.disbursementInfo && p.disbursementInfo.isDisbursed) {
                              const { eWallet } = p.disbursementInfo;
                              if (eWallet.entity === "Direct Deposit") {
                                eWalletText = `${eWallet.entity} - ${eWallet.fullName}<br />
                                  Routing #${eWallet.routingNumber}<br />
                                  Bank account #${eWallet.bankAccountNumber}`;
                              } else {
                                eWalletText = `${eWallet.entity} - ${eWallet.account}`;
                              }
                            }
                            return (
                              <div style={styles.itemRow} key={p.ledgerId}>
                                {this.renderPurchaseCheckbox(p)}
                                <div style={styles.itemRowText}>
                                  {itemName}
                                </div>
                                <div style={{ ...styles.row, flex: 1, alignItems: "center" }}>
                                  <div style={{ ...styles.salesValue, color: Colors.white, fontWeight: "bold" }}>
                                    {moneyFormat(p.netPayment)}
                                  </div>
                                  <div style={styles.feesValue}>
                                    {moneyFormat(pFees)}
                                  </div>
                                  <div style={{ ...styles.gmvValue, color: Colors.white }}>
                                    {moneyFormat(p.netPayment + pFees)}
                                  </div>
                                </div>
                                {this.renderShippingInfo(shipping)}
                                <div style={styles.itemRowText}>
                                  {p.feedback
                                    ? (
                                      <div>
                                        <div
                                          style={{ cursor: p.feedback.comment ? "pointer" : "auto" }}
                                          data-tip={p.feedback.comment}
                                          data-for={`${p.ledgerId}-feedback-tooltip`}
                                        >
                                          {this.renderLabelValue("Feedback", this.getFeedbackText(p.feedback))}
                                        </div>
                                        <ReactTooltip id={`${p.ledgerId}-feedback-tooltip`} className="medium-tooltip" place="left" />
                                      </div>
                                    ) : this.renderLabelValue("Feedback", "None")}
                                </div>
                                <div
                                  style={{ flex: 0.5, cursor: eWalletText ? "pointer" : "auto" }}
                                  data-tip={eWalletText}
                                  data-for={`${p.ledgerId}-ewallet-tooltip`}
                                >
                                  {this.renderStatusInfo(p)}
                                  <ReactTooltip multiline id={`${p.ledgerId}-ewallet-tooltip`} className="medium-tooltip" place="left" />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div style={styles.footer}>
                        <div style={{ ...styles.mainText, fontSize: 14 }}>{`Paid on ${dateFormat(d.paymentDate)}`}</div>
                        {d.disbursementInfo.isDisbursed
                          ? (
                            <div style={{ ...styles.mainText, fontSize: 14 }}>
                              {`Disbursed on ${dateFormat(d.disbursementInfo.date)}`}
                            </div>
                          ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Flex>
      </Page>
    );
  }
}

const mapStateToProps = (state) => {
  const sellerOverallData = DisbursementsSelectors.getSellerOverallData(state);
  const userId = sellerOverallData.id;
  return {
    disbursements: DisbursementsSelectors.getDisbursements(state),
    username: DisbursementsSelectors.getUsernameFromId(state, userId),
    sellerDetails: DisbursementsSelectors.getSellerDetails(state),
    sellerOverallData,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchSellerDisbursementData: (sellerId, isDisbursed) =>
    dispatch(DisbursementsActions.fetchSellerDisbursementData(sellerId, isDisbursed)),
  fetchMoreSellerDisbursements: (sellerId, isDisbursed) =>
    dispatch(DisbursementsActions.fetchMoreSellerDisbursements(sellerId, isDisbursed)),
  fetchSellerExceptions: sellerId =>
    dispatch(DisbursementsActions.fetchSellerExceptions(sellerId)),
  markItemsAsDisbursed: (ledgerIds, userId) =>
    dispatch(DisbursementsActions.markItemsAsDisbursed(ledgerIds, userId)),
  makeException: (ledgerIds, userId, exception, isDisbursed) =>
    dispatch(DisbursementsActions.makeException(ledgerIds, userId, exception, isDisbursed)),
  setAppMessage: message =>
    dispatch(AppStateActions.setAppMessage(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SellerDisbursementPage));
