import { Map, List, fromJS } from "immutable";
import memoize from "fast-memoize";

const INIT_STATE = Map({
  isFetching: false,
  isSubmitting: false,
  auctions: List([])
});

const types = {
  fetchAuctions: "FETCH_AUCTIONS",
  fetchAuctionsSuccess: "FETCH_AUCTIONS_SUCCESS",
  fetchAuctionsFailure: "FETCH_AUCTIONS_FAILURE",
  modifyAuctions: "MODIFY_AUCTIONS",
  modifyAuctionsSuccess: "MODIFY_AUCTIONS_SUCCESS",
  modifyAuctionsFailure: "MODIFY_AUCTIONS_FAILURE"
};

/* ********* Actions ********* */

const actions = {
  fetchAuctions() {
    return {
      type: types.fetchAuctions
    };
  },
  fetchAuctionsSuccess(data) {
    return {
      type: types.fetchAuctionsSuccess,
      data
    };
  },
  fetchAuctionsFailure(data) {
    return {
      type: types.fetchAuctionsFailure,
      data
    };
  },
  modifyAuctions(data) {
    return {
      type: types.modifyAuctions,
      data
    };
  },
  modifyAuctionsSuccess() {
    return {
      type: types.modifyAuctionsSuccess
    };
  },
  modifyAuctionsFailure() {
    return {
      type: types.modifyAuctionsFailure
    };
  }
};

/* ********* Reducer ********* */

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case types.fetchAuctions:
    return state.merge({ isFetching: true });
  case types.fetchAuctionsSuccess:
    return state.merge({ isFetching: false, auctions: fromJS(action.data) });
  case types.fetchAuctionsFailure:
    return state.merge({ isFetching: false });
  case types.modifyAuctions:
    return state.merge({ isSubmitting: true });
  case types.modifyAuctionsSuccess:
    return state.merge({ isSubmitting: false });
  case types.modifyAuctionsFailure:
    return state.merge({ isSubmitting: false });
  default:
    return state;
  }
};

/* ********* Selectors ********* */

const auctions = state => state.get("auctions");

const selectors = {
  getAuctions: memoize(state => auctions(state).get("auctions").toJS())
};

export {
  types as AuctionsTypes,
  reducer as AuctionsReducer,
  selectors as AuctionsSelectors
};
export default actions;
