import { call, put, takeLatest } from "redux-saga/effects";
import api from "client/services/api";
import ModelHelper from "./helpers/feedback_model";
import FeedbackActions, { FeedbackTypes as types } from "../redux/feedback_redux";

function* fetchFeedback() {
  try {
    const response = yield call(api.fetchFeedback);
    const feedback = ModelHelper.sanitizeFeedbackList(response.data);
    yield put(FeedbackActions.fetchFeedbackSuccess(feedback));
  } catch (e) {
    yield put(FeedbackActions.fetchFeedbackFailure());
  }
}

function* modifyFeedback(action) {
  try {
    yield call(api.modifyFeedback, action.data);
    yield put(FeedbackActions.modifyFeedbackSuccess());
  } catch (e) {
    yield put(FeedbackActions.modifyFeedbackFailure());
  }
}

export default function feedbackSagas() {
  return [
    takeLatest(types.fetchFeedback, fetchFeedback),
    takeLatest(types.modifyFeedback, modifyFeedback)
  ];
}
