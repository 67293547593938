import api from "./client";

function fetchUsers() {
  return api.get("/users");
}

function modifyUsers(params) {
  return api.put("/users", params);
}

function blockDevice(params) {
  return api.post("/users/devices/block", params);
}

function searchUsers(params) {
  return api.get("/users/search", { params });
}

function downloadUsersLast7Days() {
  return api.get("/users/download");
}

export default {
  fetchUsers,
  modifyUsers,
  blockDevice,
  searchUsers,
  downloadUsersLast7Days
};
