import api from "./client";

function getUser(userId) {
  return api.get(`/user/${userId}`);
}

function changePassword(userId, params) {
  return api.put(`/user/${userId}/password`, params);
}

function modifyUserEnvironment(userId, params) {
  return api.put(`/user/${userId}/environment`, params);
}

export default {
  getUser,
  changePassword,
  modifyUserEnvironment
};
