import { put, takeLatest, select } from "redux-saga/effects";
import api from "client/services/api";
import callAuthApi from "client/services/call_auth_api";
import ModelHelper from "./helpers/user_model";
import DisbursementsActions, { DisbursementsSelectors, DisbursementsTypes as types } from "../redux/disbursements_redux";
import AppStateActions from "../redux/app_state_redux";
import { DISBURSEMENT_ACTION } from "../../constants";

function getExceptionApiFunction(action) {
  if (action === DISBURSEMENT_ACTION.REFUND) {
    return api.refundPurchases;
  }
  if (action === DISBURSEMENT_ACTION.CANCEL) {
    return api.cancelPurchases;
  }
  throw new Error("Invalid exception type");
}

function getExceptionMessage(action) {
  if (action === DISBURSEMENT_ACTION.REFUND) {
    return "Exception successful: purchase(s) refunded";
  }
  if (action === DISBURSEMENT_ACTION.CANCEL) {
    return "Exception successful: purchase(s) canceled";
  }
  return "Exception successful for purchase(s)";
}

function* fetchSellersForDisbursement() {
  try {
    const cursor = yield select(DisbursementsSelectors.getSellersCursor);
    const response = yield callAuthApi(api.fetchSellersForDisbursement, {
      sortField: cursor.sortField,
      sortDirection: cursor.sortDirection
    });
    const { cursor: newCursor, data } = response.data;
    const sellers = ModelHelper.sanitizeSellers(data);
    yield put(DisbursementsActions.fetchSellersForDisbursementSuccess(sellers, newCursor));
  } catch (e) {
    yield put(DisbursementsActions.fetchSellersForDisbursementFailure());
  }
}

function* fetchMoreSellersForDisbursement() {
  try {
    const cursor = yield select(DisbursementsSelectors.getSellersCursor);
    const response = yield callAuthApi(api.fetchSellersForDisbursement, {
      cursorStage: cursor.stage,
      cursorLast: cursor.last,
      sortField: cursor.sortField,
      sortDirection: cursor.sortDirection
    });
    const { cursor: newCursor, data } = response.data;
    const sellers = ModelHelper.sanitizeSellers(data);
    yield put(DisbursementsActions.fetchMoreSellersForDisbursementSuccess(sellers, newCursor));
  } catch (e) {
    yield put(DisbursementsActions.fetchSellersForDisbursementFailure());
  }
}

function* fetchPendingDisbursementsData() {
  try {
    const response = yield callAuthApi(api.fetchPendingDisbursementsData);
    yield put(DisbursementsActions.fetchPendingDisbursementsDataSuccess(response.data));
  } catch (e) {
    yield put(DisbursementsActions.fetchPendingDisbursementsDataFailure());
  }
}

function* searchSellersForDisbursement({ query }) {
  try {
    const response = yield callAuthApi(api.searchSellersForDisbursement, { q: query });
    const searchResults = ModelHelper.sanitizeSellers(response.data);
    yield put(DisbursementsActions.searchSellersForDisbursementSuccess(searchResults));
  } catch (e) {
    yield put(DisbursementsActions.searchSellersForDisbursementFailure());
  }
}

function* fetchSellerDisbursementData({ userId, isDisbursed }) {
  try {
    let response = yield callAuthApi(api.fetchSellerDisbursementData, userId, { isDisbursed });
    if (response.status === 200) {
      const { data: disbursements, cursor } = response.data;
      const sanitizedDisbursements = ModelHelper.sanitizeDisbursements(disbursements);
      yield put(DisbursementsActions.fetchSellerDisbursementDataSuccess({
        disbursements: sanitizedDisbursements,
        cursor
      }));
    }
    response = yield callAuthApi(api.fetchSellerInfo, userId);
    if (response.status === 200) {
      const { overallData, sellerData: sellerDetails } = response.data;
      const sanitizedOverallData = ModelHelper.sanitizeSellerOverallData(overallData);
      yield put(DisbursementsActions.fetchSellerInfoSuccess({
        overallData: sanitizedOverallData,
        sellerDetails
      }));
    }
  } catch (e) {
    yield put(DisbursementsActions.fetchSellerDisbursementDataFailure());
  }
}

function* fetchMoreSellerDisbursements({ userId, isDisbursed }) {
  try {
    const cursor = yield select(DisbursementsSelectors.getDisbursementsCursor);
    if (!cursor) {
      yield put(DisbursementsActions.fetchMoreSellerDisbursementsFailure());
      return;
    }
    const response = yield callAuthApi(
      api.fetchSellerDisbursementData,
      userId,
      { isDisbursed, cursor }
    );
    if (response.status === 200) {
      const { data: disbursements, cursor: newCursor } = response.data;
      const sanitizedDisbursements = ModelHelper.sanitizeDisbursements(disbursements);
      yield put(DisbursementsActions.fetchMoreSellerDisbursementsSuccess({
        disbursements: sanitizedDisbursements,
        cursor: newCursor
      }));
    } else {
      yield put(DisbursementsActions.fetchMoreSellerDisbursementsFailure());
    }
  } catch (err) {
    yield put(DisbursementsActions.fetchMoreSellerDisbursementsFailure());
  }
}

function* fetchSellerExceptions({ userId }) {
  try {
    const response = yield callAuthApi(api.fetchSellerExceptions, userId);
    if (response.status === 200) {
      const disbursements = response.data;
      const sanitizedDisbursements = ModelHelper.sanitizeDisbursements(disbursements);
      yield put(DisbursementsActions.fetchSellerDisbursementDataSuccess({
        disbursements: sanitizedDisbursements
      }));
    }
  } catch (err) {
    yield put(DisbursementsActions.fetchSellerDisbursementDataFailure());
  }
}

function* markItemsAsDisbursed({ ledgerIds, userId }) {
  try {
    const response = yield callAuthApi(api.markItemsAsDisbursed, { ledgerIds });
    if (response.status === 200) {
      yield put(DisbursementsActions.markItemsAsDisbursedSuccess());
      yield put(DisbursementsActions.fetchSellerDisbursementData(userId, 0));
      yield put(AppStateActions.setAppMessage({ message: "Items successfully marked as disbursed" }));
    }
  } catch (e) {
    yield put(DisbursementsActions.markItemsAsDisbursedFailure());
  }
}

function* makeException({ ledgerIds, userId, exception, isDisbursed }) {
  try {
    const apiFunction = getExceptionApiFunction(exception);
    const response = yield callAuthApi(apiFunction, { ledgerIds });
    if (response.status === 200) {
      yield put(DisbursementsActions.makeExceptionSuccess());
      const disbursedBit = isDisbursed ? 1 : 0;
      yield put(DisbursementsActions.fetchSellerDisbursementData(userId, disbursedBit));
      yield put(AppStateActions.setAppMessage({ message: getExceptionMessage(exception) }));
    }
  } catch (e) {
    yield put(DisbursementsActions.makeExceptionFailure());
  }
}

function* downloadUserTransactionHistory({ userId }) {
  try {
    const response = yield callAuthApi(api.fetchUserTransactionHistory, userId);
    if (response.status === 200) {
      const url = window.URL.createObjectURL(new window.Blob([response.data], { type: "text/csv" }));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `transactions-${userId}.csv`);
      // Append to html page
      document.body.appendChild(link);
      // Force download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
      yield put(AppStateActions.setAppMessage({ message: "Successfully downloaded CSV of user transactions" }));
    } else {
      yield put(AppStateActions.setAppMessage({ message: "Failed to download CSV of user transactions" }));
    }
  } catch (e) {
    yield put(AppStateActions.setAppMessage({ message: "Failed to download CSV of user transactions" }));
  }
}

export default function disbursementsSagas() {
  return [
    takeLatest(types.fetchSellersForDisbursement, fetchSellersForDisbursement),
    takeLatest(types.fetchMoreSellersForDisbursement, fetchMoreSellersForDisbursement),
    takeLatest(types.fetchPendingDisbursementsData, fetchPendingDisbursementsData),
    takeLatest(types.searchSellersForDisbursement, searchSellersForDisbursement),
    takeLatest(types.fetchSellerDisbursementData, fetchSellerDisbursementData),
    takeLatest(types.fetchMoreSellerDisbursements, fetchMoreSellerDisbursements),
    takeLatest(types.markItemsAsDisbursed, markItemsAsDisbursed),
    takeLatest(types.makeException, makeException),
    takeLatest(types.fetchSellerExceptions, fetchSellerExceptions),
    takeLatest(types.downloadUserTransactionHistory, downloadUserTransactionHistory)
  ];
}
