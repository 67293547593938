import { Map, List, fromJS } from "immutable";
import memoize from "fast-memoize";

const INIT_STATE = Map({
  isFetching: false,
  isSubmitting: false,
  groupsMessage: "",
  groups: List([]),
  groupSets: List([]),
  groupFees: List([]),
  globalFees: List([])
});

const types = {
  setGroupsMessage: "SET_GROUPS_MESSAGE",
  fetchGroups: "FETCH_GROUPS",
  fetchGroupsSuccess: "FETCH_GROUPS_SUCCESS",
  fetchGroupsFailure: "FETCH_GROUPS_FAILURE",
  fetchGroupSets: "FETCH_GROUP_SETS",
  fetchGroupSetsSuccess: "FETCH_GROUP_SETS_SUCCESS",
  fetchGroupSetsFailure: "FETCH_GROUP_SETS_FAILURE",
  fetchGroupFees: "FETCH_GROUP_FEES",
  fetchGroupFeesSuccess: "FETCH_GROUP_FEES_SUCCESS",
  fetchGroupFeesFailure: "FETCH_GROUP_FEES_FAILURE",
  modifyGroups: "MODIFY_GROUPS",
  modifyGroupsSuccess: "MODIFY_GROUPS_SUCCESS",
  modifyGroupsFailure: "MODIFY_GROUPS_FAILURE",
  createGroup: "CREATE_GROUP",
  createGroupSuccess: "CREATE_GROUP_SUCCESS",
  createGroupFailure: "CREATE_GROUP_FAILURE",
  downloadFeeTotals: "DOWNLOAD_FEE_TOTALS",
  downloadMemberEmails: "DOWNLOAD_MEMBER_EMAILS"
};

/* ********* Actions ********* */

const actions = {
  setGroupsMessage(data) {
    return {
      type: types.setGroupsMessage,
      data
    };
  },
  createGroup(data) {
    return {
      type: types.createGroup,
      data
    };
  },
  createGroupSuccess(data) {
    return {
      type: types.createGroupSuccess,
      data
    };
  },
  createGroupFailure(data) {
    return {
      type: types.createGroupFailure,
      data
    };
  },
  fetchGroups() {
    return {
      type: types.fetchGroups
    };
  },
  fetchGroupsSuccess(data) {
    return {
      type: types.fetchGroupsSuccess,
      data
    };
  },
  fetchGroupsFailure(data) {
    return {
      type: types.fetchGroupsFailure,
      data
    };
  },
  fetchGroupSets() {
    return {
      type: types.fetchGroupSets
    };
  },
  fetchGroupSetsSuccess(data) {
    return {
      type: types.fetchGroupSetsSuccess,
      data
    };
  },
  fetchGroupSetsFailure(data) {
    return {
      type: types.fetchGroupSetsFailure,
      data
    };
  },
  fetchGroupFees() {
    return {
      type: types.fetchGroupFees
    };
  },
  fetchGroupFeesSuccess(data) {
    return {
      type: types.fetchGroupFeesSuccess,
      data
    };
  },
  fetchGroupFeesFailure(data) {
    return {
      type: types.fetchGroupFeesFailure,
      data
    };
  },
  modifyGroups(data) {
    return {
      type: types.modifyGroups,
      data
    };
  },
  modifyGroupsSuccess() {
    return {
      type: types.modifyGroupsSuccess
    };
  },
  modifyGroupsFailure() {
    return {
      type: types.modifyGroupsFailure
    };
  },
  downloadFeeTotals(groupId) {
    return {
      type: types.downloadFeeTotals,
      groupId
    };
  },
  downloadMemberEmails(groupId) {
    return {
      type: types.downloadMemberEmails,
      groupId
    };
  }
};

/* ********* Reducer ********* */

// eslint-disable-next-line default-param-last
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case types.setGroupsMessage:
    return state.merge({ groupsMessage: action.data });
  case types.createGroup:
    return state.merge({ isSubmitting: true });
  case types.createGroupSuccess:
    return state.merge({ isSubmitting: false, groupsMessage: JSON.stringify(action.data) });
  case types.createGroupFailure:
    return state.merge({ isSubmitting: false, groupsMessage: JSON.stringify(action.data) });
  case types.fetchGroups:
    return state.merge({ isFetching: true });
  case types.fetchGroupsSuccess:
    return state.merge({ isFetching: false, groups: fromJS(action.data) });
  case types.fetchGroupsFailure:
    return state.merge({ isFetching: false });
  case types.fetchGroupSets:
    return state.merge({ isFetching: true });
  case types.fetchGroupSetsSuccess:
    return state.merge({ isFetching: false, groupSets: fromJS(action.data) });
  case types.fetchGroupSetsFailure:
    return state.merge({ isFetching: false });
  case types.fetchGroupFees:
    return state.merge({ isFetching: true });
  case types.fetchGroupFeesSuccess:
    return state.merge({
      isFetching: false,
      groupFees: fromJS(action.data.groups),
      globalFees: fromJS(action.data.global)
    });
  case types.fetchGroupFeesFailure:
    return state.merge({ isFetching: false });
  case types.modifyGroups:
    return state.merge({ isSubmitting: true });
  case types.modifyGroupsSuccess:
    return state.merge({ isSubmitting: false });
  case types.modifyGroupsFailure:
    return state.merge({ isSubmitting: false });
  default:
    return state;
  }
};

/* ********* Selectors ********* */

const groups = state => state.get("groups");

const selectors = {
  getGroups: memoize(state => groups(state).get("groups").toJS()),
  getGroupSets: memoize(state => groups(state).get("groupSets").toJS()),
  getGroupFees: memoize(state => groups(state).get("groupFees").toJS()),
  getGlobalFees: memoize(state => groups(state).get("globalFees").toJS()),
  getGroupsMessage: state => groups(state).get("groupsMessage")
};

export {
  types as GroupsTypes,
  reducer as GroupsReducer,
  selectors as GroupsSelectors
};
export default actions;
