import { Colors } from "client/themes";

const styles = {
  tabInterface: {
  },
  tabBar: {
    marginBottom: 10,
    marginTop: 10
  },
  tabBody: {
  },
  tabButton: {
    flex: 1,
    fontSize: 18,
    fontWeight: "bold",
    backgroundColor: "transparent",
  },
  selectedButton: {
    color: Colors.auxxitBlue
  },
  unselectedButton: {
    color: Colors.lightGrey
  },
  selectedTab: {
    borderBottomColor: Colors.auxxitBlue,
    borderBottomWidth: 2,
    borderBottomStyle: "solid"
  },
  unselectedTab: {
    border: 0
  }
};

export default styles;
