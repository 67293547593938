import React, { Component } from "react";
import NavigationService from "client/services/navigation_service";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DisbursementsActions, { DisbursementsSelectors } from "client/state/redux/disbursements_redux";
import Page from "client/components/page";
import Flex from "client/components/flex";
import * as FlatColorIcons from "react-icons/fc";
import { Colors } from "client/themes";
import { moneyFormat, dateFormat } from "client/util";

import styles, { detailIconStyleProps, iconStyleProps, refreshIconStyleProps } from "./styles";

class DisbursementsPage extends Component {
  static propTypes = {
    clearSellerSearchResults: PropTypes.func.isRequired,
    fetchPendingDisbursementsData: PropTypes.func.isRequired,
    fetchSellersForDisbursement: PropTypes.func.isRequired,
    fetchSellersForDisbursementMore: PropTypes.func.isRequired,
    icon: PropTypes.string,
    pendingInfo: PropTypes.shape(),
    searchSellersForDisbursement: PropTypes.func.isRequired,
    sellers: PropTypes.arrayOf(PropTypes.shape()),
    sellerSearchResults: PropTypes.arrayOf(PropTypes.shape()),
    sellersScrollTop: PropTypes.number,
    setSellersScrollTop: PropTypes.func.isRequired
  };

  static defaultProps = {
    icon: "FcStatistics",
    sellers: [],
    sellerSearchResults: [],
    sellersScrollTop: 0,
    pendingInfo: {}
  };

  static lineItem = (label, text) => (
    <div style={styles.lineItemRow}>
      <div style={styles.lineItemRowLabel}>
        {label}
      </div>
      <div style={styles.lineItemRowText}>
        {text}
      </div>
    </div>
  );

  static stackedLineItem = (label, text, color) => (
    <div style={styles.lineItemColumn}>
      <div style={{ ...styles.lineItemColumnText, ...{ color } }}>
        {text}
      </div>
      <div style={styles.lineItemColumnLabel}>
        {label}
      </div>
    </div>
  );

  state = {
    searchQuery: ""
  };

  componentDidMount() {
    const {
      fetchSellersForDisbursement,
      sellers,
      sellersScrollTop,
      fetchPendingDisbursementsData
    } = this.props;
    this.sellerList.scrollTop = sellersScrollTop;
    fetchPendingDisbursementsData();
    // TODO: We should have better way of maintaining list length for scrollTo behavior
    // without disabling any sort of single-page refresh
    if (!sellers.length) {
      fetchSellersForDisbursement();
    }
  }

  handleEndReached = () => {
    const { fetchSellersForDisbursementMore, sellers } = this.props;
    if (sellers.length) {
      fetchSellersForDisbursementMore();
    }
  };

  handleScroll = (e) => {
    const isEndReached = (
      (e.target.scrollHeight - Math.round(e.target.scrollTop) === e.target.clientHeight)
      || (e.target.scrollHeight - Math.floor(e.target.scrollTop) === e.target.clientHeight));
    if (isEndReached) {
      this.handleEndReached();
    }
  };

  // eslint-disable-next-line class-methods-use-this
  onClick = sellerId => () => {
    const { setSellersScrollTop } = this.props;
    setSellersScrollTop(this.sellerList.scrollTop);
    NavigationService.toSellerDisbursementPage(sellerId);
  };

  renderSellerCard = sellerData => (
    <div key={sellerData.seller.username} role="button" tabIndex={0} style={styles.dataContainer} onClick={this.onClick(sellerData.seller.id)}>
      <div style={styles.userDetailsWrap}>
        <div style={styles.userIcon}>
          {React.createElement(FlatColorIcons.FcVoicePresentation, { ...detailIconStyleProps })}
          <div style={styles.iconText}>Seller</div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={styles.userDetailsRow}>
            {DisbursementsPage.lineItem("Username", sellerData.seller.username)}
            {DisbursementsPage.lineItem("E-Mail", sellerData.seller.email)}
            {DisbursementsPage.lineItem("Member Since", dateFormat(sellerData.seller.createDate))}
          </div>
        </div>
      </div>
      <div style={styles.salesIconWrapper}>
        {React.createElement(FlatColorIcons.FcSalesPerformance, { ...detailIconStyleProps })}
        <div style={{ ...styles.iconText }}>Sales</div>
      </div>
      <div style={styles.userDataWrap}>
        <div style={styles.detailsRows}>
          {DisbursementsPage.stackedLineItem(
            "Pending Disbursement",
            moneyFormat(sellerData.totalPendingAmount),
            sellerData.totalPendingAmount > 0 ? Colors.red : Colors.Black
          )}
          {DisbursementsPage.stackedLineItem("Fees Generated", moneyFormat(sellerData.totalFeesGenerated))}
          {DisbursementsPage.stackedLineItem("Total $ Disbursed", moneyFormat(sellerData.totalDisbursedAmount))}
          {DisbursementsPage.stackedLineItem("Total Pending Purchases", sellerData.totalPendingPurchasesCount)}
          {DisbursementsPage.stackedLineItem("Total Disbursed Purchases", sellerData.totalDisbursedPurchasesCount)}
        </div>
      </div>
    </div>
  );

  onChangeSearchQuery = (e) => {
    const { searchSellersForDisbursement } = this.props;
    const newSearchQuery = e.target.value;
    this.setState({ searchQuery: newSearchQuery });
    if (newSearchQuery.length >= 3) {
      searchSellersForDisbursement(newSearchQuery);
    }
  };

  clearSellerSearch = () => {
    const { clearSellerSearchResults } = this.props;
    this.setState({ searchQuery: "" });
    clearSellerSearchResults();
  };

  render() {
    const {
      icon,
      sellers,
      sellerSearchResults,
      fetchSellersForDisbursement,
      pendingInfo
    } = this.props;
    const { searchQuery } = this.state;
    const iconElement = React.createElement(FlatColorIcons[icon], { ...iconStyleProps });

    const sellerList = sellerSearchResults.length ? sellerSearchResults : sellers;
    return (
      <Page
        title="Disbursements"
      >
        <Flex style={styles.container}>
          <Flex style={styles.content} flexDirection="column">
            <div style={styles.widgetHeaderContainer}>
              <div style={styles.widgetHeader}>
                {iconElement}
                <div>Sellers</div>
              </div>
              <Flex flexDirection="column" style={styles.pendingTotals}>
                <div>{`Pending Amount: ${moneyFormat(pendingInfo.totalPendingAmount)}`}</div>
                <div>{`Pending Count: ${pendingInfo.totalPendingPurchasesCount}`}</div>
              </Flex>
            </div>
            <Flex flex={1} style={styles.searchContainer}>
              <div role="button" tabIndex={0} style={styles.refreshButton} onClick={fetchSellersForDisbursement}>
                {React.createElement(FlatColorIcons.FcRefresh, { ...refreshIconStyleProps })}
              </div>
              <Flex flex={1} style={styles.inputContainer}>
                <input
                  style={styles.input}
                  value={searchQuery}
                  onChange={this.onChangeSearchQuery}
                  placeholder="Seller ID, username, or email"
                />
                <button type="button" onClick={this.clearSellerSearch}>Clear</button>
              </Flex>
            </Flex>
            <div
              ref={(ref) => { this.sellerList = ref; }}
              style={styles.listWrapper}
              onScroll={this.handleScroll}
            >
              {sellerList.map(sellerData => (
                <div key={sellerData.id}>
                  {this.renderSellerCard(sellerData)}
                </div>
              ))}
              {sellerList.length
                ? (
                  <Flex style={styles.loadMoreWrapper}>
                    <button type="button" onClick={this.handleEndReached}>Load More</button>
                  </Flex>
                ) : null}
            </div>
          </Flex>
        </Flex>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  sellers: DisbursementsSelectors.getSellers(state),
  sellerSearchResults: DisbursementsSelectors.getSellerSearchResults(state),
  sellersScrollTop: DisbursementsSelectors.getSellersScrollTop(state),
  pendingInfo: DisbursementsSelectors.getPendingInfo(state)
});

const mapDispatchToProps = dispatch => ({
  fetchSellersForDisbursement: () =>
    dispatch(DisbursementsActions.fetchSellersForDisbursement()),
  fetchSellersForDisbursementMore: () =>
    dispatch(DisbursementsActions.fetchMoreSellersForDisbursement()),
  fetchPendingDisbursementsData: () =>
    dispatch(DisbursementsActions.fetchPendingDisbursementsData()),
  searchSellersForDisbursement: query =>
    dispatch(DisbursementsActions.searchSellersForDisbursement(query)),
  clearSellerSearchResults: () =>
    dispatch(DisbursementsActions.clearSellerSearchResults()),
  setSellersScrollTop: value => dispatch(DisbursementsActions.setSellersScrollTop(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(DisbursementsPage);
