import { Colors } from "client/themes";

const styles = {
  container: {
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
  },
  frame: {
    backgroundColor: Colors.white,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: Colors.auxxitSlateDarker,
    borderStyle: "solid",
    position: "absolute",
    width: "90%",
    height: "90%",
    overflow: "auto"
  }
};

export default styles;
