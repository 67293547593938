import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Flex from "client/components/flex";
import { AppStateSelectors } from "client/state/redux/app_state_redux";
import UsersActions from "client/state/redux/users_redux";
import { envStyles } from "client/themes";
import styles from "./styles";

const USER_FIELD = "user";
const DEVICE_ID_FIELD = "deviceId";

class BlockDeviceForm extends Component {
  static propTypes = {
    blockDevice: PropTypes.func.isRequired,
    environment: PropTypes.string.isRequired,
    setBlockMessage: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      form: {
        user: "",
        deviceId: "",
        environment: props.environment
      }
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.state;
    const { blockDevice } = this.props;
    blockDevice(form);
  };

  handleChange = (name, value) => {
    const { setBlockMessage } = this.props;
    const { form } = this.state;
    this.setState({ form: { ...form, [name]: value } });
    setBlockMessage("");
  };

  onChangeUser = (e) => {
    this.handleChange(USER_FIELD, e.target.value);
  };

  onChangeDeviceId = (e) => {
    this.handleChange(DEVICE_ID_FIELD, e.target.value);
  };

  render() {
    const { form } = this.state;

    return (
      <Flex
        flexDirection="column"
        alignItems="center"
        width="500px"
        height="400px"
        style={styles.container}
      >
        <Flex style={styles.title} flex={2} alignItems="center">Block a Device</Flex>
        <form style={styles.formContainer}>
          <div>By User:</div>
          <Flex style={styles.inputContainer}>
            <div style={styles.label}>Username/Email</div>
            <input
              style={styles.input}
              value={form.user}
              placeholder="username1"
              autoComplete="off"
              onChange={this.onChangeUser}
            />
          </Flex>
          <div style={{ marginTop: 10 }}>By Device ID:</div>
          <Flex style={styles.inputContainer}>
            <div style={styles.label}>Device ID</div>
            <input
              style={styles.input}
              value={form.deviceId}
              placeholder="2k4lu5hj234k5l32k45"
              autoComplete="off"
              onChange={this.onChangeDeviceId}
            />
          </Flex>
          <Flex>
            <select
              style={{ ...styles.selectInput, ...envStyles[form.environment] }}
              value={form.environment}
              disabled
            >
              <option
                style={styles.selectOption}
                key={form.environment}
                value={form.environment}
              >
                {form.environment}
              </option>
            </select>
          </Flex>
          <button
            style={styles.saveButton}
            type="submit"
            onClick={this.handleSubmit}
          >
            Block
          </button>
        </form>
      </Flex>
    );
  }
}

const mapStateToProps = state => ({
  environment: AppStateSelectors.getEnvironment(state)
});

const mapDispatchToProps = dispatch => ({
  blockDevice: data => dispatch(UsersActions.blockDevice(data)),
  setBlockMessage: text => dispatch(UsersActions.setUsersMessage(text))
});

export default connect(mapStateToProps, mapDispatchToProps)(BlockDeviceForm);
