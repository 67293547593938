import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import LedgersActions, { LedgersSelectors } from "client/state/redux/ledgers_redux";
import Page from "client/components/page";
import Flex from "client/components/flex";
import DataGrid from "client/components/data_grid";
import styles from "./styles";

const GroupGmvModel = {
  id: {
    type: String,
    editable: false
  },
  name: {
    type: String,
    editable: false
  },
  count: {
    type: Number,
    editable: false
  },
  gmvUsd: {
    type: Number,
    editable: false
  }
};

const MONTH_OPTIONS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const START_YEAR = 2021;
const YEAR_COUNT = new Date().getFullYear() - START_YEAR + 1;
const YEAR_OPTIONS = new Array(YEAR_COUNT).fill().map((y, idx) => new Date().getFullYear() - idx);

class GroupsGmvPage extends Component {
  static propTypes = {
    downloadLedgerSummary: PropTypes.func.isRequired,
    fetchGroupsGrossMerchandiseValue: PropTypes.func.isRequired,
    groupsGmv: PropTypes.arrayOf(PropTypes.shape())
  };

  static defaultProps = {
    groupsGmv: []
  };

  static renderOptions = options => (
    Object.values(options).map(option => (
      <option
        key={option}
        style={styles.selectOption}
        value={option}
      >
        {option}
      </option>
    ))
  );

  state = {
    selectedMonth: MONTH_OPTIONS[new Date().getMonth()],
    selectedYear: new Date().getFullYear()
  };

  componentDidMount() {
    const { fetchGroupsGrossMerchandiseValue } = this.props;
    fetchGroupsGrossMerchandiseValue();
  }

  handleMonthDropdownChange = (e) => {
    const { fetchGroupsGrossMerchandiseValue } = this.props;
    const { selectedYear } = this.state;
    const { value: newSelectedMonth } = e.target;
    this.setState({ selectedMonth: newSelectedMonth });
    const month = MONTH_OPTIONS.indexOf(newSelectedMonth);
    fetchGroupsGrossMerchandiseValue(month, selectedYear);
  };

  handleYearDropdownChange = (e) => {
    const { fetchGroupsGrossMerchandiseValue } = this.props;
    const { selectedMonth } = this.state;
    const { value: newSelectedYear } = e.target;
    this.setState({ selectedYear: newSelectedYear });
    const month = MONTH_OPTIONS.indexOf(selectedMonth);
    fetchGroupsGrossMerchandiseValue(month, newSelectedYear);
  };

  downloadCsv = () => {
    const { downloadLedgerSummary } = this.props;
    const { selectedMonth, selectedYear } = this.state;
    const month = MONTH_OPTIONS.indexOf(selectedMonth);
    downloadLedgerSummary(month, selectedYear);
  };

  render() {
    const { groupsGmv } = this.props;
    const { selectedMonth, selectedYear } = this.state;
    const gmvTotal = groupsGmv.reduce((total, g) => total + g.gmv, 0);
    return (
      <Page
        title="Groups GMV"
      >
        <Flex style={styles.container} flexDirection="column">
          <Flex style={styles.contentHeader}>
            <Flex style={{ marginRight: 5 }}>
              <select
                style={styles.selectInput}
                value={selectedMonth}
                onChange={this.handleMonthDropdownChange}
              >
                {GroupsGmvPage.renderOptions(MONTH_OPTIONS)}
              </select>
            </Flex>
            <Flex style={{ marginLeft: 5, marginRight: 10 }}>
              <select
                style={styles.selectInput}
                value={selectedYear}
                onChange={this.handleYearDropdownChange}
              >
                {GroupsGmvPage.renderOptions(YEAR_OPTIONS)}
              </select>
            </Flex>
            <div style={styles.totalGmvWrapper}>
              <b>Total: </b>
              <span>{`$${gmvTotal.toFixed(2)}`}</span>
            </div>
            <button style={styles.downloadBtn} type="button" onClick={this.downloadCsv}>Download Ledger</button>
          </Flex>
          <Flex flex={1} style={styles.interface}>
            <DataGrid
              data={groupsGmv}
              model={GroupGmvModel}
              showSave={false}
            />
          </Flex>
        </Flex>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  groupsGmv: LedgersSelectors.getGroupsGmv(state),
});

const mapDispatchToProps = dispatch => ({
  fetchGroupsGrossMerchandiseValue: (month, year) =>
    dispatch(LedgersActions.fetchGroupsGrossMerchandiseValue(month, year)),
  downloadLedgerSummary: (month, year) =>
    dispatch(LedgersActions.downloadLedgerSummary(month, year))
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupsGmvPage);
