import axios from "axios";
import constants from "client/constants";

const { ENV } = constants;

const client = axios.create({
  baseURL: "/api",
  "Cache-Control": "no-cache",
  "Content-Type": "application/json",
  validateStatus: status => status < 400
});

client.setAccessToken = (accessToken) => {
  if (accessToken) {
    client.defaults.headers.common.Authorization = accessToken;
  } else {
    delete client.defaults.headers.common.Authorization;
  }
};

client.defaults.params = {};
client.setEnvironment = (environment) => {
  if (environment) {
    client.defaults.params.environment = environment;
  } else {
    client.defaults.params.environment = ENV.DEVELOPMENT;
  }
};

export default client;
