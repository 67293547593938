import { call, put, takeLatest, select } from "redux-saga/effects";
import AuthToken from "client/services/auth_token_storage";
import api from "client/services/api";
import callAuthApi from "client/services/call_auth_api";
import ModelHelper from "./helpers/user_model";
import CurrentUserActions, { CurrentUserTypes as types, CurrentUserSelectors } from "../redux/current_user_redux";
import AppStateActions from "../redux/app_state_redux";

function* fetchCurrentUser() {
  try {
    const { userId } = yield call(AuthToken.getAuthToken);
    const response = yield callAuthApi(api.getUser, userId);

    const currentUser = ModelHelper.sanitizeCurrentUser(response.data);

    const { environment } = currentUser.settings;
    yield call(api.client.setEnvironment, environment);
    yield put(AppStateActions.setEnvironmentSuccess(environment));
    delete currentUser.settings.environment;

    yield put(CurrentUserActions.fetchCurrentUserSuccess(currentUser));
  } catch (e) {
    yield put(CurrentUserActions.fetchCurrentUserFailure());
  }
}

function* saveUserSettings(action) {
  try {
    const { id: userId } = yield select(CurrentUserSelectors.getCurrentUser);
    const { data: settings } = action;

    let response;
    if (settings.changePassword.length) {
      const { changePassword, changePasswordConfirm } = settings;
      response = yield callAuthApi(api.changePassword, userId, {
        changePassword,
        changePasswordConfirm
      });
    }

    if (settings.someOtherSetting) {
      // do something else
    }

    yield put(CurrentUserActions.saveUserSettingsSuccess(response.data));
  } catch (e) {
    const err = (e.response && e.response.data) || e.message;
    yield put(CurrentUserActions.saveUserSettingsFailure(err));
  }
}

export default function currentUserSagas() {
  return [
    takeLatest(types.fetchCurrentUser, fetchCurrentUser),
    takeLatest(types.saveUserSettings, saveUserSettings)
  ];
}
