import { Colors } from "client/themes";

const styles = {
  footer: {
    fontSize: 20,
    color: Colors.white,
    bottom: 0
  }
};

export default styles;
