// NOTE: To get a proper name for a new color please use
//       http://chir.ag/projects/name-that-color/

export default {
  // achromatics colors
  black: "#000000",
  grey: "#454545",
  greyBlurred: "#454545CC",
  white: "#FFFFFF",
  offWhite: "#f9f9f9",
  transparent: "#00000000",
  darkTransparent: "rgba(0,0,0,.7)",

  // Auxxit Colors
  auxxitBlue: "#3e5a80",
  auxxitOrange: "#fc5707",
  auxxitSlate: "#dfe4eb",
  auxxitSlateDarker: "#bec4ce",
  auxxitSlateDarkest: "#72757b",
  linkBlue: "#4338ba",
  chatBlue: "#78acff",

  // brand colors
  halfBacked: "#88C4CA",
  horizon: "#6d6d6d",
  william: "#e1e5ea",
  outerSpace: "#2A3E41",

  // other colors
  offRed: "#e2acac",
  darkGreen: "#1b5145",
  lightBlue: "#0099ff",
  green: "#42f46b",
  pink: "#FFCCCC",
  red: "#ff0000",
  yellow: "#F8C544",
  cornflowerBlue: "#F1F9FF",
  alertGreen: "#009963",
  lightGrey: "#d4d5d6",
  emeraldGreen: "#50C878",

  replayYellow: "#ccff36",

  withOpacity: (color, opacity) => color.slice(0, 7) + opacity,
};
