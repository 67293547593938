import { put, takeLatest, takeLeading } from "redux-saga/effects";
import api from "client/services/api";
import callAuthApi from "client/services/call_auth_api";
import ModelHelper from "./helpers/group_model";
import LedgersActions, {
  LedgersTypes as types
} from "../redux/ledgers_redux";
import AppStateActions from "../redux/app_state_redux";

function* fetchGroupsGrossMerchandiseValue({ month, year }) {
  try {
    const monthParam = month === 0 ? 0 : month || new Date().getMonth();
    const yearParam = year || new Date().getFullYear();
    const response = yield callAuthApi(api.fetchGroupsGrossMerchandiseValue, {
      month: monthParam,
      year: yearParam
    });
    const groupsGmv = ModelHelper.sanitizeGroupsGmv(response.data);
    yield put(LedgersActions.fetchGroupsGrossMerchandiseValueSuccess(groupsGmv));
  } catch (e) {
    yield put(LedgersActions.fetchGroupsGrossMerchandiseValueFailure());
  }
}

const getMonthFromIndex = (idx) => {
  const num = idx + 1;
  if (num < 10) {
    return `0${num.toString()}`;
  }
  return num.toString();
};

function* downloadLedgerSummary({ month, year }) {
  try {
    const monthParam = month === 0 ? 0 : month || new Date().getMonth();
    const yearParam = year || new Date().getFullYear();
    const response = yield callAuthApi(api.downloadLedgerSummary, {
      month: monthParam,
      year: yearParam
    });
    const url = window.URL.createObjectURL(new window.Blob([response.data], { type: "text/csv" }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `ledger-${getMonthFromIndex(monthParam)}-${yearParam}.csv`);
    // Append to html page
    document.body.appendChild(link);
    // Force download
    link.click();
    // Clean up and remove the link
    link.parentNode.removeChild(link);
    yield put(AppStateActions.setAppMessage({ message: "Successfully downloaded CSV of ledger summary" }));
  } catch (e) {
    yield put(AppStateActions.setAppMessage({ message: "Failed to download CSV of ledger summary" }));
  }
}

export default function ledgersSagas() {
  return [
    takeLatest(types.fetchGroupsGrossMerchandiseValue, fetchGroupsGrossMerchandiseValue),
    takeLeading(types.downloadLedgerSummary, downloadLedgerSummary)
  ];
}
