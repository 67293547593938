import { Colors } from "client/themes";

const styles = {
  copyrightText: {
    color: Colors.black,
    fontSize: 16,
    padding: 8
  }
};

export default styles;
