import { Colors } from "client/themes";

const styles = {
  signUpLinkText: {
    color: Colors.black,
    fontSize: 16,
    cursor: "pointer",
    padding: 8
  }
};

export default styles;
