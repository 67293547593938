import api from "./client";

function fetchGroups() {
  return api.get("/groups");
}

function fetchGroupSets() {
  return api.get("/groups/sets");
}

function fetchGroupFees() {
  return api.get("/groups/fees");
}

function modifyGroups(params) {
  return api.put("/groups", params);
}

function createGroup(params) {
  return api.post("/groups", params);
}

function fetchGroupFeeTotals(groupId) {
  return api.get(`/groups/${groupId}/fees/totals`);
}

function fetchGroupMemberEmails(groupId) {
  return api.get(`/groups/${groupId}/emails`);
}

export default {
  fetchGroups,
  fetchGroupSets,
  modifyGroups,
  createGroup,
  fetchGroupFees,
  fetchGroupFeeTotals,
  fetchGroupMemberEmails
};
