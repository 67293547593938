import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import Flex from "client/components/flex";
import NavbarRight from "client/components/navbar_right";
import NavbarLeft from "client/components/navbar_left";
import AuthOnly from "client/containers/auth_only";
import EnvDropdown from "./components/env_dropdown";
import LogoutButton from "./components/logout_button";
import SettingsButton from "./components/settings_button";
import UserText from "./components/user_text";

class MainNavbar extends PureComponent {
  render() {
    return (
      <Flex
        flex={1}
        alignItems="center"
      >
        <NavbarLeft>
          <AuthOnly>
            <UserText />
          </AuthOnly>
        </NavbarLeft>
        <NavbarRight>
          <AuthOnly>
            <EnvDropdown />
            <SettingsButton />
            <LogoutButton />
          </AuthOnly>
        </NavbarRight>
      </Flex>
    );
  }
}

export default withRouter(MainNavbar);
