import { all } from "redux-saga/effects";

import authSagas from "./auth_sagas";
import currentUserSagas from "./current_user_sagas";
import startupSagas from "./startup_sagas";
import usersSagas from "./users_sagas";
import appStateSagas from "./app_state_sagas";
import groupsSagas from "./groups_sagas";
import auctionsSagas from "./auctions_sagas";
import itemsSagas from "./items_sagas";
import feedbackSagas from "./feedback_sagas";
import releasesSagas from "./releases_sagas";
import exposSagas from "./expos_sagas";
import disbursementsSagas from "./disbursements_sagas";
import ledgersSagas from "./ledgers_sagas";
import privateOffersSagas from "./private_offers_sagas";

export default function* sagas() {
  yield all([
    ...authSagas(),
    ...currentUserSagas(),
    ...startupSagas(),
    ...usersSagas(),
    ...appStateSagas(),
    ...groupsSagas(),
    ...auctionsSagas(),
    ...itemsSagas(),
    ...feedbackSagas(),
    ...releasesSagas(),
    ...exposSagas(),
    ...disbursementsSagas(),
    ...ledgersSagas(),
    ...privateOffersSagas()
  ]);
}
