import { Colors } from "client/themes";

const styles = {
  container: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  interface: {
    backgroundColor: Colors.white,
    width: "90%",
    height: "90%",
    borderRadius: 10,
    boxShadow: `3px 4px 4px ${Colors.grey}`,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    marginRight: "10px",
    marginLeft: "10px",
    paddingTop: "10px",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row"
  },
  userInfo: {
    backgroundColor: Colors.offWhite,
    borderRadius: 6,
    borderColor: Colors.grey,
    borderStyle: "solid",
    borderWidth: 1,
    boxShadow: `1px 2px 2px 1px ${Colors.grey}`,
    padding: 10
  },
  avatarWrapper: {
    marginBottom: 10
  },
  avatar: {
    height: 100,
    width: 100
  },
  linkText: {
    color: Colors.linkBlue,
    textDecoration: "underline"
  },
  usernameText: {
    color: Colors.black,
    fontSize: 18,
    fontWeight: "bold"
  },
  toolboxRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10
  },
  infoRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  contactInfo: {
    backgroundColor: Colors.offWhite,
    borderRadius: 6,
    borderColor: Colors.grey,
    borderStyle: "solid",
    borderWidth: 1,
    boxShadow: `1px 2px 2px 1px ${Colors.grey}`,
    padding: 10
  },
  paymentInfo: {
    backgroundColor: Colors.offWhite,
    borderRadius: 6,
    borderColor: Colors.grey,
    borderStyle: "solid",
    borderWidth: 1,
    boxShadow: `1px 2px 2px 1px ${Colors.grey}`,
    padding: 10
  },
  figureWrapper: {
    backgroundColor: Colors.offWhite,
    borderRadius: 6,
    borderColor: Colors.grey,
    borderStyle: "solid",
    borderWidth: 1,
    boxShadow: `1px 2px 2px 1px ${Colors.grey}`,
    position: "relative",
    padding: 10,
    marginLeft: 10,
    marginRight: 10,
  },
  addressRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start"
  },
  scrollView: {
    overflowY: "scroll",
  },
  labelValueRow: {
    display: "flex",
    flexDirection: "row"
  },
  labelText: {
    color: Colors.black,
    fontWeight: "bold"
  },
  mainText: {
    color: Colors.black
  },
  redText: {
    color: Colors.red,
  },
  buttonsRow: {
    display: "flex",
    flexDirection: "row",
    marginTop: 10,
  },
  itemRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  itemRowText: {
    color: Colors.black,
    flex: 1
  },
  addressColumn1: {
    display: "flex",
    flexDirection: "column",
    marginRight: 5,
  },
  addressColumn2: {
    display: "flex",
    flexDirection: "column"
  },
  actionsButton: {
    display: "flex",
    backgroundColor: Colors.lightBlue,
    opacity: 0.5,
    width: 100,
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 10,
  },
  actionsButtonText: {
    color: "rgba(255,255,255,0.5)",
  },
  disbursedButton: {
    display: "flex",
    backgroundColor: Colors.auxxitOrange,
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    boxShadow: `2px 3px 3px ${Colors.grey}`,
  },
  actionDropdown: {
    marginRight: 10
  },
  buttonText: {
    color: Colors.white,
    textAlign: "center",
    fontWeight: "bold"
  },
  content: {
    flexDirection: "column",
    backgroundColor: Colors.white,
    marginLeft: 10,
    marginRight: 10,
    padding: 20,
    borderRadius: 10,
    overflow: "auto"
  },
  paymentContainer: {
    backgroundColor: Colors.auxxitSlateDarker,
    marginBottom: 15,
    padding: 10,
    borderRadius: 2
  },
  paymentHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  salesValue: {
    padding: 5,
    borderRadius: "2px 0 0 2px",
    backgroundColor: Colors.auxxitOrange
  },
  salesValueWrapper: {
    padding: 5,
    borderRadius: 2,
    backgroundColor: Colors.auxxitOrange,
    color: Colors.white
  },
  feesValueWrapper: {
    padding: 5,
    borderRadius: 2,
    backgroundColor: Colors.yellow,
    color: Colors.white
  },
  gmvValueWrapper: {
    padding: 5,
    borderRadius: 2,
    backgroundColor: Colors.auxxitBlue,
    color: Colors.white
  },
  feesValue: {
    padding: 5,
    backgroundColor: Colors.yellow
  },
  gmvValue: {
    padding: 5,
    borderRadius: "0 2px 2px 0",
    backgroundColor: Colors.auxxitBlue
  },
  purchasesContainer: {
    backgroundColor: Colors.william,
    marginTop: 5,
    borderRadius: 10,
    padding: 5,
    boxShadow: `1px 2px 2px ${Colors.grey}`
  },
  selectInput: {
    fontSize: 14,
    padding: 10,
    borderRadius: 6,
    backgroundColor: Colors.white,
    width: 200,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: Colors.auxxitSlateGrey,
    boxShadow: `2px 3px 3px ${Colors.grey}`,
  },
  dateRangeInput: {
    fontSize: 14,
    padding: 9,
    borderRadius: 6,
    backgroundColor: Colors.white,
    width: 125,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: Colors.auxxitSlateDarker,
    marginLeft: 5,
    boxShadow: `2px 3px 3px ${Colors.grey}`,
  },
  selectOption: {
    padding: 4,
    color: Colors.black,
    backgroundColor: Colors.white,
    paddingTop: 2,
    width: 10,
    height: 10,
  },
  checkbox: {
    width: 20,
    height: 20,
    marginRight: 15
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
    alignItems: "center"
  },
  markDisbursedText: {
    marginRight: 10
  },
  inputContainer: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.auxxitSlateDarker,
    borderStyle: "solid",
    padding: 10,
    backgroundColor: "white"
  },
  input: {
    color: Colors.lightblue,
    fontSize: 16
  },
};

export const iconStyleProps = {
  size: 40,
  style: {
    display: "inline-block",
    paddingRight: 12,
    alignSelf: "center",
  }
};

export default styles;
