import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { GroupsSelectors } from "client/state/redux/groups_redux";
import Page from "client/components/page";
import Flex from "client/components/flex";
import CreateGroup from "./components/create_group_form";

class CreateGroupPage extends PureComponent {
  static propTypes = {
    groupsMessage: PropTypes.string.isRequired
  };

  render() {
    const { groupsMessage } = this.props;
    return (
      <Page
        title="Admin - Create Group"
      >
        <Flex
          flex={8}
          alignItems="center"
          justifyContent="center"
        >
          <CreateGroup />
        </Flex>
        <Flex
          flex={1}
          alignItems="center"
          justifyContent="center"
        >
          {groupsMessage}
        </Flex>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  groupsMessage: GroupsSelectors.getGroupsMessage(state)
});

export default connect(mapStateToProps, null)(CreateGroupPage);
