import api from "./client";

function fetchFeedback() {
  return api.get("/feedback");
}

function modifyFeedback(params) {
  return api.put("/feedback", params);
}

export default {
  fetchFeedback,
  modifyFeedback
};
