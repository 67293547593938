import { Colors } from "client/themes";

const styles = {
  navbarItem: {
    margin: "1%",
    padding: "2px 5px 2px 5px",
    color: Colors.black
  },
  navbarBtn: {
    backgroundColor: Colors.auxxitBlue,
    color: Colors.white,
    border: "none",
    fontSize: 14,
    paddingLeft: 15,
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 15,
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: Colors.auxxitSlateDarker
  }
};

export default styles;
