import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Flex from "client/components/flex";
import { AppStateSelectors } from "client/state/redux/app_state_redux";
import ReleasesActions from "client/state/redux/releases_redux";
import { envStyles } from "client/themes";
import styles from "./styles";

const APP_VERSION_FIELD = "appVersion";
const API_VERSION_FIELD = "apiVersion";
const MINIMUM_VERSION_FIELD = "minimumVersion";

class ReleaseForm extends Component {
  static propTypes = {
    draftRelease: PropTypes.func.isRequired,
    environment: PropTypes.string.isRequired,
    setReleaseMessage: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      form: {
        appVersion: "",
        apiVersion: "",
        minimumVersion: "",
        environment: props.environment
      }
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.state;
    const { draftRelease } = this.props;
    draftRelease(form);
  };

  handleChange = (name, value) => {
    const { setReleaseMessage } = this.props;
    const { form } = this.state;
    this.setState({ form: { ...form, [name]: value } });
    setReleaseMessage("");
  };

  onChangeAppVersion = (e) => {
    this.handleChange(APP_VERSION_FIELD, e.target.value);
  };

  onChangeApiVersion = (e) => {
    this.handleChange(API_VERSION_FIELD, e.target.value);
  };

  onChangeMinimumVersion = (e) => {
    this.handleChange(MINIMUM_VERSION_FIELD, e.target.value);
  };

  render() {
    const { form } = this.state;

    return (
      <Flex
        flexDirection="column"
        alignItems="center"
        width="500px"
        height="400px"
        style={styles.container}
      >
        <Flex style={styles.title} flex={2} alignItems="center">Draft a Release!</Flex>
        <form style={styles.formContainer}>
          <Flex style={styles.inputContainer}>
            <div style={styles.label}>App Version</div>
            <input
              style={styles.input}
              value={form.appVersion}
              placeholder="1.2.3"
              autoComplete="off"
              onChange={this.onChangeAppVersion}
            />
          </Flex>
          <Flex style={styles.inputContainer}>
            <div style={styles.label}>API Version</div>
            <input
              style={styles.input}
              value={form.apiVersion}
              placeholder="v1"
              autoComplete="off"
              onChange={this.onChangeApiVersion}
            />
          </Flex>
          <Flex style={styles.inputContainer}>
            <div style={styles.label}>Minimum Version</div>
            <input
              style={styles.input}
              value={form.minimumVersion}
              placeholder="1.2.3"
              autoComplete="off"
              onChange={this.onChangeMinimumVersion}
            />
          </Flex>
          <Flex>
            <select
              style={{ ...styles.selectInput, ...envStyles[form.environment] }}
              value={form.environment}
              disabled
            >
              <option
                style={styles.selectOption}
                key={form.environment}
                value={form.environment}
              >
                {form.environment}
              </option>
            </select>
          </Flex>
          <button
            style={styles.saveButton}
            type="submit"
            onClick={this.handleSubmit}
          >
            Draft
          </button>
        </form>
      </Flex>
    );
  }
}

const mapStateToProps = state => ({
  environment: AppStateSelectors.getEnvironment(state)
});

const mapDispatchToProps = dispatch => ({
  draftRelease: release => dispatch(ReleasesActions.draftRelease(release)),
  setReleaseMessage: text => dispatch(ReleasesActions.setReleaseMessage(text))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReleaseForm);
