import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as FlatColorIcons from "react-icons/fc";
import { FaCopy } from "react-icons/fa";
import GroupsActions, { GroupsSelectors } from "client/state/redux/groups_redux";
import Page from "client/components/page";
import Flex from "client/components/flex";
import styles, { detailIconStyleProps, copyIconStyleProps } from "./styles";

const copyIdToClipboard = groupId => () => {
  navigator.clipboard.writeText(groupId);
};

class GroupsPage extends Component {
  static propTypes = {
    downloadMemberEmails: PropTypes.func.isRequired,
    fetchGroups: PropTypes.func.isRequired,
    groups: PropTypes.arrayOf(PropTypes.shape()),
  };

  static defaultProps = {
    groups: []
  };

  componentDidMount() {
    const { fetchGroups } = this.props;
    fetchGroups();
  }

  downloadMemberEmails = groupId => () => {
    const { downloadMemberEmails } = this.props;
    downloadMemberEmails(groupId);
  };

  // eslint-disable-next-line class-methods-use-this
  renderGroupCard = (groupData, idx) => (
    <div key={groupData.id} style={styles.dataContainer}>
      <div style={styles.groupDetailsWrap}>
        <div style={styles.groupName}>{groupData.name}</div>
        <div onClick={copyIdToClipboard(groupData.id)} role="button" tabIndex={idx} style={styles.copyBtn}>
          {React.createElement(FaCopy, { ...copyIconStyleProps })}
        </div>
        <div style={styles.groupText}>{`Created ${new Date(groupData.createDate).toLocaleDateString()}`}</div>
        <div style={styles.groupText}>{groupData.createdBy?.email}</div>
      </div>
      <div style={styles.reportsWrap}>
        <div style={styles.reportsIconWrapper}>
          {React.createElement(FlatColorIcons.FcDataSheet, { ...detailIconStyleProps })}
          <div style={{ ...styles.iconText }}>Downloads</div>
        </div>
        <div style={styles.detailsRows}>
          <button style={styles.downloadBtn} type="button" onClick={this.downloadMemberEmails(groupData.id)}>
            Download Emails
          </button>
        </div>
      </div>
    </div>
  );

  render() {
    const { groups } = this.props;
    return (
      <Page
        title="Groups"
      >
        <Flex style={styles.container} flexDirection="column">
          <Flex style={styles.content} flexDirection="column">
            <div style={styles.listWrapper}>
              {groups.length
                ? (
                  groups.map((group, idx) => (
                    <div key={group.id}>
                      {this.renderGroupCard(group, idx)}
                    </div>
                  ))
                ) : (
                  <div style={styles.emptyListText}>No groups found</div>
                )}
            </div>
          </Flex>
        </Flex>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  groups: GroupsSelectors.getGroups(state),
});

const mapDispatchToProps = dispatch => ({
  downloadMemberEmails: groupId => dispatch(GroupsActions.downloadMemberEmails(groupId)),
  fetchGroups: () => dispatch(GroupsActions.fetchGroups())
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupsPage);
