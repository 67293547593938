import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ItemsActions, { ItemsSelectors } from "client/state/redux/items_redux";
import Flex from "client/components/flex";
import DataGrid from "client/components/data_grid";
import styles from "./styles";

const ItemModel = {
  id: {
    type: String,
    editable: false
  },
  createdBy: {
    type: String,
    editable: true
  },
  type: {
    type: String,
    editable: false
  },
  groupId: {
    type: String,
    editable: false
  },
  name: {
    type: String,
    editable: true
  },
  condition: {
    type: String,
    editable: true
  },
  quantity: {
    type: Number,
    editable: true
  },
  reservePrice: {
    type: Number,
    editable: true
  },
  startPrice: {
    type: Number,
    editable: true
  },
  salesTax: {
    type: Object,
    editable: true
  },
  images: {
    type: Array,
    editable: true
  },
  watchers: {
    type: Array,
    editable: true
  },
  rejected: {
    type: Boolean,
    editable: true
  },
  shipping: {
    type: Object,
    editable: true
  },
  payment: {
    type: Object,
    editable: true
  },
  createDate: {
    type: Date,
    editable: false
  },
  lastModified: {
    type: Date,
    editable: false
  },
  // lifecycle
  approvedBy: {
    type: String,
    editable: true
  },
  auctionId: { // auction
    type: String,
    editable: false
  },
  startDate: {
    type: String,
    editable: false
  },
  endDate: {
    type: String,
    editable: false
  },
  bids: {
    type: Array,
    editable: true
  },
  winner: {
    type: Object,
    editable: true
  },
  liveMarketId: { // market
    type: String,
    editable: false
  },
  duration: {
    type: Number,
    editable: true
  },
  category: {
    type: String,
    editable: true
  },
  acceptPrice: {
    type: Number,
    editable: true
  },
  rejectPrice: {
    type: Number,
    editable: true
  },
  priceReductions: {
    type: Array,
    editable: true
  },
  offers: {
    type: Array,
    editable: true
  }
};

class ItemsInterface extends Component {
  static propTypes = {
    fetchItems: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    modifyItems: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      changes: {}
    };
  }

  componentDidMount() {
    const { fetchItems } = this.props;
    fetchItems();
  }

  handleChange = (id, field) => (value, noChange) => {
    this.setState((prevState) => {
      const newChanges = { ...prevState.changes };
      // if no change, delete that field
      if (noChange) {
        delete newChanges[id][field];
        // if no change and object now empty, delete object
        if (!Object.keys(newChanges[id]).length) {
          delete newChanges[id];
        }
      // else, make change to requested field
      } else {
        newChanges[id] = { ...newChanges[id], [field]: value };
      }
      return { changes: newChanges };
    });
  }

  handleSave = () => {
    const { changes } = this.state;
    this.props.modifyItems(changes);
  }

  render() {
    const { items } = this.props;
    const { changes } = this.state;
    const showSave = Object.keys(changes).length > 0;
    return (
      <Flex flex={1} style={styles.interface}>
        <DataGrid
          data={items}
          model={ItemModel}
          showSave={showSave}
          handleEdit={this.handleChange}
          handleSave={this.handleSave}
        />
      </Flex>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  fetchItems: () => dispatch(ItemsActions.fetchItems()),
  modifyItems: data => dispatch(ItemsActions.modifyItems(data))
});

const mapStateToProps = state => ({
  items: ItemsSelectors.getItems(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemsInterface);
