import { Map } from "immutable";

const INIT_STATE = Map({
  isLoggingIn: false,
  loginMessage: "",
  isLoggingOut: false,
  isSigningUp: false,
  signUpMessage: "",
  isRefreshing: false
});

const types = {
  attemptLogIn: "ATTEMPT_LOG_IN",
  logInSuccess: "LOG_IN_SUCCESS",
  logInFailure: "LOG_IN_FAILURE",
  setLoggedIn: "SET_LOGGED_IN",
  setLoginMessage: "SET_LOGIN_MESSAGE",
  attemptLogOut: "ATTEMPT_LOG_OUT",
  logOutSuccess: "LOG_OUT_SUCCESS",
  logOutFailure: "LOG_OUT_FAILURE",
  attemptSignUp: "ATTEMPT_SIGN_UP",
  signUpSuccess: "SIGN_UP_SUCCESS",
  signUpFailure: "SIGN_UP_FAILURE",
  setSignUpMessage: "SET_SIGN_UP_MESSAGE",
  attemptRefreshToken: "ATTEMPT_REFRESH_TOKEN",
  refreshTokenSuccess: "REFRESH_TOKEN_SUCCESS",
  refreshTokenFailure: "REFRESH_TOKEN_FAILURE"
};

/* ********* Actions ********* */

const actions = {
  attemptLogIn(data) {
    return {
      type: types.attemptLogIn,
      data
    };
  },
  logInSuccess(data) {
    return {
      type: types.logInSuccess,
      data
    };
  },
  logInFailure(data) {
    return {
      type: types.logInFailure,
      data
    };
  },
  setLoginMessage(data) {
    return {
      type: types.setLoginMessage,
      data
    };
  },
  attemptLogOut() {
    return {
      type: types.attemptLogOut
    };
  },
  logOutSuccess() {
    return {
      type: types.logOutSuccess
    };
  },
  logOutFailure() {
    return {
      type: types.logOutFailure
    };
  },
  attemptSignUp(data) {
    return {
      type: types.attemptSignUp,
      data
    };
  },
  signUpSuccess() {
    return {
      type: types.signUpSuccess
    };
  },
  signUpFailure(data) {
    return {
      type: types.signUpFailure,
      data
    };
  },
  setSignUpMessage(data) {
    return {
      type: types.setSignUpMessage,
      data
    };
  },
  attemptRefreshToken(data) {
    return {
      type: types.attemptRefreshToken,
      data
    };
  },
  refreshTokenSuccess() {
    return {
      type: types.refreshTokenSuccess
    };
  },
  refreshTokenFailure() {
    return {
      type: types.refreshTokenFailure
    };
  }
};

/* ********* Reducer ********* */

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case types.attemptLogIn:
    return state.merge({ isLoggingIn: true });
  case types.logInSuccess:
    return state.merge({ isLoggingIn: false });
  case types.logInFailure:
    return state.merge({
      isLoggingIn: false,
      loginMessage: action.data.message || JSON.stringify(action.data)
    });
  case types.setLoginMessage:
    return state.merge({ loginMessage: action.data });
  case types.attemptLogOut:
    return state.merge({ isLoggingOut: true });
  case types.logOutSuccess:
    return state.merge({ isLoggingOut: false });
  case types.logOutFailure:
    return state.merge({ isLoggingOut: false });
  case types.attemptSignUp:
    return state.merge({ isSigningUp: true });
  case types.signUpSuccess:
    return state.merge({ isSigningUp: false });
  case types.signUpFailure:
    return state.merge({
      isSigningUp: false,
      signUpMessage: action.data.message || JSON.stringify(action.data)
    });
  case types.setSignUpMessage:
    return state.merge({ signUpMessage: action.data });
  case types.clearSignUpMessage:
    return state.merge({ signUpMessage: "" });
  case types.clearLogInMessage:
    return state.merge({ loginMessage: "" });
  default:
    return state;
  }
};

/* ********* Selectors ********* */

const auth = state => state.get("auth");

const selectors = {
  getLoginMessage: state => auth(state).get("loginMessage"),
  getSignUpMessage: state => auth(state).get("signUpMessage"),
  getIsRefreshing: state => auth(state).get("isRefreshing")
};

export {
  types as AuthTypes,
  reducer as AuthReducer,
  selectors as AuthSelectors
};
export default actions;
