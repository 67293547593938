import { call, put, takeLatest } from "redux-saga/effects";
import api from "client/services/api";
import ModelHelper from "./helpers/release_model";
import ReleasesActions, { ReleasesTypes as types } from "../redux/releases_redux";

function* fetchReleases() {
  try {
    const response = yield call(api.fetchReleases);
    const releases = ModelHelper.sanitizeReleases(response.data);
    yield put(ReleasesActions.fetchReleasesSuccess(releases));
  } catch (e) {
    yield put(ReleasesActions.fetchReleasesFailure());
  }
}

function* draftRelease(action) {
  try {
    const response = yield call(api.draftRelease, action.data);
    yield put(ReleasesActions.draftReleaseSuccess(response.data));
  } catch (e) {
    yield put(ReleasesActions.draftReleaseFailure(e.response.data));
  }
}

export default function releasesSagas() {
  return [
    takeLatest(types.fetchReleases, fetchReleases),
    takeLatest(types.draftRelease, draftRelease)
  ];
}
