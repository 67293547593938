import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Flex from "client/components/flex";
import AppStateActions, { AppStateSelectors } from "client/state/redux/app_state_redux";
import constants from "client/constants";
import { envStyles } from "client/themes";
import styles from "./styles";

const { ENV } = constants;

class EnvDropdown extends PureComponent {
  static propTypes = {
    environment: PropTypes.string.isRequired,
    setEnvironment: PropTypes.func.isRequired
  };

  handleChange = (e) => {
    this.props.setEnvironment(e.target.value);
  };

  static renderEnvOptions = () => (
    Object.values(ENV).map(env => (
      <option
        key={env}
        style={styles.selectOption}
        value={env}
      >
        {env[0].toUpperCase() + env.substring(1)}
      </option>
    ))
  );

  render() {
    const { environment } = this.props;
    return (
      <Flex style={{ padding: 10 }}>
        <select
          style={{ ...styles.selectInput, ...envStyles[environment] }}
          value={environment}
          onChange={this.handleChange}
        >
          {EnvDropdown.renderEnvOptions()}
        </select>
      </Flex>
    );
  }
}

const mapStateToProps = state => ({
  environment: AppStateSelectors.getEnvironment(state),
});

const mapDispatchToProps = dispatch => ({
  setEnvironment: env => dispatch(AppStateActions.setEnvironment(env))
});

export default connect(mapStateToProps, mapDispatchToProps)(EnvDropdown);
