import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Flex from "client/components/flex";
import styles from "./styles";

const USERNAME_FIELD = "username";
const PASSWORD_FIELD = "password";

class LoginForm extends PureComponent {
  static propTypes = {
    form: PropTypes.shape({
      password: PropTypes.string,
      username: PropTypes.string
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired
  };

  onChangeUsername = (e) => {
    this.props.handleChange(USERNAME_FIELD, e.target.value);
  }

  onChangePassword = (e) => {
    this.props.handleChange(PASSWORD_FIELD, e.target.value);
  }

  render() {
    const { handleSubmit, form: { username, password } } = this.props;
    return (
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="400px"
        height="400px"
        style={styles.container}
      >
        <Flex flex={2} alignItems="center">Log in to Auxxit&apos;s admin portal!</Flex>
        <form style={styles.formContainer}>
          <Flex style={styles.inputContainer}>
            <div style={styles.label}>Username</div>
            <input style={styles.input} value={username} autoComplete="username" onChange={this.onChangeUsername} />
          </Flex>
          <Flex style={styles.inputContainer}>
            <div style={styles.label}>Password</div>
            <input style={styles.input} value={password} type="password" autoComplete="current-password" onChange={this.onChangePassword} />
          </Flex>
          <button
            style={styles.loginButton}
            type="submit"
            onClick={handleSubmit}
          >
            Log In
          </button>
        </form>
        <Flex
          flex={2}
          alignItems="center"
          justifyContent="center"
        >
          <div style={styles.signUpText}>
            Don&apos;t have an account? Contact an admin to create one.
          </div>
        </Flex>
      </Flex>
    );
  }
}

export default LoginForm;
