import { Colors } from "client/themes";

const styles = {
  container: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  interface: {
    backgroundColor: Colors.white,
    width: "90%",
    height: "90%",
    borderRadius: 10,
    boxShadow: `3px 4px 4px ${Colors.grey}`,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    marginRight: "10px",
    marginLeft: "10px",
    paddingTop: "10px",
  },
  importWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flex: 1
  }
};

export const iconStyleProps = {
  color: Colors.white,
  size: 35
};

export default styles;
