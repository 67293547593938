import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { UsersSelectors } from "client/state/redux/users_redux";
import Page from "client/components/page";
import Flex from "client/components/flex";
import BlockDeviceForm from "./components/block_device_form";

class BlockPage extends PureComponent {
  static propTypes = {
    blockMessage: PropTypes.string.isRequired
  };

  render() {
    const { blockMessage } = this.props;
    return (
      <Page
        title="Admin - Block"
      >
        <Flex
          flex={8}
          alignItems="center"
          justifyContent="center"
        >
          <BlockDeviceForm />
        </Flex>
        <Flex
          flex={1}
          alignItems="center"
          justifyContent="center"
        >
          {blockMessage}
        </Flex>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  blockMessage: UsersSelectors.getUsersMessage(state)
});

export default connect(mapStateToProps, null)(BlockPage);
