import { Map, List, fromJS } from "immutable";
import memoize from "fast-memoize";

const INIT_STATE = Map({
  isFetching: false,
  usersMessage: "",
  users: List([]),
  userSearchResults: List([])
});

const types = {
  searchUsers: "SEARCH_USERS",
  searchUsersSuccess: "SEARCH_USERS_SUCCESS",
  searchUsersFailure: "SEARCH_USERS_FAILURE",
  clearUserSearchResults: "CLEAR_USER_SEARCH_RESULTS",
  fetchUsers: "FETCH_USERS",
  fetchUsersSuccess: "FETCH_USERS_SUCCESS",
  fetchUsersFailure: "FETCH_USERS_FAILURE",
  modifyUsers: "MODIFY_USERS",
  modifyUsersSuccess: "MODIFY_USERS_SUCCESS",
  modifyUsersFailure: "MODIFY_USERS_FAILURE",
  setUsersMessage: "SET_USERS_MESSAGE",
  blockDevice: "BLOCK_DEVICE",
  blockDeviceSuccess: "BLOCK_DEVICE_SUCCESS",
  blockDeviceFailure: "BLOCK_DEVICE_FAILURE",
  downloadUsersLast7Days: "DOWNLOAD_USERS_LAST_7_DAYS"
};

/* ********* Actions ********* */

const actions = {
  searchUsers(query) {
    return {
      type: types.searchUsers,
      query
    };
  },
  searchUsersSuccess(data) {
    return {
      type: types.searchUsersSuccess,
      data
    };
  },
  searchUsersFailure(data) {
    return {
      type: types.searchUsersFailure,
      data
    };
  },
  clearUserSearchResults() {
    return {
      type: types.clearUserSearchResults
    };
  },
  fetchUsers() {
    return {
      type: types.fetchUsers
    };
  },
  fetchUsersSuccess(data) {
    return {
      type: types.fetchUsersSuccess,
      data
    };
  },
  fetchUsersFailure(data) {
    return {
      type: types.fetchUsersFailure,
      data
    };
  },
  modifyUsers(data) {
    return {
      type: types.modifyUsers,
      data
    };
  },
  modifyUsersSuccess() {
    return {
      type: types.modifyUsersSuccess
    };
  },
  modifyUsersFailure() {
    return {
      type: types.modifyUsersFailure
    };
  },
  setUsersMessage(data) {
    return {
      type: types.setUsersMessage,
      data
    };
  },
  blockDevice(data) {
    return {
      type: types.blockDevice,
      data
    };
  },
  blockDeviceSuccess(data) {
    return {
      type: types.blockDeviceSuccess,
      data
    };
  },
  blockDeviceFailure(data) {
    return {
      type: types.blockDeviceFailure,
      data
    };
  },
  downloadUsersLast7Days() {
    return {
      type: types.downloadUsersLast7Days
    };
  }
};

/* ********* Reducer ********* */

// eslint-disable-next-line default-param-last
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case types.fetchUsers:
    return state.merge({ isFetching: true });
  case types.fetchUsersSuccess:
    return state.merge({ isFetching: false, users: fromJS(action.data) });
  case types.fetchUsersFailure:
    return state.merge({ isFetching: false });
  case types.searchUsers:
    return state.merge({ isFetching: true });
  case types.searchUsersSuccess:
    return state.merge({ isFetching: false, userSearchResults: fromJS(action.data) });
  case types.searchUsersFailure:
    return state.merge({ isFetching: false });
  case types.clearUserSearchResults:
    return state.merge({ userSearchResults: List([]) });
  case types.modifyUsers:
    return state.merge({ isSubmitting: true });
  case types.modifyUsersSuccess:
    return state.merge({ isSubmitting: false });
  case types.modifyUsersFailure:
    return state.merge({ isSubmitting: false });
  case types.setUsersMessage:
    return state.merge({ usersMessage: action.data });
  case types.blockDevice:
    return state.merge({ isSubmitting: true });
  case types.blockDeviceSuccess:
    return state.merge({ isSubmitting: false, usersMessage: JSON.stringify(action.data) });
  case types.blockDeviceFailure:
    return state.merge({ isSubmitting: false, usersMessage: JSON.stringify(action.data) });
  default:
    return state;
  }
};

/* ********* Selectors ********* */

const users = state => state.get("users");

const selectors = {
  getUsers: memoize(state => users(state).get("users").toJS()),
  getUserSearchResults: memoize(state => users(state).get("userSearchResults").toJS()),
  getUsersMessage: state => users(state).get("usersMessage")
};

export {
  types as UsersTypes,
  reducer as UsersReducer,
  selectors as UsersSelectors
};
export default actions;
