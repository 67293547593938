import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AppStateActions, { AppStateSelectors } from "client/state/redux/app_state_redux";
import PopupMessage from "client/components/popup_message";

class AppMessage extends PureComponent {
  static propTypes = {
    expiresIn: PropTypes.number,
    hideAppMessage: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    showMessage: PropTypes.bool.isRequired
  };

  static defaultProps = {
    expiresIn: 2500
  };

  render() {
    const { message, expiresIn, hideAppMessage, showMessage } = this.props;
    return (
      <PopupMessage
        show={showMessage}
        message={message}
        expiresIn={expiresIn}
        onExpire={hideAppMessage}
      />
    );
  }
}

const mapStateToProps = state => ({
  message: AppStateSelectors.getAppMessage(state),
  expiresIn: AppStateSelectors.getAppMessageExpiration(state),
  showMessage: AppStateSelectors.getShowAppMessage(state)
});

const mapDispatchToProps = dispatch => ({
  hideAppMessage: () => dispatch(AppStateActions.hideAppMessage())
});

export default connect(mapStateToProps, mapDispatchToProps)(AppMessage);
