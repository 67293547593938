import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styles from "./styles";

class FlexContainer extends PureComponent {
  static propTypes = {
    alignItems: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
      PropTypes.array
    ]),
    class: PropTypes.string,
    flex: PropTypes.number,
    flexDirection: PropTypes.string,
    height: PropTypes.string,
    justifyContent: PropTypes.string,
    style: PropTypes.shape(),
    width: PropTypes.string
  }

  static defaultProps = {
    children: null,
    flex: null,
    flexDirection: "row",
    alignItems: null,
    class: "",
    height: null,
    justifyContent: null,
    style: {},
    width: null
  }

  render() {
    const {
      width,
      height,
      class: _class,
      children,
      flex,
      flexDirection,
      justifyContent,
      alignItems,
      style
    } = this.props;

    const flexStyles = {
      width,
      height,
      ...style
    };

    return (
      <div
        className={`${_class || ""}`}
        style={{
          flex,
          flexDirection,
          justifyContent,
          alignItems,
          ...styles.flexContainer,
          ...flexStyles
        }}
      >
        {children}
      </div>
    );
  }
}

export default FlexContainer;
