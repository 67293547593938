import { call, put, takeLatest } from "redux-saga/effects";
import callAuthApi from "client/services/call_auth_api";
import api from "client/services/api";
import ModelHelper from "./helpers/group_model";
import AppStateActions from "../redux/app_state_redux";
import GroupsActions, { GroupsTypes as types } from "../redux/groups_redux";

function* fetchGroups() {
  try {
    const response = yield call(api.fetchGroups);
    const groups = ModelHelper.sanitizeGroups(response.data);
    yield put(GroupsActions.fetchGroupsSuccess(groups));
  } catch (e) {
    yield put(GroupsActions.fetchGroupsFailure());
  }
}

function* fetchGroupSets() {
  try {
    const response = yield call(api.fetchGroupSets);
    const groups = ModelHelper.sanitizeGroupSets(response.data);
    yield put(GroupsActions.fetchGroupSetsSuccess(groups));
  } catch (e) {
    yield put(GroupsActions.fetchGroupSetsFailure());
  }
}

function* fetchGroupFees() {
  try {
    const response = yield call(api.fetchGroupFees);
    const groups = ModelHelper.sanitizeGroupFees(response.data.groups);
    yield put(GroupsActions.fetchGroupFeesSuccess({ global: response.data.global, groups }));
  } catch (e) {
    yield put(GroupsActions.fetchGroupFeesFailure());
  }
}

function* modifyGroups(action) {
  try {
    yield call(api.modifyGroups, action.data);
    yield put(GroupsActions.modifyGroupsSuccess());
  } catch (e) {
    yield put(GroupsActions.modifyGroupsFailure());
  }
}

function* createGroup(action) {
  try {
    const response = yield call(api.createGroup, action.data);
    yield put(GroupsActions.createGroupSuccess(response.data));
  } catch (e) {
    yield put(GroupsActions.createGroupFailure(e.response.data));
  }
}

function* downloadFeeTotals({ groupId }) {
  try {
    const response = yield callAuthApi(api.fetchGroupFeeTotals, groupId);
    if (response.status === 200) {
      const url = window.URL.createObjectURL(new window.Blob([response.data], { type: "text/csv" }));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `fee-totals-${groupId}.csv`);
      // Append to html page
      document.body.appendChild(link);
      // Force download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
      yield put(AppStateActions.setAppMessage({ message: "Successfully downloaded CSV of fee totals" }));
    } else {
      yield put(AppStateActions.setAppMessage({ message: "Failed to download CSV of fee totals" }));
    }
  } catch (e) {
    yield put(AppStateActions.setAppMessage({ message: "Failed to download CSV of fee totals" }));
  }
}

function* downloadMemberEmails({ groupId }) {
  try {
    const response = yield callAuthApi(api.fetchGroupMemberEmails, groupId);
    if (response.status === 200) {
      const url = window.URL.createObjectURL(new window.Blob([response.data], { type: "text/csv" }));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `member-emails-${groupId}.csv`);
      // Append to html page
      document.body.appendChild(link);
      // Force download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
      yield put(AppStateActions.setAppMessage({ message: "Successfully downloaded CSV of member emails" }));
    } else {
      yield put(AppStateActions.setAppMessage({ message: "Failed to download CSV of member emails" }));
    }
  } catch (e) {
    yield put(AppStateActions.setAppMessage({ message: "Failed to download CSV of member emails" }));
  }
}

export default function groupsSagas() {
  return [
    takeLatest(types.fetchGroups, fetchGroups),
    takeLatest(types.fetchGroupSets, fetchGroupSets),
    takeLatest(types.fetchGroupFees, fetchGroupFees),
    takeLatest(types.modifyGroups, modifyGroups),
    takeLatest(types.createGroup, createGroup),
    takeLatest(types.downloadFeeTotals, downloadFeeTotals),
    takeLatest(types.downloadMemberEmails, downloadMemberEmails)
  ];
}
