import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ExposSelectors } from "client/state/redux/expos_redux";
import Page from "client/components/page";
import Flex from "client/components/flex";
import CreateExpo from "./components/create_expo_form";

class CreateExpoPage extends PureComponent {
  static propTypes = {
    exposMessage: PropTypes.string.isRequired
  };

  render() {
    const { exposMessage } = this.props;
    return (
      <Page
        title="Admin - Create Expo"
      >
        <Flex
          flex={8}
          alignItems="center"
          justifyContent="center"
        >
          <CreateExpo />
        </Flex>
        <Flex
          flex={1}
          alignItems="center"
          justifyContent="center"
        >
          {exposMessage}
        </Flex>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  exposMessage: ExposSelectors.getExposMessage(state)
});

export default connect(mapStateToProps, null)(CreateExpoPage);
