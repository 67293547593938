import React, { Component } from "react";
import PropTypes from "prop-types";
import Flex from "../flex";
import Content from "./components/content";
import Footer from "./components/footer";
import Header from "./components/header";
import SideMenu from "./components/side_menu";
import styles from "./styles";

class Page extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element)
    ]).isRequired,
    title: PropTypes.string.isRequired
  };

  componentDidMount() {
    this.setTitle();
  }

  setTitle() {
    const { title } = this.props;
    document.title = title;
  }

  render() {
    const { children } = this.props;
    return (
      <Flex
        flexDirection="row"
        style={styles.page}
      >
        <SideMenu />
        <Flex flexDirection="column" flex={1}>
          <Header />
          <Content>
            {children}
          </Content>
          <Footer />
        </Flex>
      </Flex>
    );
  }
}

export default Page;
