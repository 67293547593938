module.exports = {
  ENV: {
    DEVELOPMENT: "development",
    STAGING: "staging",
    PRODUCTION: "production",
    REPLAY: "replay"
  },
  FETCH_RANGE: {
    DISBURSEMENT_SELLERS: 25
  },
  CURSOR_STAGE: {
    DISBURSEMENT_SELLERS: {
      UNDISBURSED: "undisbursed",
      DISBURSED: "disbursed"
    }
  }
};
