import { call, put, take, select } from "redux-saga/effects";
import AuthActions, { AuthTypes, AuthSelectors } from "client/state/redux/auth_redux";
import refreshAuthToken from "./refresh_auth_token";

function* callAuthApi(apiFunction, ...payload) {
  try {
    const response = yield call(apiFunction, ...payload);
    return response;
  } catch (e) {
    const { response } = e;
    if (response.status !== 401) return response;

    const isRefreshing = yield select(AuthSelectors.getIsRefreshing);
    if (!isRefreshing) {
      yield put(AuthActions.attemptRefreshToken());
    }

    const result = yield take([
      AuthTypes.refreshTokenSuccess,
      AuthTypes.refreshTokenFailure
    ]);
    const isRefreshed = result.type === AuthTypes.refreshTokenSuccess;

    if (!isRefreshed) return response;

    return yield call(apiFunction, ...payload);
  }
}

export async function asyncCallAuthApi(apiFunction, ...payload) {
  const response = await apiFunction(...payload);
  if (response.status !== 401) return response;

  const isRefreshed = await refreshAuthToken();
  if (!isRefreshed) return response;

  return apiFunction(...payload);
}

export default callAuthApi;
