import { combineReducers } from "redux-immutable";
import { AuthReducer as auth } from "./auth_redux";
import { CurrentUserReducer as currentUser } from "./current_user_redux";
import { StartupReducer as startup } from "./startup_redux";
import { UsersReducer as users } from "./users_redux";
import { AppStateReducer as appState } from "./app_state_redux";
import { GroupsReducer as groups } from "./groups_redux";
import { AuctionsReducer as auctions } from "./auctions_redux";
import { ItemsReducer as items } from "./items_redux";
import { FeedbackReducer as feedback } from "./feedback_redux";
import { ReleasesReducer as releases } from "./releases_redux";
import { ExposReducer as expos } from "./expos_redux";
import { DisbursementsReducer as disbursements } from "./disbursements_redux";
import { LedgersReducer as ledgers } from "./ledgers_redux";
import { PrivateOffersReducer as privateOffers } from "./private_offers_redux";

// combine reducers
export default combineReducers({
  appState,
  auth,
  currentUser,
  startup,
  users,
  groups,
  auctions,
  items,
  feedback,
  releases,
  expos,
  disbursements,
  ledgers,
  privateOffers
});
