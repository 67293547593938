import { Colors } from "client/themes";

const styles = {
  userText: {
    color: Colors.black,
    fontSize: 16
  }
};

export default styles;
