const sharedConstants = require("../shared/constants");

module.exports = {
  ...sharedConstants,
  DISBURSEMENT_ACTION: {
    REFUND: "Refund",
    CANCEL: "Cancel",
    DISBURSE: "Disburse"
  }
};
