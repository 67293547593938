import { call, put, takeLatest } from "redux-saga/effects";
import api from "client/services/api";
import callAuthApi from "client/services/call_auth_api";
import ModelHelper from "./helpers/user_model";
import UsersActions, { UsersTypes as types } from "../redux/users_redux";
import AppStateActions from "../redux/app_state_redux";

function* fetchUsers() {
  try {
    const response = yield call(api.fetchUsers);
    const users = ModelHelper.sanitizeUsers(response.data);
    yield put(UsersActions.fetchUsersSuccess(users));
  } catch (e) {
    yield put(UsersActions.fetchUsersFailure());
  }
}

function* modifyUsers(action) {
  try {
    yield call(api.modifyUsers, action.data);
    yield put(UsersActions.modifyUsersSuccess());
  } catch (e) {
    yield put(UsersActions.modifyUsersFailure());
  }
}

function* blockDevice(action) {
  try {
    const response = yield call(api.blockDevice, action.data);
    yield put(UsersActions.blockDeviceSuccess(response.data));
  } catch (e) {
    yield put(UsersActions.blockDeviceFailure(e.response.data));
  }
}

function* searchUsers({ query }) {
  try {
    const response = yield callAuthApi(api.searchUsers, { q: query });
    const searchResults = ModelHelper.sanitizeUsers(response.data);
    yield put(UsersActions.searchUsersSuccess(searchResults));
  } catch (e) {
    yield put(UsersActions.searchUsersFailure());
  }
}

function* downloadUsersLast7Days() {
  try {
    const response = yield callAuthApi(api.downloadUsersLast7Days);
    if (response.status === 200) {
      const url = window.URL.createObjectURL(new window.Blob([response.data], { type: "text/csv" }));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "new-users.csv");
      // Append to html page
      document.body.appendChild(link);
      // Force download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
      yield put(AppStateActions.setAppMessage({ message: "Successfully downloaded CSV of users" }));
    } else {
      yield put(AppStateActions.setAppMessage({ message: "Failed to download CSV of user" }));
    }
  } catch (e) {
    yield put(AppStateActions.setAppMessage({ message: "Failed to download CSV of users" }));
  }
}

export default function usersSagas() {
  return [
    takeLatest(types.fetchUsers, fetchUsers),
    takeLatest(types.modifyUsers, modifyUsers),
    takeLatest(types.blockDevice, blockDevice),
    takeLatest(types.searchUsers, searchUsers),
    takeLatest(types.downloadUsersLast7Days, downloadUsersLast7Days)
  ];
}
