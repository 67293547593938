import MainPage from "./main_page";
import LoginPage from "./login_page";
import SignUpPage from "./sign_up_page";
import SettingsPage from "./settings_page";
import ReleasePage from "./release_page";
import FeedbackPage from "./feedback_page";
import BlockPage from "./block_page";
import CreateExpoPage from "./create_expo_page";
import CreateGroupPage from "./create_group_page";
import DisbursementsPage from "./disbursements_page";
import SellerDisbursementPage from "./seller_disbursement_page";
import GroupsGmvPage from "./groups_gmv_page";
import GroupSetsPage from "./group_sets_page";
import GroupFeesPage from "./group_fees_page";
import UsersPage from "./users_page";
import ItemImportPage from "./item_import_page";
import GroupsPage from "./groups_page";
import FeaturesIntegerPage from "./features_integer_page";
import PrivateOffersPage from "./private_offers_page";

export default {
  MainPage,
  LoginPage,
  SignUpPage,
  SettingsPage,
  ReleasePage,
  FeedbackPage,
  BlockPage,
  CreateExpoPage,
  CreateGroupPage,
  DisbursementsPage,
  SellerDisbursementPage,
  GroupsGmvPage,
  GroupSetsPage,
  GroupFeesPage,
  UsersPage,
  ItemImportPage,
  GroupsPage,
  FeaturesIntegerPage,
  PrivateOffersPage
};
