import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Flex from "client/components/flex";
import NavigationService from "client/services/navigation_service";
import styles from "./styles";

const USERNAME_FIELD = "username";
const EMAIL_FIELD = "email";
const PASSWORD_FIELD = "password";
const PASSWORD2_FIELD = "password2";

class SignUpForm extends PureComponent {
  static propTypes = {
    form: PropTypes.shape({
      email: PropTypes.string,
      password: PropTypes.string,
      password2: PropTypes.string,
      username: PropTypes.string
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired
  };

  onChangeUsername = (e) => {
    this.props.handleChange(USERNAME_FIELD, e.target.value);
  }

  onChangeEmail = (e) => {
    this.props.handleChange(EMAIL_FIELD, e.target.value);
  }

  onChangePassword = (e) => {
    this.props.handleChange(PASSWORD_FIELD, e.target.value);
  }

  onChangePassword2 = (e) => {
    this.props.handleChange(PASSWORD2_FIELD, e.target.value);
  }

  goToLoginPage = () => {
    NavigationService.toLoginPage();
  }

  render() {
    const { handleSubmit, form: { username, email, password, password2 } } = this.props;
    return (
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="400px"
        height="600px"
        style={styles.container}
      >
        <Flex flex={2} alignItems="center">Sign up a user for Auxxit&apos;s admin portal!</Flex>
        <form style={styles.formContainer}>
          <Flex style={styles.inputContainer}>
            <div style={styles.label}>Username</div>
            <input style={styles.input} value={username} autoComplete="username" onChange={this.onChangeUsername} />
          </Flex>
          <Flex style={styles.inputContainer}>
            <div style={styles.label}>Email</div>
            <input style={styles.input} value={email} autoComplete="email" onChange={this.onChangeEmail} />
          </Flex>
          <Flex style={styles.inputContainer}>
            <div style={styles.label}>Password</div>
            <input style={styles.input} value={password} type="password" autoComplete="new-password" onChange={this.onChangePassword} />
          </Flex>
          <Flex style={styles.inputContainer}>
            <div style={styles.label}>Confirm Password</div>
            <input style={styles.input} value={password2} type="password" autoComplete="new-password" onChange={this.onChangePassword2} />
          </Flex>
          <button
            style={styles.signUpButton}
            type="submit"
            onClick={handleSubmit}
          >
            Sign Up
          </button>
        </form>
      </Flex>
    );
  }
}

export default SignUpForm;
