import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CurrentUserSelectors } from "client/state/redux/current_user_redux";
import Page from "client/components/page";
import Flex from "client/components/flex";
import SettingsPanel from "./components/settings_panel";

class SettingsPage extends PureComponent {
  static propTypes = {
    settingsMessage: PropTypes.string.isRequired
  }

  render() {
    const { settingsMessage } = this.props;
    return (
      <Page
        title="Admin - Settings"
      >
        <Flex
          flex={8}
          alignItems="center"
          justifyContent="center"
        >
          <SettingsPanel />
        </Flex>
        <Flex
          flex={1}
          alignItems="center"
          justifyContent="center"
        >
          {settingsMessage}
        </Flex>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  settingsMessage: CurrentUserSelectors.getSettingsMessage(state)
});

export default connect(mapStateToProps, null)(SettingsPage);
