import React, { PureComponent } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./styles";

class NavbarLink extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element
    ]).isRequired,
    href: PropTypes.string.isRequired,
  }

  render() {
    const { children, href } = this.props;
    return (
      <NavLink
        style={styles.navbarItem}
        to={href}
      >
        {children}
      </NavLink>
    );
  }
}

class NavbarLinkBlue extends PureComponent {
  static propTypes = {
    children: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  }

  render() {
    const { children, href } = this.props;
    return (
      <NavLink
        style={styles.navbarItem}
        to={href}
      >
        {children}
      </NavLink>
    );
  }
}

class NavbarButton extends PureComponent {
  static propTypes = {
    children: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    style: PropTypes.shape()
  }

  static defaultProps = {
    style: {}
  };

  render() {
    const { onClick, children, style } = this.props;
    return (
      <button
        style={{ ...styles.navbarItem, ...styles.navbarBtn, ...style }}
        onClick={onClick}
        type="button"
      >
        {children}
      </button>
    );
  }
}

class NavbarText extends PureComponent {
  static propTypes = {
    children: PropTypes.string.isRequired
  }

  render() {
    const { children } = this.props;
    return <span style={styles.navbarItem}>{children}</span>;
  }
}

export {
  NavbarLink,
  NavbarLinkBlue,
  NavbarButton,
  NavbarText
};
