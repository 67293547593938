import { Map } from "immutable";

const INIT_STATE = Map({
  isFetching: false,
  isSubmitting: false,
  id: "",
  username: "",
  email: "",
  avatar: "",
  settings: Map({}),
  settingsMessage: ""
});

const types = {
  fetchCurrentUser: "FETCH_CURRENT_USER",
  fetchCurrentUserSuccess: "FETCH_CURRENT_USER_SUCCESS",
  fetchCurrentUserFailure: "FETCH_CURRENT_USER_FAILURE",
  saveUserSettings: "SAVE_USER_SETTINGS",
  saveUserSettingsSuccess: "SAVE_USER_SETTINGS_SUCCESS",
  saveUserSettingsFailure: "SAVE_USER_SETTINGS_FAILURE",
  setUserSettingsMessage: "SET_USER_SETTINGS_MESSAGE",
  reset: "RESET"
};

/* ********* Actions ********* */

const actions = {
  fetchCurrentUser() {
    return {
      type: types.fetchCurrentUser
    };
  },
  fetchCurrentUserSuccess(data) {
    return {
      type: types.fetchCurrentUserSuccess,
      data
    };
  },
  fetchCurrentUserFailure() {
    return {
      type: types.fetchCurrentUserFailure
    };
  },
  saveUserSettings(data) {
    return {
      type: types.saveUserSettings,
      data
    };
  },
  saveUserSettingsSuccess(data) {
    return {
      type: types.saveUserSettingsSuccess,
      data
    };
  },
  saveUserSettingsFailure(data) {
    return {
      type: types.saveUserSettingsFailure,
      data
    };
  },
  setUserSettingsMessage(data) {
    return {
      type: types.setUserSettingsMessage,
      data
    };
  },
  reset() {
    return {
      type: types.reset
    };
  }
};

/* ********* Reducer ********* */

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case types.fetchCurrentUser:
    return state.merge({ isFetching: true });
  case types.fetchCurrentUserSuccess: {
    const { settings } = action.data;
    return state
      .merge({
        isFetching: false,
        ...action.data
      })
      .setIn(["settings"], Map({
        darkTheme: settings.darkTheme
      }));
  }
  case types.fetchCurrentUserFailure:
    return state.merge({ isFetching: false });
  case types.saveUserSettings:
    return state.merge({ isSubmitting: true });
  case types.saveUserSettingsSuccess:
    return state.merge({
      isSubmitting: false,
      settingsMessage: action.data.message || JSON.stringify(action.data)
    });
  case types.saveUserSettingsFailure:
    return state.merge({
      isSubmitting: false,
      settingsMessage: action.data.message || JSON.stringify(action.data)
    });
  case types.setUserSettingsMessage:
    return state.merge({ settingsMessage: action.data });
  case types.reset:
    return INIT_STATE;
  default:
    return state;
  }
};

/* ********* Selectors ********* */

const user = state => state.get("currentUser");

const selectors = {
  getIsLoggedIn: state => user(state).get("username").length > 0,
  getCurrentUser: state => ({
    id: user(state).get("id"),
    username: user(state).get("username"),
    email: user(state).get("email"),
    avatar: user(state).get("avatar")
  }),
  getUserSettings: state => ({
    darkTheme: user(state).getIn(["settings", "darkTheme"])
  }),
  getSettingsMessage: state => user(state).get("settingsMessage")
};

export {
  types as CurrentUserTypes,
  reducer as CurrentUserReducer,
  selectors as CurrentUserSelectors
};
export default actions;
