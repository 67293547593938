import { Colors } from "client/themes";

const styles = {
  container: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    position: "relative"
  },
  interface: {
    width: "80%",
    height: "80%",
    position: "relative",
    background: Colors.grey,
    borderRadius: 10,
    boxShadow: `3px 4px 4px${Colors.grey}`
  },
  feedbackList: {
    borderRadius: "5px 0 0 5px",
    overflowY: "scroll",
    flex: 1
  },
  feedbackLabelContainer: {
    paddingTop: 9,
    paddingBottom: 10,
    margin: 0,
  },
  feedbackLabel: {
    color: Colors.grey,
    fontSize: 20,
    padding: 0,
    margin: 0,
  },
  feedDescription: {
    paddingBottom: 10,
    margin: 0,
    fontSize: 14,
    flexWrap: "wrap"
  },
  feedbackItem: {
    background: Colors.white,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: Colors.auxxitSlateDarker,
    margin: 10,
    paddingLeft: 10,
    position: "relative",
    boxShadow: "1px 1px 3px #888888",
    cursor: "pointer"
  },
  feedbackDate: {
    position: "absolute",
    top: 10,
    right: 10,
    margin: 0,
    fontSize: 12,
    color: Colors.auxxitSlateDarkest
  },
  feedbackDateSelected: {
    position: "absolute",
    top: 23,
    right: 18,
    margin: 0,
    fontSize: 16,
    color: Colors.auxxitSlateDarkest
  },
  feedbackUser: {
    margin: 0,
    fontSize: 14,
    color: Colors.auxxitOrange,
    fontWeight: "bold",
    paddingTop: 10,
  },
  selectedItemContainer: {
    flex: 2,
    padding: 5,
    borderRadius: "0px 10px 10px 0px",
  },
  selectedLabel: {
    margin: 0,
    paddingBottom: 10,
    borderBottom: "1px solid",
    borderBottomColor: Colors.auxxitSlateDarker,
  },
  selectedItem: {
    margin: 10,
    background: Colors.white,
    padding: "2%",
    flex: 1,
    borderRadius: 5,
    position: "relative",
    overflow: "auto"
  },
  feedbackAttachment: {
    fontSize: 12,
    color: Colors.auxxitBlue,
    fontWeight: "bold"
  },
  image: {
    width: "100%"
  }
};

export default styles;
