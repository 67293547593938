import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AuctionsActions, { AuctionsSelectors } from "client/state/redux/auctions_redux";
import Flex from "client/components/flex";
import DataGrid from "client/components/data_grid";
import styles from "./styles";

const AuctionModel = {
  id: {
    type: String,
    editable: false
  },
  createdBy: {
    type: String,
    editable: true
  },
  groupId: {
    type: String,
    editable: false
  },
  name: {
    type: String,
    editable: true
  },
  notes: {
    type: Array,
    editable: true
  },
  approvedItemCount: {
    type: Number,
    editable: true
  },
  effectiveDate: {
    type: Date,
    editable: true
  },
  closeDate: {
    type: Date,
    editable: true
  },
  createDate: {
    type: Date,
    editable: false
  },
  lastModified: {
    type: Date,
    editable: false
  }
};

class AuctionsInterface extends Component {
  static propTypes = {
    auctions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    fetchAuctions: PropTypes.func.isRequired,
    modifyAuctions: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      changes: {}
    };
  }

  componentDidMount() {
    const { fetchAuctions } = this.props;
    fetchAuctions();
  }

  handleChange = (id, field) => (value, noChange) => {
    this.setState((prevState) => {
      const newChanges = { ...prevState.changes };
      // if no change, delete that field
      if (noChange) {
        delete newChanges[id][field];
        // if no change and object now empty, delete object
        if (!Object.keys(newChanges[id]).length) {
          delete newChanges[id];
        }
      // else, make change to requested field
      } else {
        newChanges[id] = { ...newChanges[id], [field]: value };
      }
      return { changes: newChanges };
    });
  }

  handleSave = () => {
    const { changes } = this.state;
    this.props.modifyAuctions(changes);
  }

  render() {
    const { auctions } = this.props;
    const { changes } = this.state;
    const showSave = Object.keys(changes).length > 0;
    return (
      <Flex flex={1} style={styles.interface}>
        <DataGrid
          data={auctions}
          model={AuctionModel}
          showSave={showSave}
          handleEdit={this.handleChange}
          handleSave={this.handleSave}
        />
      </Flex>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  fetchAuctions: () => dispatch(AuctionsActions.fetchAuctions()),
  modifyAuctions: data => dispatch(AuctionsActions.modifyAuctions(data))
});

const mapStateToProps = state => ({
  auctions: AuctionsSelectors.getAuctions(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(AuctionsInterface);
