import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Flex from "../flex";
import styles from "./styles";

class TabInterface extends PureComponent {
  static propTypes = {
    selectedTab: PropTypes.string.isRequired,
    setSelectedTab: PropTypes.func.isRequired,
    style: PropTypes.shape(),
    tabs: PropTypes.arrayOf(PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      component: PropTypes.object,
      title: PropTypes.string,
      type: PropTypes.string
    })).isRequired
  };

  static defaultProps = {
    style: {}
  };

  setSelectedTab = type => () => {
    this.props.setSelectedTab(type);
  };

  renderTabs = () => {
    const { tabs, selectedTab } = this.props;
    return tabs.map(tab => (
      <Flex
        key={tab.type}
        flex={1}
        justifyContent="center"
        style={selectedTab === tab.type
          ? styles.selectedTab
          : styles.unselectedTab}
      >
        <button
          style={{
            ...styles.tabButton,
            ...(selectedTab === tab.type
              ? styles.selectedButton
              : styles.unselectedButton
            )
          }}
          type="button"
          onClick={this.setSelectedTab(tab.type)}
        >
          {tab.title}
        </button>
      </Flex>
    ));
  };

  renderTabBody = () => {
    const { tabs, selectedTab } = this.props;
    const { component: TabBody } = tabs.find(tab => tab.type === selectedTab);
    return <TabBody />;
  };

  render() {
    const { style } = this.props;
    return (
      <Flex flexDirection="column" flex={1} style={{ ...styles.tabInterface, ...style }}>
        <Flex style={styles.tabBar}>
          {this.renderTabs()}
        </Flex>
        <Flex style={styles.tabBody}>
          {this.renderTabBody()}
        </Flex>
      </Flex>
    );
  }
}

export default TabInterface;
