import { call, put, takeLatest, select } from "redux-saga/effects";
import api from "client/services/api";
import callAuthApi from "client/services/call_auth_api";
import NavigationService from "client/services/navigation_service";
import { CurrentUserSelectors } from "../redux/current_user_redux";
import AppStateActions, {
  AppStateTypes as types
} from "../redux/app_state_redux";

function* setEnvironment(action) {
  try {
    const { id: userId } = yield select(CurrentUserSelectors.getCurrentUser);
    yield callAuthApi(api.modifyUserEnvironment, userId, { environment: action.data });

    yield call(api.client.setEnvironment, action.data);
    yield put(AppStateActions.setEnvironmentSuccess(action.data));

    // TODO: this needs to refresh all relevant data
    // on page without actually reloading the page
    yield call(NavigationService.reloadMainPage);
  } catch (e) {
    yield put(AppStateActions.setEnvironmentFailure());
  }
}

export default function currentUserSagas() {
  return [
    takeLatest(types.setEnvironment, setEnvironment)
  ];
}
