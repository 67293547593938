import { Map, List } from "immutable";

const INIT_STATE = Map({
  isFetching: false,
  isSubmitting: false,
  exposMessage: "",
  expos: List([]) // TODO: implement expos data grid
});

const types = {
  setExposMessage: "SET_EXPOS_MESSAGE",
  createExpo: "CREATE_EXPO",
  createExpoSuccess: "CREATE_EXPO_SUCCESS",
  createExpoFailure: "CREATE_EXPO_FAILURE"
};

/* ********* Actions ********* */

const actions = {
  setExposMessage(data) {
    return {
      type: types.setExposMessage,
      data
    };
  },
  createExpo(data) {
    return {
      type: types.createExpo,
      data
    };
  },
  createExpoSuccess(data) {
    return {
      type: types.createExpoSuccess,
      data
    };
  },
  createExpoFailure(data) {
    return {
      type: types.createExpoFailure,
      data
    };
  }
};

/* ********* Reducer ********* */

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case types.setExposMessage:
    return state.merge({ exposMessage: action.data });
  case types.createExpo:
    return state.merge({ isSubmitting: true });
  case types.createExpoSuccess:
    return state.merge({ isSubmitting: false, exposMessage: JSON.stringify(action.data) });
  case types.createExpoFailure:
    return state.merge({ isSubmitting: false, exposMessage: JSON.stringify(action.data) });
  default:
    return state;
  }
};

/* ********* Selectors ********* */

const expos = state => state.get("expos");

const selectors = {
  getExposMessage: state => expos(state).get("exposMessage")
};

export {
  types as ExposTypes,
  reducer as ExposReducer,
  selectors as ExposSelectors
};
export default actions;
