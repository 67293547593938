import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Flex from "client/components/flex";
import styles from "./styles";

class Content extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element)
    ]).isRequired
  };

  render() {
    const { children } = this.props;
    return (
      <Flex
        flexDirection="column"
        style={styles.content}
        flex={1}
      >
        {children}
      </Flex>
    );
  }
}

export default Content;
