import { Colors } from "client/themes";

const styles = {
  container: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center"
  },
  content: {
    height: "100%",
    width: "80%"
  },
  listWrapper: {
    backgroundColor: Colors.greyBlurred,
    borderRadius: 10,
    boxShadow: `3px 4px 4px${Colors.grey}`,
    marginRight: 10,
    paddingTop: 10,
    marginBottom: 10,
    height: "100%",
    overflowY: "scroll"
  },
  dataContainer: {
    display: "flex",
    flexDirection: "row",
    width: "98%",
    background: Colors.white,
    margin: "0 auto",
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 10,
    marginBottom: 10,
  },
  userDetailsWrap: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "70%",
    marginRight: 20,
    flex: 1
  },
  userDetailsRow: {
    display: "flex",
    flexDirection: "column",
  },
  reportsWrap: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    marginRight: 20
  },
  detailsRows: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  userIcon: {
    paddingLeft: 30,
    paddingRight: 30,
  },
  avatar: {
    height: 100,
    width: 100
  },
  iconText: {
    fontSize: 12,
    color: Colors.greyBlurred,
  },
  lineItemRow: {
    display: "flex",
    flexDirection: "row",
    padding: "3px 0 3px 0"
  },
  lineItemRowLabel: {
    fontSize: 12,
    color: Colors.greyBlurred,
    width: 80,
    alignSelf: "center",
    marginLeft: 5,
    marginRight: 5
  },
  lineItemRowText: {
    fontSize: 12,
    fontWeight: "bold",
    color: Colors.black,
    textOverflow: "ellipsis",
    overflow: "hidden"
  },
  lineItemColumn: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  lineItemColumnLabel: {
    fontSize: 10,
    color: Colors.greyBlurred,
    textAlign: "center"
  },
  lineItemColumnText: {
    fontSize: 14,
    fontWeight: "bold",
    color: Colors.black,
  },
  reportsIconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  searchContainer: {
    marginLeft: 50,
    marginRight: 50,
    marginBottom: 10
  },
  inputContainer: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.auxxitSlateDarker,
    borderStyle: "solid",
    padding: 10,
    backgroundColor: "white"
  },
  label: {
    color: Colors.greyBlurred
  },
  input: {
    color: Colors.lightblue,
    fontSize: 16
  },
  downloadBtn: {
    backgroundColor: "transparent",
    border: 0,
    marginLeft: 10,
    color: Colors.linkBlue,
    textDecorationLine: "underline"
  },
  emptyListText: {
    marginTop: 20,
    textAlign: "center"
  }
};

export const detailIconStyleProps = {
  color: Colors.white,
  size: 35,
  style: {
    alignSelf: "center",
    display: "inline-block",
  }
};

export default styles;
