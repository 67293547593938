import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import Flex from "client/components/flex";
import NavbarRight from "client/components/navbar_right";
import AuthOnly from "client/containers/auth_only";
import SignUpLink from "./components/sign_up_link";
import styles from "./styles";

class BottomNavbar extends PureComponent {
  render() {
    return (
      <Flex flex={1} alignItems="center">
        <NavbarRight>
          <AuthOnly>
            <SignUpLink />
          </AuthOnly>
          <div style={styles.copyrightText}>&copy; Auxxit, Inc. 2024</div>
        </NavbarRight>
      </Flex>
    );
  }
}

export default withRouter(BottomNavbar);
