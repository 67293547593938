import api from "./client";

function fetchAuctions() {
  return api.get("/auctions");
}

function modifyAuctions(params) {
  return api.put("/auctions", params);
}

export default {
  fetchAuctions,
  modifyAuctions
};
