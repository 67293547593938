function flattenLifeCycle(item) {
  const result = { ...item };
  if (result.lifeCycle) {
    Object.keys(result.lifeCycle).forEach((key) => {
      result[key] = result.lifeCycle[key];
    });
    delete result.lifeCycle;
  }
  return result;
}

function flattenItem(itemData) {
  const item = flattenLifeCycle({ ...itemData });

  item.watchers = item.watchers ? item.watchers : [];

  return item;
}

function sanitizeImages(images) {
  return images.map(image => image.url);
}

function sanitizeItem(item) {
  return {
    ...flattenItem(item),
    id: item._id,
    images: sanitizeImages(item.images)
  };
}

function sanitizeItems(data) {
  return data.map(item => sanitizeItem(item));
}

function sanitizeGroupsPrivateOffers(data) {
  return data.map(g => ({
    ...g,
    id: g._id,
    gmvUsd: `$${g.offersAcceptedGmv.toFixed(2)}`
  }));
}

export default {
  sanitizeItem,
  sanitizeItems,
  sanitizeGroupsPrivateOffers
};
