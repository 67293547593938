import api from "./client";

function logIn(params) {
  return api.post("/auth/login", params);
}

function logOut(params) {
  return api.post("/auth/logout", params);
}

function signUp(params) {
  return api.post("/auth/register", params);
}

function refreshToken(params) {
  return api.post("/auth/refresh", params);
}

export default {
  logIn,
  logOut,
  signUp,
  refreshToken
};
