import { Colors } from "client/themes";

const styles = {
  container: {
    backgroundColor: Colors.white,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 8,
    justifyContent: "space-around"
  },
  inputContainer: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.auxxitSlateDarker,
    borderStyle: "solid",
    padding: 20
  },
  label: {
    color: Colors.greyBlurred
  },
  input: {
    color: Colors.lightblue,
    fontSize: 16
  },
  signUpButton: {
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 30,
    paddingRight: 30,
    backgroundColor: Colors.auxxitBlue,
    borderRadius: 5,
    borderStyle: "solid",
    elevation: 2,
    shadowOffset: { width: 1, height: 1 },
    shadowColor: Colors.white,
    shadowOpacity: 0.5,
    shadowRadius: 10,
    color: Colors.white,
    fontSize: 16
  },
  loginLinkText: {
    color: Colors.auxxitBlue,
    cursor: "pointer"
  }
};

export default styles;
